import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TablePagination
} from "@mui/material";
import axios from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../Actions/branchActions";
import { LocalService } from "../../core/service";
import { RevenueService } from "../../core/service/revenue.service";
import { jwtDecode } from "jwt-decode";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as Util from "../../core/utility";

const user_id: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

interface expenses {
  month: string;
  salary: number;
  overheads: number;
  infra: number;
  team_leader?: string;
  revenue: number;
}

interface salary {
  mon: string;
  total_salary: number;
}

interface purchase {
  mon: string;
  amount: number;
  team_id: number;
}

function CurrentExpense({ startd, endd, teams, doApply, setDoApply }: any) {

  const revenueService: RevenueService = new RevenueService();
  const methods: Util.Methods = new Util.Methods();

  const dispatch = useDispatch();
  const branch = useSelector((state: any) => state.branch);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filteredRows, setFilteredRows] = useState<expenses[]>([]);
  const [purchases, setPurchases] = useState<purchase[]>([]);
  const [salaries, setSalaries] = useState<salary[]>([]);
  const [leads, setLeads] = useState<any[]>([]);


  useEffect(() => {
    dispatch(loading(true))
    revenueService.getMonthlyPurchases(teams, startd, endd, branch.branch).then((res) => {
      if (res.status > 0) {
        setPurchases(res.data.rows);
      }
    }).catch((err) => console.log(`get all purchases error :: ${err}`));

    revenueService.getMonthlySalary(startd, endd, branch.branch).then((res) => {
      if (res.status > 0) {
        setSalaries(res.data.rows);
      }
    }).catch((err) => console.log(`get all salaries error :: ${err}`));

    revenueService.getRevenue(branch.branch, roleobj?.role, teams.map((team: any) => team.user_id)).then((res) => {
      if (res.status > 0) {
        setLeads(res.data.rows);
      }
      dispatch(loading(false));
    }).catch((err) => console.log(`get all leads error :: ${err}`));

    setDoApply(false);

  }, [doApply])

  useEffect(() => { setDoApply(true) }, [])

  useEffect(() => {
    if (purchases && salaries) {
      const revenue: any[] = revenueService.generateTotalRevenue(startd, endd, leads, teams);
      if (revenue.length > 0) {
        const filterRows = revenueService.makeData(purchases, salaries, teams, revenue);
        setFilteredRows(filterRows);
      }
    }
  }, [salaries, purchases, branch.branch])

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div style={{ width: '100%', marginTop: 30 }}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead style={{ backgroundColor: '#2196F3' }}>
            <TableRow style={{ color: 'white' }}>
              <TableCell style={{ color: 'white' }}>Month</TableCell>
              <TableCell style={{ color: 'white' }}>Salaries</TableCell>
              <TableCell style={{ color: 'white' }}>OverHeads</TableCell>
              <TableCell style={{ color: 'white' }}>Infrastructure</TableCell>
              <TableCell style={{ color: 'white' }}>Expenses</TableCell>
              <TableCell style={{ color: 'white' }}>Revenue</TableCell>
              <TableCell style={{ color: 'white' }}>Total Profit</TableCell>
              {teams.length > 0 ? <TableCell style={{ color: 'white' }}>Team Leader</TableCell> : <></>}
              <TableCell style={{ color: 'white', width: 30 }}>
                <button
                  style={{ background: 'transparent', border: 'none', color: 'white' }}
                  onClick={() =>
                    methods.exportToExcel(filteredRows, "MonthlyStats")
                  }
                >
                  <CloudDownloadIcon />
                </button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{item.month}</TableCell>
                <TableCell>{methods._formatINR(Number(item.salary), false)}</TableCell>
                <TableCell>{methods._formatINR(Number(item.overheads), false)}</TableCell>
                <TableCell>{0}</TableCell>
                <TableCell>{methods._formatINR(Number(`${item.overheads + item.salary + item.infra}`), false)}</TableCell>
                <TableCell>{methods._formatINR(Number(item.revenue), false)}</TableCell>
                <TableCell>{methods._formatINR(Number(`${item.revenue - (item.overheads + item.salary + item.infra)}`), false)}</TableCell>
                {teams.length > 0 ? <TableCell>{item.team_leader}</TableCell> : <></>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={filteredRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}

export default CurrentExpense;
