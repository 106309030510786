import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InfoIcon from "@mui/icons-material/Info";

import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { ExpenseClientDetail } from "../../types/finance";
import { ExpensesService } from "../../core/service";
import * as Util from "../../core/utility";
import dayjs from "dayjs";

const expensesService: ExpensesService = new ExpensesService();
const methods: Util.Methods = new Util.Methods();

interface EditAccountProps {
  id: number;
  inv_date: string;
  inv_no: string;
  narr: string;
  Amount: string;
  client_id: number;
  lead_id: string;
  Reimbursible: string;
  Igst: string;
  Cgst: string;
  Sgst: string;
  updateRefresh: any;
  onClose: any;
}

const BillingMaster: React.FC<EditAccountProps> = ({
  id,
  inv_date,
  inv_no,
  narr,
  Amount,
  client_id,
  lead_id,
  Reimbursible,
  Igst,
  Cgst,
  Sgst,
  updateRefresh,
  onClose,
}) => {
  const branch = useSelector((state: any) => state.branch);
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const [ClientID, setClientId] = useState(client_id);
  const [leadId, setLeadId] = useState(lead_id);
  const [amount, setAmount] = useState(Amount);
  const [invDate, setInvDate] = useState<string>(inv_date);
  const [narration, setNarration] = useState<string>(narr);
  const [invNum, setInvNum] = useState<string>(inv_no);
  const [reimbursible, setReimbursible] = useState<string>(Reimbursible);
  const [gstDeduction, setGstDeduction] = useState(0);
  const [sameState, setSameState] = useState(0);
  const [igst, setigst] = useState(Igst);
  const [sgst, setsgst] = useState(Sgst);
  const [cgst, setcgst] = useState(Cgst);
  const [companyGst, setCompanyGst] = useState("");
  const [clientGST, setClientGST] = useState("");
  const [expenseIds, setExpenseId] = useState<(string | number)[]>([]);
  const [expenses, setExpenses] = useState<ExpenseClientDetail[]>([]);

  const [allClients, setClients] = useState([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function handleAutoComplete(values: ExpenseClientDetail[]) {
    setExpenseId(values.map((val) => val.id));
  }

  const handleClientID = (e: any) => {
    setClientId(e.target.value);
  };

  useEffect(() => {
    expensesService.getClientExpenses(ClientID).then((res) => {
      if (res.status > 0) {
        setExpenses(res.data.rows);
      }
    });
  }, [ClientID]);

  useEffect(() => {
    expensesService.getBillingExpenses(id).then((res) => {
      if (res.data.rows.length > 0) {
        setExpenseId(res.data.rows.map((val) => parseInt(val)));
      }
    });
  }, [id]);

  const handleEdit = async () => {
    setIsLoading(true);
    axios
      .post(`create-update/billing`, {
        id: id,
        company_id: branch.branch,
        client_id: ClientID,
        amount: amount,
        lead_id: leadId,
        invoice_date: invDate,
        invoice_no: invNum,
        igst: Number(igst),
        cgst: Number(cgst),
        sgst: Number(sgst),
        narration: narration,
        invoice_type: reimbursible,
        expensesIds: expenseIds,
      })
      .then((resp) => {
        if (resp.data.status > 0) {
          toast.success(`Successfully Updated!`, {
            position: "top-right",
            autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          onClose();
          updateRefresh(true);
        } else {
          toast.warning(`Please check all the entries carefully`, {
            position: "top-right",
            autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.error("Update collection err :: ", err);
        toast.error(`Server error!`, {
          position: "top-right",
          autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
    // handleClose();
  };

  useEffect(() => {
    let lead: any = allClients?.find((client: any) => client.id == ClientID);
    if (lead) {
      setLeadId(lead.lead_id);
      setClientGST(lead.gst);
      setCompanyGst(lead.company_gst);
    }

    if (companyGst.substring(0, 2) === clientGST.substring(0, 2)) {
      setSameState(1);
    } else {
      setSameState(2);
    }
    if (sameState === 1 && amount) {
      const gst = Math.round(Number(amount) * (gstDeduction / 100));
      setigst(gst.toString());
    } else if (sameState === 2 && amount) {
      const gst = Math.round((Number(amount) * (gstDeduction / 100)) / 2);
      setsgst(gst.toString());
      setcgst(gst.toString());
    }

    console.log(ClientID);
  }, [gstDeduction, amount, ClientID]);

  useEffect(() => {
    const getAllClients = async () => {
      const response: any = await axios.post(`/get-all-clients-id`, {
        branch: branch.branch,
      });
      setClients(response.data.data.rows);
    };

    getAllClients();
  }, [branch.branch]);

  // const handleClose = () => {
  //     setOpen(false);
  // }

  const handlePopup = () => {
    setOpen(true);
  };

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      {/* <IconButton onClick={handlePopup} color="primary" style={{ height: '8px' }}>
                <InfoIcon />
            </IconButton> */}
      <Dialog
        open
        onClose={onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // maxWidth='xl'
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack
            direction={isMdUp ? "row" : "column"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <h2>{isEditing ? "Edit Bill" : "Bill Details"}</h2>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {!isEditing ? (
                <IconButton color="primary" style={{ height: "8px" }}>
                  <EditIcon
                    onClick={() => {
                      setIsEditing(true);
                    }}
                  />
                </IconButton>
              ) : (
                <></>
              )}
              <h2 style={{ marginLeft: 10 }}>
                {localStorage.getItem("cur_branch")}
              </h2>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={isMdUp ? "row" : "column"}
            sx={{
              width: isMdUp ? 1200 : "100%",
              borderRadius: 2,
              bgcolor: "background.paper",
              p: 3,
            }}
          >
            <Box
              sx={{
                width: isMdUp ? 400 : "100%",
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                bgcolor: "background.paper",
                p: 3,
                marginX: isMdUp ? 2 : 0,
              }}
            >
              <FormControl fullWidth>
                <RadioGroup
                  defaultValue="Normal"
                  value={reimbursible}
                  onChange={(e) => {
                    setReimbursible(e.target.value);
                  }}
                  name="radio-buttons-group"
                >
                  <Stack direction={"row"}>
                  {/* <FormControlLabel
                      disabled={!isEditing}
                      value={"Operational"}
                      control={<Radio />}
                      label="Operational"
                    /> */}
                    <FormControlLabel
                      disabled={!isEditing}
                      value={"Reimbursement"}
                      control={<Radio />}
                      label="Reimbursement"
                    />
                    <FormControlLabel
                      disabled={!isEditing}
                      value={"Normal"}
                      control={<Radio />}
                      label="Retainer"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Invoice Date"
                type="date"
                value={invDate}
                onChange={(e) => {
                  setInvDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Invoice Number"
                type="string"
                value={invNum}
                onChange={(e) => {
                  setInvNum(e.target.value);
                }}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="lead-label">Client</InputLabel>
                <Select
                  labelId="lead-label"
                  label="Client"
                  value={ClientID}
                  onChange={handleClientID}
                  fullWidth
                  readOnly={!isEditing ? true : false}
                  IconComponent={isEditing ? undefined : () => null}
                >
                  {allClients.map((client: any) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.brand_and_branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
                sx={{ mt: 2 }}
                id="expense_id"
                className="w-100"
                limitTags={1}
                multiple={true}
                disableCloseOnSelect={true}
                options={expenses}
                value={methods.filterArray(expenseIds, expenses, "id")}
                onChange={(event, value) => handleAutoComplete(value)}
                getOptionLabel={(option) => option.invoice_no}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={"invoice-list" + option.invoice_no} >
                    <Checkbox
                      icon={<CheckBoxOutlineBlank />}
                      checkedIcon={<CheckBox />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.invoice_no}&nbsp;&nbsp;
                    <strong className="date-box">
                      {dayjs(option.invoice_date).format("DD, MMM, YYYY")}
                    </strong>
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} label="Expenses" />
                )}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Principal Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="lead-label">GST</InputLabel>
                <Select
                  labelId="lead-label"
                  label="GST"
                  value={gstDeduction}
                  onChange={(e) => {
                    setGstDeduction(Number(e.target.value));
                  }}
                  fullWidth
                  readOnly={!isEditing ? true : false}
                  IconComponent={isEditing ? undefined : () => null}
                >
                  <MenuItem value={0} key={0}>
                    0%
                  </MenuItem>
                  <MenuItem value={5} key={5}>
                    5%
                  </MenuItem>
                  <MenuItem value={18} key={18}>
                    18%
                  </MenuItem>
                  <MenuItem value={28} key={28}>
                    28%
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ mt: 2, display: sameState === 1 ? "block" : "none" }}
                fullWidth
                label="IGST"
                type="string"
                value={igst}
                onChange={(e) => setigst(e.target.value)}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <TextField
                sx={{ mt: 2, display: sameState === 2 ? "block" : "none" }}
                fullWidth
                label="SGST"
                type="string"
                value={sgst}
                onChange={(e) => setsgst(e.target.value)}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <TextField
                sx={{ mt: 2, display: sameState === 2 ? "block" : "none" }}
                fullWidth
                label="CGST"
                type="string"
                value={sgst}
                onChange={(e) => setcgst(e.target.value)}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Gross Amount"
                type="number"
                value={
                  Number(amount) + Number(igst) + Number(cgst) + Number(sgst)
                }
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                multiline
                rows={4}
                label="Narration"
                type="string"
                value={narration}
                onChange={(e) => setNarration(e.target.value)}
                InputProps={{
                  readOnly: !isEditing ? true : false,
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        {isEditing ? (
          <DialogActions>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              style={{ borderRadius: 5 }}
              onClick={handleEdit}
            >
              Update
            </LoadingButton>
            <Button
              variant="outlined"
              style={{ marginLeft: 10, borderRadius: 5 }}
              onClick={(e) => {
                setIsEditing(!isEditing);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              variant="outlined"
              style={{ marginLeft: 10, borderRadius: 5 }}
              onClick={onClose}
            >
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default BillingMaster;
