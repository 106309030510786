import React, { Fragment, useEffect, useState } from "react";
import axios from "../../../utils/axios";
import Divider from "@mui/material/Divider";
import { TextField, Checkbox, Autocomplete, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../../Actions/branchActions";
import { toast } from "react-toastify";
import CurrentBillingStats from "../../Analytics/Finance/CurrentBillingStats";
import { LineBarColumn } from "../../../global-component/graphs/line-bar-column";
import { MBDatePicker } from "../../../global-component";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { PieChart } from "../../../global-component/graphs/pie_chart";
import {
  TableComponent,
  SortingColumns,
  DisplayNames,
} from "../../../global-component";
import dayjs from "dayjs";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { TableFooter } from "@mui/material";
import * as Util from "../../../core/utility";

const format = "YYYY-MM-DD";
const methods: Util.Methods = new Util.Methods();

type AutoCompleteType = { label: string; value: string };

const displayName: DisplayNames = {
  MONTH: "Months",
  // "legal_name": "Legal name",
  // "brand_name": "Brand name",
  total_amount: "Total amount",
};

const sortingColumn: SortingColumns[] = [
  { name: "MONTH", columnSize: "auto" },
  //   { name: "legal_name", columnSize: "auto" },
  //   { name: "brand_name", columnSize: "auto" },
  { name: "total_amount", columnSize: "auto" },
];

const initialFilter = {
  start_date: "2024-01-01",
  end_date: dayjs(new Date()).format(format),
  branch: 0,
  expense_category: [] as AutoCompleteType[],
  billable: [] as AutoCompleteType[], // ["Yes"],
  status: [] as AutoCompleteType[], //  ["Not Paid"],
};

const PaymentReport: React.FC = () => {
  const dispatch = useDispatch();
  const branch = useSelector((state: any) => state.branch);
  // chart Section
  const [chart, setChart] = useState<{
    chartData: any[];
    category: string[];
    stack: boolean;
  }>({
    chartData: [],
    category: [],
    stack: false,
  });
  // filter Section
  const [filter, setFilter] = useState<typeof initialFilter>(initialFilter);
  // table Data
  const [tableData, setTableData] = useState<any[]>([]);
  const [renderData, setRenderData] = useState<any[]>([]);
  const [tableFooter, setTableFooter] = useState<React.ReactNode>();

  useEffect(() => {
    getCurrentBilling();
  }, []);

  useEffect(() => {
    setTableFooter(
      <TableFooter>
        <TableRow>
          <TableCell>Total</TableCell>
          <TableCell>
            {/* {methods._formatINR(0)} */}
            {methods._formatINR(
              renderData.reduce((accumulator, currentObject) => {
                return accumulator + currentObject.total_amount;
              }, 0)
            )}
          </TableCell>
        </TableRow>
      </TableFooter>
    );
  }, [renderData]);

  const getCurrentBilling = async () => {
    try {
      const readyFilterData = {
        ...filter,
        expense_category: filter.expense_category.map((val: any) => val?.value),
        billable: filter.billable.map((val: any) => val?.value),
        status: filter.status.map((val: any) => val?.value),
        branch: branch.branch,
      };
      dispatch(loading(true));
      const response: any = await axios.post(
        "/report/payments",
        readyFilterData
      );

      if (response.data.data.status > 0) {
        const res: any = response.data.data;
        const result = _TranformDataIntoChart(res.rows);
        setTableData(_TransformIntoTable(res.rows));
        // setTableData(_TransformIntoTable(res.rows));
        setChart((prev) => ({
          ...prev,
          chartData: result.chartData as [],
          category: result.category as [],
          stack: true,
        }));
      }
    } catch (err) {
      console.error(err);
      toast.error(`Service Unavailable!`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(loading(false));
  };

  function _TransformIntoTable(dataSource: any) {
    const months = Array.from(
      new Set(dataSource.map((prev: { MONTH: string }) => prev.MONTH))
    );
    let transformData: any[] = [];
    const createModel = (month: any) => {
      transformData.push({
        MONTH: month,
        total_amount: 0,
      });
    };
    months.forEach((val) => createModel(val));
    dataSource.forEach(
      (prev: {
        MONTH: string;
        legal_name: string;
        brand_name: string;
        total_amount: number;
      }) => {
        const monthIndex: number = months.indexOf(prev.MONTH);
        if (monthIndex === -1 || !prev.legal_name) {
          return;
        }

        transformData[monthIndex].total_amount += prev.total_amount;
      }
    );

    return transformData;
  }

  function _TranformDataIntoChart(dataSource: any) {
    const months = Array.from(
      new Set(dataSource.map((prev: { MONTH: string }) => prev.MONTH))
    );
    const brand = Array.from(
      new Set(dataSource.map((prev: { legal_name: string }) => prev.legal_name))
    ).filter(Boolean);
    let transformData: any[] = [];
    const createModel = (name: any) => {
      transformData.push({
        name: name,
        data: Array(months.length).fill(0),
      });
    };
    brand.forEach((val) => createModel(val));
    dataSource.forEach(
      (prev: {
        MONTH: string;
        legal_name: string;
        brand_name: string;
        total_amount: number;
      }) => {
        const monthIndex: number = months.indexOf(prev.MONTH);
        const brandIndex: number = brand.indexOf(prev.legal_name);
        if (monthIndex === -1 || !prev.legal_name) {
          return;
        }

        transformData[brandIndex].data[monthIndex] = prev.total_amount;
      }
    );

    let newTransform: any = [];

    const transformDataFn = (val: { name: string; data: number[] }) => {
      newTransform.push({
        name: val.name,
        y: val.data.reduce((a, b) => a + b, 0),
      });
    };
    transformData.forEach((val) => transformDataFn(val));
    // Tranform new Object

    newTransform = [
      {
        name: "Percentage",
        colorByPoint: true,
        data: newTransform,
      },
    ];

    return { chartData: newTransform, category: months };
  }

  return (
    <Fragment>
      <div className="expense-report-filter">
        <MBDatePicker
          onChange={(e) => setFilter((prev) => ({ ...prev, start_date: e }))}
          className="w-100"
          size="small"
          label="To"
          defaultValue={filter.start_date}
        />
        <MBDatePicker
          onChange={(e) => setFilter((prev) => ({ ...prev, end_date: e }))}
          className="w-100"
          size="small"
          label="From"
          defaultValue={filter.end_date}
          minDate={filter.start_date}
          maxDate={new Date().toString()}
        />
        {/* <Autocomplete
          id="EXPENSE_CATEGORY_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={EXPENSE_CATEGORY}
          size="small"
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, expense_category: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Expense Category" />
          )}
        />
        <Autocomplete
          id="BILLABLE_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={BILLABLE}
          defaultValue={filter.billable}
          size="small"
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, billable: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Billable" />}
        />
        <Autocomplete
          id="STATUS_expense_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={STATUS}
          size="small"
          defaultValue={filter.status}
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, status: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Status" />}
        /> */}
        <LoadingButton
          loading={false}
          loadingPosition="start"
          startIcon={<SearchIcon />}
          variant="contained"
          onClick={getCurrentBilling}
        >
          {" "}
          Search
        </LoadingButton>
      </div>
      <Divider className="divider-margin" />
      <div className="account-table">
        <Stack
          direction={"column"}
          width={"100%"}
          style={{ padding: "10px 0px" }}
        >
          <TableComponent
            exportFileName={`payments_${dayjs(new Date()).format(
              "DD-MM-YYYY"
            )}`}
            orderByColumnName={"MONTH"}
            displayName={displayName}
            sortingColumn={sortingColumn}
            dataSource={tableData}
            renderData={renderData}
            footer={tableFooter}
            pagination={true}
            setRenderData={(data) => setRenderData(data)}
          >
            {renderData.map((data, index: number) => (
              <TableRow key={index}>
                <TableCell>{data.MONTH}</TableCell>
                <TableCell>{methods._formatINR(data.total_amount)}</TableCell>
              </TableRow>
            ))}
          </TableComponent>
          <PieChart
            dataSource={chart.chartData}
            id="director-finance-fixed-expenses-report"
            title={`${filter.expense_category
              .map((val: any) => val?.label)
              .join(", ")} Payments (${dayjs(filter.start_date).format(
              "MMM-YYYY"
            )} to ${dayjs(filter.end_date).format("MMM-YYYY")})`}
            categories={chart.category}
            exporting={true}
            legend={false}
            yAxisTitle="Amount ( in rupees )"
          />
        </Stack>
      </div>
    </Fragment>
  );
};

export default PaymentReport;
