import React from "react";
import "./ComingSoon.css";

const ComingSoon = () => {
  return (
    <>
      <div className="container">
        <h2 className="title">
          <span className="title-word title-word-1">Coming</span>
          <span className="title-word title-word-2">Soon</span>
          <span className="title-word title-word-3">...</span>
        </h2>
      </div>
    </>
  );
};

export default ComingSoon;
