import React, { Children, cloneElement, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import ClearIcon from '@mui/icons-material/Clear';
import moment from "moment";
import Dialog from "material-ui/Dialog";
import FlatButton from "material-ui/FlatButton";
import TextField from '@mui/material/TextField';
import TimePicker from "material-ui/TimePicker";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { zIndex } from "material-ui/styles";
import axios from '../utils/axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import './Calender.css'
import PendingCards from "./PendingCards";
import { toast } from "react-toastify";
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import { useParams } from "react-router-dom";
// import DateCellWrapper from "./DateCellWrapper";
const label = { inputProps: { 'aria-label': 'Tea' } };


interface Props {
    room_title: string;
    room_id: string;
    data_type: string;
}
const token = localStorage.getItem('access_token');
const user_email = localStorage.getItem('email');
const headers = {
    authorization: token ? `${token}` : '',
    'Content-Type': 'application/json',
};
moment.locale("en");
const localizer = momentLocalizer(moment);

const TouchCellWrapper = ({ children, value, onSelectSlot }:any) =>
  cloneElement(Children.only(children), {
    onTouchEnd: () => onSelectSlot({ action: "click", slots: [value] }),
    style: {
      className: `${children}`
    }
  });

const CustomCalendar: React.FC<Props> = (props) => {
    // console.log(props)
    const [approver, setApprover] = useState<any>('');
    const [approverData, setApproverData] = useState<any[] | []>([]);
    const [meetingType, setMeetingType] = useState('')
    const [events, setEvents] = useState<any[]>([]);
    const [title, setTitle] = useState('');
    const [remarks, setRemarks] = useState('');
    const [refreshmentRemarks, setRefreshmentRemarks] = useState('');
    const [start, setStart] = useState<any>(null);
    const [end, setEnd] = useState<any>(null);
    const [desc, setDesc] = useState('');
    const [openSlot, setOpenSlot] = useState(false);
    const [openEvent, setOpenEvent] = useState(false);
    const [clickedEvent, setClickedEvent] = useState({});
    const [eventsData, setEventsData] = useState<any[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<any>({});
    const [deleteVisible, setDeleteVisible] = useState(false);
    const [disableApprove, setDisableApprove] = useState(false);
    const [checkedItems, setCheckedItems] = useState<any>({});
    const [itcheckedItems, setITCheckedItems] = useState<any>({});
    const [disableCalender, setDisableCalenders] = useState(props.data_type === 'pending' ? false : true);
    const [validTitle, setValidTitle] = useState('');
    const [validTime, setValidTime] = useState('');
    const [validMeeting, setValidMeeting] = useState('');
    const [validApprover, setValidApprover] = useState('');
    const [validStart, setValidStart] = useState('');
    const [validEnd, setValidEnd] = useState('');
    const [cancelledRemarks, setCancelledRemarks] = useState('');
    const [isValidcancelledRemarks, setIsValidCancelledRemarks] = useState('');
    const [defaultView, setDefaultView] = useState<any>(window.innerWidth < 768 ? 'day':'week');
    const [CalenderView, setCalenderView] = useState<any>(window.innerWidth < 768 ? ['day']:['week','day']);
   

const {room_id} = useParams();
console.log("searchParams", room_id)
    useEffect(() => {
        getApproverEmails();
        getEventsData();



    }, [])

    const handleITCheckboxChange = async (event: any) => {
        const { name, it_items } = event.target;
        setITCheckedItems((prevCheckedItems: any) => ({
            ...prevCheckedItems,
            [name]: it_items,
        }));
        // console.log("itcheckedItems", itcheckedItems)
    };

    const handleCheckboxChange = async (event: any) => {
        const { name, checked } = event.target;
        setCheckedItems((prevCheckedItems: any) => ({
            ...prevCheckedItems,
            [name]: checked,
        }));
        // console.log("checkedItems", checkedItems)
    };



    const getApproverEmails = async () => {
        try {
            const response = await axios.get(`/get-approver-data?room_id=${props.room_id}`, { headers });
            const getApproverData = response.data.getApproverData;
            setApproverData(getApproverData);
        } catch (error) {
            console.log('getApproverEmails_Error:', error);
        }
    };

    const handleCancelledRemarks = async (event:any) => {
        setIsValidCancelledRemarks("");
        setCancelledRemarks(event.target.value)
    }

    const getEventsData = async () => {
        try {
            const response = await axios.get(`/get-events-data?room_id=${room_id}`, { headers });
            console.log("response",response)
            const EventsData = response?.data?.EventsData;
            setEventsData(EventsData);
            // console.log('eventsData', EventsData);
        } catch (error) {
            console.log('getEventsData_Error:', error);
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };


    const handleClose = () => {
        setOpenSlot(false);
        setOpenEvent(false);
        // console.log("hello1")
    };
    function hasEmail(users: any, email: any) {
        return users.some((user: any) => user.approver_email === email);
    }

    const handleSlotSelected = (slotInfo: any) => {

        // console.log(slotInfo)
        if (new Date(slotInfo.start) > new Date()) {
            if (hasEmail(approverData, user_email)) {
                // console.log("hasEmail", hasEmail)
                setStart(slotInfo.start);
                setEnd(slotInfo.end);
                setOpenSlot(true);
                setCheckedItems({})
                setApprover(user_email)
                setDisableApprove(true)
                setMeetingType('')
                setValidTitle('')
                setValidMeeting('')
                setValidApprover('')

            } else {
                // console.log("hasEmail2", hasEmail)
                setStart(slotInfo.start);
                setEnd(slotInfo.end);
                setOpenSlot(true);
                setCheckedItems({})
                setApprover('')
                setMeetingType('')
                setDisableApprove(false)
                // console.log("hello2", slotInfo)
            }
        } else {
            toast.error("Back date bookings not allowed")
        }


    };

    const handleEventSelected = (event: any) => {
        // console.log("slotInfo", event)
        setStart(event.start);
        setEnd(event.end);
        setTitle(event.title);
        setDesc(event.desc);
        setClickedEvent(event);
        setOpenEvent(true);
        setSelectedEvent(event)
        // console.log("hello3")
        if (event.user_email === user_email || event.approver_email === user_email) {
            setDeleteVisible(true);
        } else {
            setDeleteVisible(false);
        }

    };



    const setNewAppointment = async () => {
        try {
            if (title === '') {
                setValidTitle('Title field is required.');;
                return;
            }
            if (!validateTime(start)) {
                setValidStart('Start value is invalid.');
                return;
            }
            if (!validateTime(end)) {
                setValidEnd('End value is invalid.');
                return;
            }
            if (approver === '') {
                setValidApprover('Approver is required.');
                return;
            }
            if (meetingType === '') {
                setValidMeeting('Meeting type is required.');
                return;
            }
            handleClose();
            const it_assistance =  checkedItems.it_assistance;
            delete checkedItems.it_assistance;
            const keysWithTrueValue = Object.keys(checkedItems).filter((key) => checkedItems[key]);
            const remarks = `${keysWithTrueValue.join(', ')} - ${refreshmentRemarks}`;
            // console.log(remarks)
            setRemarks(remarks);

            let status = 'Pending';
            if (approver === user_email) {
                status = 'Approved'
            }
            const conf_room_id = room_id;
            const conf_room = props.room_title;

            const appointment = { title, start, end, desc, meetingType, approver, remarks, status, conf_room_id, user_email, conf_room, it_assistance };
            // console.log("appointment",appointment)

            const response = await axios.post('/create-booking', { appointment }, { headers });
            // console.log("setNewAppointment", response);

            if (response.data.message === 'Booking done successfully') {
                // console.log('Booking done successfully')
                toast.success(response.data.message);
                setEvents([...eventsData, appointment]);
                getEventsData();

            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log('setNewAppointment_Error:', error);
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };

    const updateEvent = () => {
        const updatedEvents = events.map((event) => {
            if (event === clickedEvent) {
                return { ...event, title, desc, start, end };
            }
            return event;
        });
        setEvents(updatedEvents);
        // console.log("hello4")
    };

    const deleteEvent = async () => {
        try {
            if(cancelledRemarks.trim() === ""){
                setIsValidCancelledRemarks("Please enter cancellation remarks first");
                return;
            }
            handleClose();
            const data = updatedEventsData.filter((event: any) => event.start._i === start._i);
            console.log(updatedEventsData, start._i, data[0]?.booking_id, user_email, cancelledRemarks)
            const response = await axios.delete(`/cancel-booking/${data[0]?.booking_id}/${user_email}/${cancelledRemarks}`)
            if (response) {
                getEventsData();
                toast.success("Event cancelled successfully")
            }


        } catch (err) {
            console.log(err)
        }
    };

    const handleChangeSelect1 = (event: any) => {
        setValidApprover('')
        const value = event.target.value
        setApprover(value);

    };

    const handleChangeSelect2 = (event: any) => {
        setValidMeeting('')
        setMeetingType(event.target.value);
    };

    const handleMyBooking = (event: any) => {
        const filteredData = eventsData.filter(
            (obj: any) => obj.user_email.includes(user_email) || obj.approver_email.includes(user_email)
        );

        setEventsData(filteredData)
    }
    // const eventActions: any = [

    //     deleteVisible && (

    //         <Button variant="contained" size="large" onClick={() => {
    //             deleteEvent();
    //             handleClose();
    //         }}>
    //             Delete
    //         </Button>
    //     )

    // ];

    // const appointmentActions = [

    //     <Button variant="contained" size="large" onClick={() => {
    //         setNewAppointment();
    //         // handleClose();
    //     }}>
    //         Submit
    //     </Button>,

    // ];
    let updatedEventsData: any
    console.log(eventsData)
    const changedData:any = eventsData
    .map(meeting => {
        const formattedStart = moment(meeting.start).utcOffset("+05:30");
        const formattedEnd = moment(meeting.end).utcOffset("+05:30");
      
        return {
          ...meeting,
          start: formattedStart,
          end: formattedEnd
        };
      });
    //  eventsData.map(item => {
    //     const utcDate = new Date(item.date).toISOString();
        
    //     return { ...item, date: utcDate };
    //   });
    //   console.log("updatedEventsData",updatedEventsData)

    if (props.data_type === 'all_booking') {
        updatedEventsData = changedData.filter((obj:any) => obj.booking_status === 'Approved' || obj.booking_status === 'Pending');
    } else if (props.data_type === 'my_booking') {
        updatedEventsData = changedData.filter(
            (obj: any) => (
                (obj.user_email.includes(user_email) || obj.approver_email.includes(user_email)) &&
                (obj.booking_status === 'Approved' || obj.booking_status === 'Pending')
            )
        );
        // console.group("updatedEventsData",updatedEventsData)

    } else if (props.data_type === 'pending') {

        updatedEventsData = changedData.filter((obj:any) => obj.booking_status === 'Pending');
        // console.log("updatedEventssssssssssst", updatedEventsData, eventsData)
    }

    // console.log("updatedEventsData",updatedEventsData)


    const minTime = new Date(); // Specify the minimum time as the current date and time
    minTime.setHours(8, 0, 0); // Set the minimum time to 8:00 AM

    const maxTime = new Date(); // Specify the maximum time as the current date and time
    maxTime.setHours(22, 0, 0); // Set the maximum time to 6:00 PM
    const handleTitle = (e: any) => {
        setValidTitle('')
        const value = e.target.value;
        setTitle(value);

    }

    const validateTime = (time: any) => {
        const selectedTime = new Date(time);
        const selectedHour = selectedTime.getHours();

        if (selectedHour < 8 || selectedHour > 22) {
            // Time is outside the allowed range, show an error or handle it as needed
            // console.log("Invalid time selection");
            return false;
        }

        return true;
    };







    return (
        <>
        {/* {console.log(moment(updatedEventsData[0].start).toDate())} */}
            {disableCalender ? (
                <div id="Calendar" className="calendar-booking-slot">

                    
                    {/* <h2 >{props.room_title}</h2> */}
                    <Calendar
                        localizer={localizer}
                        events={updatedEventsData}
                        views={["month", "week", "day", "agenda"]}
                        timeslots={2}
                        defaultView={defaultView}
                        defaultDate={new Date()}
                        selectable={true}
                        longPressThreshold={-1}
                        eventPropGetter={(event) => {
                            let backgroundColor;
                            let color;
                            if (event.booking_status === 'Approved') {
                                backgroundColor = '#10f710';
                                color = 'black'
                            } else if (event.booking_status === 'Pending') {
                                backgroundColor = '#ffff8f';
                                color = 'black';
                            } else {
                                backgroundColor = 'red';
                                color = 'black';

                            }

                            return {
                                style: {
                                    backgroundColor,
                                    color,
                                },
                            };
                        }}
                        // startAccessor={(event) => moment(event.start, "MM/DD/YYYY, h:mm:ss a").toDate()}
                        // endAccessor={(event) => moment(event.end, "MM/DD/YYYY, h:mm:ss a").toDate()}
                        formats = {{
                            agendaHeaderFormat: ({start, end}) => {
                                return (moment.utc(start).format('DD/MM/YYYY') + ' - ' + moment.utc(end).format('DD/MM/YYYY') );
                            }
                        }}
                        startAccessor={(event) => new Date(event.start)}
                        endAccessor={(event) => new Date(event.end)}
                        onSelectEvent={handleEventSelected}
                        onSelectSlot={handleSlotSelected}
                        min={minTime}
                        max={maxTime}

                        // defaultView='week'
                        components={{

                            event: ({ event }) => (
                                <div>
                                    {/* <div><strong>Approver</strong> : {event.approver_email}</div>
                                    <div><strong>Meeting Type</strong> : {event.meeting_type}</div>
                                    <div><strong>Booked By</strong> : {event.user_email}</div>
                                    <div><strong>Status</strong> : {event.booking_status}</div>
                                    <div><strong>Remarks</strong> : {event.remarks}</div> */}

                                </div>
                            ),
                        }}
                    />

                    <Dialog

                        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className='booking_appointment-modal'>
                            <p>Book an appointment on {moment(start).format("MMMM Do YYYY")} </p><ClearIcon onClick={handleClose}
                                className="clear-icon" />
                        </div>
                        }

                        modal={false}
                        open={openSlot}
                        className="appointment-modal"
                        // scroll="paper"
                        onRequestClose={handleClose}
                        style={{ zIndex: "10" }}

                    >
                        <div>
                            <TextField

                                id="outlined-basic"
                                label="Booking Title"
                                variant="outlined"
                                // value={email}
                                onChange={handleTitle}
                                className="dialog-textfield"
                                required={true}
                            />

                            <p style={{ color: "red" }} className="error">{validTitle}</p>
                            {/* <TextField
                            floatingLabelText="Title"
                            onChange={(e: any) => setTitle(e.target.value)}
                            className="dialog-textfield"
                        /> */}
                            <br />
                            <TextField

                                id="outlined-basic"
                                label="Booking Description"
                                variant="outlined"
                                // value={email}
                                onChange={(e: any) => setDesc(e.target.value)}
                                className="dialog-textfield"
                                // required={true}
                            />

                            <br />
                            <div>
                            <p className="error" style={{ marginTop: "10px" }}>IT</p>
                            <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.it_assistance || false}
                                            onChange={handleCheckboxChange}
                                            name="it_assistance"
                                        />
                                    }
                                    label="IT Assistance"
                                />
                            </div>
                            <p className="error" style={{ marginTop: "10px" }}>Refreshments</p>
                            <div className="refreshment">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.tea_coffee || false}
                                            onChange={handleCheckboxChange}
                                            name="tea_coffee"
                                        />
                                    }
                                    label="Tea/Coffee"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.cold_drinks || false}
                                            onChange={handleCheckboxChange}
                                            name="cold_drinks"
                                        />
                                    }
                                    label="Cold Drinks"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.snacks || false}
                                            onChange={handleCheckboxChange}
                                            name="snacks"
                                        />
                                    }
                                    label="Snacks"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.lunch || false}
                                            onChange={handleCheckboxChange}
                                            name="lunch"
                                        />
                                    }
                                    label="Lunch"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checkedItems.stationery || false}
                                            onChange={handleCheckboxChange}
                                            name="stationery"
                                        />
                                    }
                                    label="Stationery"
                                />
                            </div>
                            <TextField
                                id="outlined-basic"
                                label="Refreshment remarks"
                                variant="outlined"
                                // value={email}
                                onChange={(e: any) => setRefreshmentRemarks(e.target.value)}
                                className="dialog-textfield"
                                // required={true}
                                />

                            <div className='timepicker-container'>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={6}>
                                        <TimePicker
                                            format="ampm"
                                            floatingLabelText="Start Time"
                                            minutesStep={5}
                                            style={{ width: "100%" }}
                                            value={start}
                                            onChange={(_, date) => {
                                                setValidStart(''); setStart(date)
                                            }}

                                        />

                                        <p className="error" style={{ color: "red" }}>{validStart}</p>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TimePicker
                                            format="ampm"
                                            floatingLabelText="End Time"
                                            minutesStep={5}
                                            style={{ width: "100%" }}
                                            value={end}
                                            onChange={(_, date) => { setValidEnd(''); setEnd(date) }}
                                        />
                                        <p className="error" style={{ color: "red" }}>{validEnd}</p>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className='dialog-select-options'>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">Approver</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Approver"
                                                value={approver}
                                                disabled={disableApprove}
                                                required={true}
                                                onChange={handleChangeSelect1}
                                            >
                                                {approverData.map((data: any) => (
                                                    <MenuItem value={data && data?.approver_email} >{data && data?.approver_email}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                        <p className="error" style={{ color: "red" }}>{validApprover}</p>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Meeting Type</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Meeting Type"
                                                value={meetingType}
                                                required={true}
                                                onChange={handleChangeSelect2}
                                            >

                                                <MenuItem value="Client">Client</MenuItem>
                                                <MenuItem value="Cross Team">Cross Team</MenuItem>
                                                <MenuItem value="External Team">External Team</MenuItem>
                                                <MenuItem value="Internal Team">Internal Team</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                                <MenuItem value="Vendor">Vendor</MenuItem>

                                            </Select>
                                        </FormControl>
                                        <p className="error" style={{ color: "red" }}>{validMeeting}</p>
                                    </Grid>
                                </Grid>
                                {/* <div style={{ width: "48%" }}>
                                    <Box >
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Approver</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Approver"
                                                value={approver}
                                                disabled={disableApprove}
                                                required={true}
                                                onChange={handleChangeSelect1}
                                            >
                                                {approverData.map((data: any) => (
                                                    <MenuItem value={data && data?.approver_email} >{data && data?.approver_email}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <p style={{ color: "red" }}>{validApprover}</p>

                                </div>
                                <div style={{ width: "48%" }}>
                                    <Box >
                                        <FormControl fullWidth >
                                            <InputLabel id="demo-simple-select-label">Meeting Type</InputLabel>
                                            <Select

                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                label="Meeting Type"
                                                value={meetingType}
                                                required={true}
                                                onChange={handleChangeSelect2}
                                            >

                                                <MenuItem value="Client">Client</MenuItem>
                                                <MenuItem value="Cross Team">Cross Team</MenuItem>
                                                <MenuItem value="External Team">External Team</MenuItem>
                                                <MenuItem value="Internal Team">Internal Team</MenuItem>
                                                <MenuItem value="Other">Other</MenuItem>
                                                <MenuItem value="Vendor">Vendor</MenuItem>

                                            </Select>
                                        </FormControl>
                                        <p style={{ color: "red" }}>{validMeeting}</p>
                                    </Box>
                                </div>*/}

                            </div>
                            <Button style={{ marginTop: "20px" }} variant="contained" size="large" onClick={() => {
                                setNewAppointment();
                                // handleClose();
                            }}>
                                Submit
                            </Button>
                        </div>
                    </Dialog>

                    <Dialog
                        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <p>Booking Details of {moment(start).format("MMMM Do YYYY")}</p><ClearIcon onClick={handleClose}
                                className="clear-icon" />
                        </div>
                        }

                        modal={false}
                        open={openEvent}
                        onRequestClose={handleClose}

                    >

                        <div>

                            <p><strong>Conference Room</strong> : {props.room_title}</p>
                            <p><strong>Time</strong> : {moment(selectedEvent?.start).format('h:mm A')} - {moment(selectedEvent.end).format('h:mm A')}
                                {/* :{new Date(selectedEvent?.start).getMinutes().toString()} - {new Date(selectedEvent?.end).getHours().toString()}:{new Date(selectedEvent?.end).getMinutes().toString()} */}
                            </p>
                            <p><strong>Booked By</strong> : {selectedEvent?.user_email}</p>
                            <p><strong>Approver</strong> : {selectedEvent?.approver_email}</p>
                            <p><strong>Meeting Type</strong> : {selectedEvent?.meeting_type}</p>
                            <p><strong>Status</strong> : {selectedEvent.booking_status}</p>
                            <p><strong>Remarks</strong> : {selectedEvent?.remarks}</p>
                            {deleteVisible && (
                                <>
                                <div style={{margin:"10px 0"}}>
                                 <TextField

                                 id="outlined-basic"
                                 label="Cancelled Remarks"
                                 variant="outlined"
                                 value={cancelledRemarks}
                                 onChange={handleCancelledRemarks}
                                 className="dialog-textfield"
                                 required={true}
                             />
                             <p style={{ color: "red" }}>{isValidcancelledRemarks}</p>
                             </div>
                            <Button variant="contained" size="large" onClick={() => {
                                deleteEvent();

                            }}>
                                Delete
                            </Button>
                            </>
                            )}
                        </div>


                    </Dialog>



                </div>)
                : (
                    <PendingCards data={updatedEventsData} />
                )

            }



        </>
    );
};

export default CustomCalendar;
