import axios from "../utils/axios";
import { toast } from "react-toastify";
export const loginUser: any =
  (complete_email: any, otp: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: "loginRequest",
      });
      const { data } = await axios.post(
        "/verify-otp",
        { complete_email, otp },
        { headers: { "Content-Type": "application/json" } }
      );
      // console.log("redux",data)
      await localStorage.setItem("access_token", data.token);
      await localStorage.setItem("email", data.complete_email);
      dispatch({
        type: "loginSuccess",
        payload: data,
      });
      window.location.href = "/";
    } catch (error: any) {
      console.log("error", error);
      toast.error(error.response.data.message);
      dispatch({
        type: "loginFailuer",
        payload: error,
      });
    }
  };

export const loadUser: any = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "LoadUserRequest",
    });
    const data = localStorage.getItem("email");
    // console.log(data)
    if (data) {
      dispatch({
        type: "LoadUserSuccess",
        payload: data,
      });
    } else {
      dispatch({
        type: "LoadUserFailuer",
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: "LoadUserFailuer",
      payload: error,
    });
  }
};

export const logOut: any = () => async (dispatch: any) => {
  try {
    dispatch({
      type: "LogOutRequest",
    });
    localStorage.clear();

    dispatch({
      type: "LogOutSuccess",
    });
    window.location.href = "/login";
  } catch (error) {
    dispatch({
      type: "LogOutFailure",
      payload: error,
    });
  }
};

export const loginUserMeetingApproval: any =
  (email: any) => async (dispatch: any) => {
    try {
      dispatch({
        type: "loginRequest",
      });
      const { data } = await axios.post(
        "/login-approve-meeting",
        { email },
        { headers: { "Content-Type": "application/json" } }
      );
      // console.log("redux",data)
      await localStorage.setItem("access_token", data.token);
      await localStorage.setItem("email", data.email);
      dispatch({
        type: "loginSuccess",
        payload: data,
      });
      // window.location.href = '/home'
    } catch (error) {
      dispatch({
        type: "loginFailuer",
        payload: error,
      });
    }
  };
