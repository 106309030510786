import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import * as Util from '../utility';

export class ExpensesService {
  private https: HttpService;
  private methods: Util.Methods;

  constructor() {
    this.https = new HttpService();
    this.methods = new Util.Methods();
  }

  async getExpenses(branch: string | number = 0, expenseType: 'Variable' | 'Fixed' | 'All', billable: 'billable' | 'non billable' | 'operational' | 'All') {
    const baseURL = BASE_URL + `/get/expenses?branch=${branch}&expenseType=${expenseType !== 'All' ? expenseType : ''}&billable=${billable !== 'All' ? billable : ''}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`getUsers Service Error :: ${err}`);
    }
  }

  async getClientExpenses(clientId: string | number = 0) {
    const baseURL = BASE_URL + `/get/expenses/client/${clientId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`getUsers Service Error :: ${err}`);
    }
  }

  async getBillingExpenses(id: string | number = 0) {
    const baseURL = BASE_URL + `/get/created/billing/expenses/${id}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`getUsers Service Error :: ${err}`);
    }
  }


}
