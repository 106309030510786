// import { Logger } from "./Logger";
import { CryptoJS } from "./cryptojs";
import * as XLSX from "xlsx";

export class Methods {
  // logger = new Logger();
  crypto = new CryptoJS();

  formatDatetime(date: Date, format: string) {
    const _padStart = (value: number): string =>
      value.toString().padStart(2, "0");
    try {
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date provided");
      }

      return format
        .replace(/yyyy/g, _padStart(date.getFullYear()))
        .replace(/dd/g, _padStart(date.getDate()))
        .replace(/mm/g, _padStart(date.getMonth() + 1))
        .replace(/hh/g, _padStart(date.getHours()))
        .replace(/mi/g, _padStart(date.getMinutes()))
        .replace(/ss/g, _padStart(date.getSeconds()))
        .replace(/ms/g, _padStart(date.getMilliseconds()));
    } catch (error) {
      throw new Error("formatDatetime : " + JSON.stringify(error));
    }
  }

  formatUTCDatetime(date: Date, format: string) {
    const _padStart = (value: number): string =>
      value.toString().padStart(2, "0");
    try {
      if (isNaN(date.getTime())) {
        throw new Error("Invalid Date provided");
      }

      return format
        .replace(/yyyy/g, _padStart(date.getUTCFullYear()))
        .replace(/dd/g, _padStart(date.getUTCDate()))
        .replace(/mm/g, _padStart(date.getUTCMonth() + 1))
        .replace(/hh/g, _padStart(date.getUTCHours()))
        .replace(/mi/g, _padStart(date.getUTCMinutes()))
        .replace(/ss/g, _padStart(date.getUTCSeconds()))
        .replace(/ms/g, _padStart(date.getUTCMilliseconds()));
    } catch (error) {
      throw new Error("formatUTCDatetime : " + JSON.stringify(error));
    }
  }

  getDateTimeStamp_JS() {
    // 2020-05-12T16:23:16Z

    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatDatetime(new Date(), "yyyy-mm-ddThh:mi:ssZ");
    } catch (error) {
      throw error;
    }
  }

  getDateTimeStamp() {
    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatDatetime(new Date(), "yyyymmddhhmiss");
    } catch (error) {
      throw error;
    }
  }

  getDateMySQL(date: Date) {
    try {
      // Get year, month, and day components from the date
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Adding 1 to month since it's zero-based
      const day = String(date.getDate()).padStart(2, "0");

      // Create the 'YYYY-MM-DD' formatted string
      const formattedDate = `${year}-${month}-${day}`;
      // today = yyyy + '-' + mm + '-' + dd;
      // return this.formatDatetime(new Date(), "yyyy-mm-dd");
      return formattedDate;
    } catch (error) {
      throw error;
    }
  }

  getDate() {
    try {
      //today = yyyy + '' + mm + '' + dd
      return this.formatDatetime(new Date(), "yyyymmdd");
    } catch (error) {
      throw error;
    }
  }

  getDTS() {
    try {
      //today = (yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss + '.' + ms);
      return this.formatDatetime(new Date(), "yyyymmddhhmiss.ms");
    } catch (error) {
      throw error;
    }
  }

  getUTCDateTimeStamp() {
    try {
      // today = yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss;
      return this.formatUTCDatetime(new Date(), "yyyymmddhhmiss");
    } catch (error) {
      throw error;
    }
  }

  getUTCDateMySQL() {
    try {
      // today = yyyy + '-' + mm + '-' + dd;
      return this.formatUTCDatetime(new Date(), "yyyy-mm-dd");
    } catch (error) {
      throw error;
    }
  }

  getUTCDate() {
    try {
      //today = yyyy + '' + mm + '' + dd
      return this.formatUTCDatetime(new Date(), "yyyymmdd");
    } catch (error) {
      throw error;
    }
  }

  getUTCDTS() {
    try {
      //today = (yyyy + '' + mm + '' + dd + '' + hh + '' + min + '' + ss + '.' + ms);
      return this.formatUTCDatetime(new Date(), "yyyymmddhhmiss.ms");
    } catch (error) {
      throw error;
    }
  }

  getAuthKey() {
    let utcDate;
    let authKey = "";
    try {
      // today = yyyy + '-' + mm + '-' + dd;
      utcDate = this.formatUTCDatetime(new Date(), "yyyy-mm-dd");
      authKey = this.crypto.MD5(utcDate);
      return authKey;
    } catch (error) {
      throw error;
    }
  }

  async timeDelay(sec: number) {
    return new Promise((resolve) => setTimeout(resolve, sec * 1000));
  }

  decimalFormat(value: number | string) {
    try {
      //check number is Integer?
      if (typeof value === "string") {
        value = +value;
      }

      let _isInteger = Number.isInteger(value);
      if (!_isInteger) {
        return value.toFixed(0);
      } else {
        return value;
      }
    } catch (err) {
      throw new Error(`decimalFormat Error :: ${err}`);
    }
  }

  //Get all query Params
  _GetQueryParams(locations: any): Object {
    let queryParams: { [key: string]: any } = {};
    try {
      let getQuerys = new URLSearchParams(locations);
      getQuerys.forEach((v, k) => (queryParams[k] = v));

      return queryParams;
    } catch (error) {
      throw new Error(`_GetQueryParams method error : ${error}`);
    }
  }

  /**
   * Filters an array based on the provided selectedIds, dataSource, filterObjectKey, and optional stats parameter.
   * @param selectedIds - An array of selected IDs.
   * @param dataSource - The data source array to filter.
   * @param filterObjectKey - The key to use for filtering the data source array.
   * @param stats - An optional parameter to specify the type of filtering. If set to 'not_exist', it will filter for values that do not exist in selectedIds.
   * @returns The filtered array.
   * Its working only 2D array
   */
  filterArray(
    selectedIds: any[],
    dataSource: any[],
    filterObjectKey: string,
    stats?: "not_exist"
  ) {
    try {
      return dataSource.filter((data: any) =>
        eval(
          `${selectedIds.indexOf(data[filterObjectKey])} ${stats ? "=" : "!"
          }== -1`
        )
      );
    } catch (err) {
      console.log("filterArray ==> " + err);
    }
  }

  /**
   * Generates a random number between 100000 and 999999.
   *
   * @returns The generated random number.
   */
  getRandNumber() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  //Format number upto two decimal places
  roundToTwoDecimalPlaces(value: number) {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  /**
   * Function Convert Object into Params
   * @param Get Object Param For Example {age: 40, name: 'test'}
   * @return age=40&name=test
   */
  objConvertIntoParam(obj: Object) {
    let array: string[] = new Array();
    Object.entries(obj).forEach(([x, y]) => array.push(`${x}=${y}`));
    return array.join("&");
  }

  _getFormValues(formId: string) {
    const formData: { [key: string]: any } = {};
    try {
      const elements: any =
        (document.getElementById(formId) as HTMLFormElement) || [];
      for (let i = 0; elements.length > i; i++) {
        const key = elements[i]["name"];
        if (key) {
          formData[key] = elements[i]["value"];
        }
      }
    } catch (error) {
      throw new Error(`getFormValue Failed method :: ${error}`);
    }
    return formData;
  }

  _prefillFormValues(formId: string, dataSource: { [key: string]: any }) {
    const formElement: any = document.getElementById(formId);
    if (formElement) {
      for (let index = 0; formElement.length > index; index++) {
        const key: any = formElement[index]["name"];
        if (key) {
          console.log(key, dataSource[key]);
          formElement[index]["value"] = dataSource[key] || null;
        }
      }
    }
  }
  _formatINR(amount: number, decimal: boolean = true) {
    if (typeof amount !== "number") {
      throw new Error("Amount must be a number");
    }

    if (decimal) {
      return amount.toLocaleString("en-IN", {
        // style: "currency",
        // currency: "INR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }

      return amount.toLocaleString("en-IN", {
        // style: "currency",
        // currency: "INR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });

  }

  exportTableData(tableId: string, fileName: string) {
    try {
      const table = document.getElementById(tableId);
      const worksheet = XLSX.utils.table_to_sheet(table);

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Export the workbook to an XLSX file
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
    } catch (error) {
      throw new Error("failed Export Excel in Table");
    }
  }

  exportToExcel(excelData: any, fileName: any) {
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    const url = window.URL.createObjectURL(data);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${fileName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  _ArraySum(arr: number[]) {
    let total = 0;
    try {
      total = arr.reduce((a, b) => a + b, 0);
    } catch (error) {
      console.error(`_ArraySum method error :: ${error}`);
    }

    return total;

  }


}
