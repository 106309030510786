import React, { useState, useEffect, Fragment } from "react";
import Highcharts from "highcharts";
import highchartsNoData from "highcharts/modules/no-data-to-display";
import HighchartsExporting from "highcharts/modules/exporting";
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}
interface Props {
  id?: string;
  className?: string;
  stackingColumns?: boolean;
  dataSource: any[];
  title?: string;
  categories?: any[];
  chartType?: string;
  exporting?: boolean;
  height?: string;
  yAxisTitle?: string;
  legend?: boolean;
  toolTipName?: string;
}

export const LineBarColumn: React.FC<Props> = ({
  id = '',
  className = '',
  stackingColumns = false,
  dataSource = [],
  title = "",
  categories = [],
  chartType = "column",
  exporting = false,
  height,
  yAxisTitle = "Values",
  legend = true,
  toolTipName = "",
}: Props) => {
  const [chartId] = useState(Math.round(Math.random() * 1000));
  const [chart_type, setChart_Type] = useState<string>(chartType); // Default chart type is "spline"
  const [labelsEnabled, setLabelsEnabled] = useState<boolean>(true);

  useEffect(() => {
    setChart_Type(chartType);
  }, [chartType]);

  useEffect(() => {
    try {
      highchartsNoData(Highcharts);
      let options: Highcharts.Options;
      options = {
        chart: {
          type: chart_type, // Dynamically set chart type
          height: height,
        },
        title: {
          text: title || "",
        },
        xAxis: {
          labels: {
            style: {
              textOverflow: "none",
            },
          },
          categories: categories,
        },
        yAxis: {
          title: {
            text: yAxisTitle,
          },
        },
        legend: {
          enabled: legend,
        },
        plotOptions: {
          bar: {
            pointPadding: 0.2,
            // borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled,
            },
            stacking: stackingColumns ? 'normal' : undefined
          },
          column: {
            pointPadding: 0.2,
            // borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled,
            },
            stacking: stackingColumns ? 'normal' : undefined
          },
          line: {
            // borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled,
            },
          },
          spline: {
            // borderWidth: 0,
            enableMouseTracking: true,
            dataLabels: {
              enabled: labelsEnabled,
            },
          },
        },
        lang: {
          noData: "No data to display!",
        },
        noData: {
          position: {
            x: 0,
            y: 0,
            align: "center",
            verticalAlign: "middle",
          },
        },
        series: dataSource,
        exporting: {
          enabled: exporting,
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                //'downloadPNG',
                "downloadJPEG",
                "downloadPDF",
                //'downloadSVG',
                "downloadCSV",
                //'downloadXLS',
              ],
            },
          },
        },
        credits: {
          enabled: false,
        },
      };

      Highcharts.chart(`container-${chartId}`, options);
      return;
    } catch (error) {
      throw new Error(
        `Chart Component Failed component failed :: ${JSON.stringify(error)}`
      );
    }
  }, [chartId, title, chart_type, labelsEnabled, categories, dataSource]);

  return ( 
    <div className={`highchart-custom-container ${className} `} id={`${id}`}>
      <div className="highchart-btns">
        <fieldset onChange={(e: any) => setChart_Type(e.target.value || "bar")}>
          <input
            value="bar"
            name="chart"
            id={`bar-chart-icon-${chartId}_id`}
            type="radio"
            checked={chart_type === "bar"}
          />
          <label htmlFor={`bar-chart-icon-${chartId}_id`}>
            <BarChartIcon className="bar-icon" />
          </label>
          <input
            value="column"
            name="chart"
            id={`column-chart-icon-${chartId}_id`}
            type="radio"
            checked={chart_type === "column"}
          />
          <label htmlFor={`column-chart-icon-${chartId}_id`}>
            <BarChartIcon  />
          </label>
          <input
            value="line"
            name="chart"
            id={`line-chart-icon-${chartId}_id`}
            type="radio"
            checked={chart_type === "line"}
          />
          <label htmlFor={`line-chart-icon-${chartId}_id`}>
            <StackedLineChartIcon />
          </label>
          <input
            value="spline"
            name="chart"
            id={`spline-chart-icon-${chartId}_id`}
            type="radio"
            checked={chart_type === "spline"}
          />
          <label htmlFor={`spline-chart-icon-${chartId}_id`}>
            <StackedLineChartIcon />
          </label>
        </fieldset>
        <fieldset>
          <input
            name="chart"
            id={`label-${chartId}_id`}
            type="checkbox"
            checked={labelsEnabled}
            onClick={() => setLabelsEnabled(!labelsEnabled)}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label htmlFor={`label-${chartId}_id`}>
            Label
          </label>
        </fieldset>
      </div>
      <div className="highchart-content">
        <div id={`container-${chartId}`} />
      </div>
    </div>
  );
};
