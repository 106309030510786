import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel } from '@mui/material';
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';

import axios from "../../utils/axios";


interface EditAccountProps {
    id: string;
    user: number;
    employee: number;
    name: string;
    exit_date: string;
    assinged_date: string;
}

const EditResource: React.FC<EditAccountProps> = ({ id, user, employee, name, exit_date, assinged_date }) => {
    const [open, setOpen] = useState(false);
    const [employeeId, setEmployeeId] = useState<number>(employee);
    const [Name, setName] = useState(name);
    const [assingedDate, setAssingedDate] = useState<string>(assinged_date);
    const [exitDate, setExitDate] = useState<string>(exit_date);
    const [userId, setUserId] = useState<number>(user);

    const [allEmployees, setAllEmployees] = useState([]);
    const [allUser, setAllUsers] = useState([]);

    const handleEmployeeIdChange = (e: any) => {
        const newSelectedEmployeeId = e.target.value; // This is the id of the selected bd_team
        console.log(newSelectedEmployeeId);
        const parsedOpstId = parseInt(newSelectedEmployeeId, 10);
        setEmployeeId(parsedOpstId);
    }

    const handleUserIdChange = (e: any) => {
        const newSelectedUserId = e.target.value; // This is the id of the selected bd_team
        console.log(newSelectedUserId);
        const parsedOpstId = parseInt(newSelectedUserId, 10);
        setUserId(parsedOpstId);
    }



    //   const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // take name,id from token

    const handleEdit = async () => {

        const response: any = await axios.post(`/create-resource`, {
            "user_id": userId, "employee_id": employeeId, "name": Name, "assinged_date": assingedDate, "exit_date": exitDate, "id": id
        })

        console.log(response);

        toast.success(`Successfully Inserted!`, {
            position: 'top-right',
            autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setOpen(false);
        window.location.reload();
    }

    useEffect(() => {

        const getAllUsers = async () => {
            const response: any = await axios.post(`/get-all-managers-employees`, {})
            console.log(response.data);
            setAllUsers(response.data.managers);
        }

        const getAllEmployees = async () => {
            const response: any = await axios.post(`/get-all-team-employees`, {})
            console.log(response.data);
            setAllEmployees(response.data.teams);
        }

        getAllUsers();
        getAllEmployees();

    }, [])

    const handleinsertopen = () => {
        setOpen(true);
    }

    return (
        <div>
            <IconButton color="primary" style={{ height: '10px' }}>
                <EditIcon onClick={handleinsertopen} />
            </IconButton>
            <Modal
                open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ width: 400, borderRadius: 2, bgcolor: 'background.paper', p: 3 }}>
                    <h1 style={{ marginBottom: 30 }}>Edit Resource</h1>
                    <form>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="lead-label">Manager</InputLabel>
                            <Select labelId="lead-label" label="Manager" value={userId} onChange={handleUserIdChange}>
                                <MenuItem value=""></MenuItem>
                                {
                                    allUser.map((user: any) => (
                                        <MenuItem key={user.user_id} value={user.user_id} >{user.user_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="lead-label">Employee</InputLabel>
                            <Select labelId="lead-label" label="Employee" value={employeeId} onChange={handleEmployeeIdChange}>
                                <MenuItem value=""></MenuItem>
                                {
                                    allEmployees.map((emp: any) => (
                                        <MenuItem key={emp.user_id} value={emp.user_id} >{emp.user_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Assigned Date"
                            type="date"
                            value={assingedDate}
                            onChange={(e) => setAssingedDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Exit Date"
                            type="date"
                            value={exitDate}
                            onChange={(e) => setExitDate(e.target.value)}
                            InputLabelProps={{
                                shrink: true
                            }}
                        />
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Remarks"
                            type="text"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleEdit}>Edit</Button>
                            <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default EditResource;
