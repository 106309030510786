import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem, Stack, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

import axios from "../../utils/axios";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";

const InsertExpenseType: React.FC<any> = ({ updateRefresh }) => {
    const [open, setOpen] = useState(false);

    const [type, setType] = useState("");
    const [category, setCategory] = useState("");
    const [freq, setFreq] = useState("");
    const [billable, setBillable] = useState(true);
    const [nonBillable, setNonBillable] = useState(true);
    const [team, setTeam] = useState(true);
    const [client, setClient] = useState(true);
    const [desc, setDesc] = useState("");

    const resetState = () => {
        setType("");
        setCategory("");
        setFreq("");
        setBillable(true);
        setNonBillable(true);
        setTeam(true);
        setClient(true);
        setDesc("");
    }

    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);



    const handleClose = () => setOpen(false);

    const handleInsert = async () => {
        try {
            const response: any = await axios.post(`/create-expense-types`, {
                "expense_type": type,
                "expense_category": category,
                "frequency": freq,
                "billable": billable ? "Yes" : "No",
                "non_billable": nonBillable ? "Yes" : "No",
                "team_lead": team ? "Yes" : "No",
                "client": client ? "Yes" : "No",
                "description": desc
            })

            console.log(response);

            toast.success(`Successfully Inserted!`, {
                position: 'top-right',
                autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(false);
            updateRefresh(true);
            resetState();
        } catch (err) {
            console.log(err)
            toast.error(`Please check all the entries carefully!`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <Button variant="contained" style={{ backgroundColor: "white", color: "#2196F3" }} onClick={handleClickOpen('paper')}>
                Add New Expense Type<FaPlus style={{ marginLeft: "10px" }} />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <h2>Add New Expense Type</h2>
                </DialogTitle>
                <DialogContent >
                    <Stack
                        direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box sx={{ width: isMdUp ? 600 : '100%', minWidth: isMdUp ? 600 : '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, bgcolor: 'background.paper', p: 3, marginX: isMdUp ? 2 : 0 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Expense Type"
                                type="text"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Expense Category</InputLabel>
                                <Select onChange={(e) => setCategory(e.target.value)} value={category} label="Expense Category" >
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                    <MenuItem value="One Time">One Time</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                    <MenuItem value="Variable">Variable</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Frequency</InputLabel>
                                <Select onChange={(e) => setFreq(e.target.value)} value={freq} label="Frequency" >
                                    <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="On Demand">On Demand</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={billable} onChange={(e) => { setBillable(!billable) }} />}
                                    label="Billable"
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={nonBillable} onChange={(e) => { setNonBillable(!nonBillable) }} />}
                                    label="Non Billable"
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={team} onChange={(e) => { setTeam(!team) }} />}
                                    label="Team Lead"
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={client} onChange={(e) => { setClient(!client) }} />}
                                    label="Client"
                                />
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Description"
                                type="text"
                                value={desc}
                                multiline
                                rows={4}
                                onChange={(e) => setDesc(e.target.value)}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Add</Button>
                    <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default InsertExpenseType;
