import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { IoMdArrowBack } from "react-icons/io";
import Billing from './DataTables/Billing';
import Collection from './DataTables/Collection';
import Clients from './DataTables/Clients';
import Vendors from './DataTables/Vendors';
import Payments from './DataTables/Payments';
import ExpenseTypes from './DataTables/ExpenseTypes';
import { useDispatch } from 'react-redux';
import { setBranchVisible } from '../Actions/branchActions';
import MasterFinanceReports from './Reports/FinanceReports/MasterFinanceReport';
import {Expenses} from './DataTables/expenses/_tab';
import { AccessDenied } from '../global-component';

const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

export default function FinanceDashboard() {

  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');
  const dispatch = useDispatch();
  // const branch = useSelector((state: any) => state.branch);

  React.useEffect(() => {
    dispatch(setBranchVisible(true))
  })

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (roleobj?.role === "Director" || roleobj?.role === "Finance" || roleobj?.role === "Admin") {
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Button onClick={() => { navigate("/") }}><IoMdArrowBack color='#000' size={25} /></Button>
        <TabContext value={value}>
          <Box px={'30px'} >
            <TabList 
            variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Billing/Invoicing" value="1" />
              <Tab label="Collection" value="2" />
              <Tab label="Expenses" value="3" />
              <Tab label="Payment" value="4" />
              <Tab label="Vendors" value="5" />
              <Tab label="Clients" value="6" />
              <Tab label="Expense Types" value="7" />
              <Tab label="Reports" value="8" />
            </TabList>
          </Box>
          <TabPanel value="1"><Billing /></TabPanel>
          <TabPanel value="2"><Collection /></TabPanel>
          <TabPanel value="3"><Expenses /></TabPanel>
          <TabPanel value="4"><Payments /></TabPanel>
          <TabPanel value="5"><Vendors /></TabPanel>
          <TabPanel value="6"><Clients /></TabPanel>
          <TabPanel value="7"><ExpenseTypes /></TabPanel>
          <TabPanel value="8"><MasterFinanceReports /></TabPanel>
        </TabContext>
      </Box>
    );
  }

  else {
    return (
      <AccessDenied />
    )
  }

}
