import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Button,
  DialogProps,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import axios from "../../../utils/axios";
import { jwtDecode } from "jwt-decode";
import InsertPurchase from "../../InsertModal/InsertPurchase";
import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../../Actions/branchActions";
import { FaFile, FaPlus } from "react-icons/fa";
import * as XLSX from "xlsx";
import PurchaseMaster from "../../MasterForms/PurchaseMaster";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import { ExpensesService } from "../../../core/service";

const expensesService: ExpensesService = new ExpensesService();

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface Column {
  id:
    | "expense_type"
    | "frequency"
    | "expense_category"
    | "billable"
    | "amount"
    | "team"
    | "from"
    | "to"
    | "client"
    | "invoice_no"
    | "invoice_date"
    | "invoice_due_date"
    | "remarks";
  label: string;
  minWidth?: number;
  maxWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "expense_type", label: "Expense Type", minWidth: 150 },
  { id: "frequency", label: "Frequency", minWidth: 60 },
  { id: "expense_category", label: "Category", minWidth: 60 },
  { id: "billable", label: "Billable", minWidth: 60 },
  { id: "amount", label: "Amount", minWidth: 100 },
  { id: "team", label: "Team Associated", minWidth: 200 },
  { id: "from", label: "From", minWidth: 250 },
  { id: "to", label: "To", minWidth: 250 },
  { id: "client", label: "Client", minWidth: 250 },
  { id: "invoice_no", label: "Invoice number", minWidth: 150 },
  { id: "invoice_date", label: "Invoice date", minWidth: 150 },
  { id: "invoice_due_date", label: "Invoice due date", minWidth: 150 },
  { id: "remarks", label: "Remarks", minWidth: 300, maxWidth: 300 },
];

const user_id: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

const Billable = () => {
  const dispatch = useDispatch();
  const branch = useSelector((state: any) => state.branch);
  const [open, setOpen] = useState(false);
  // const [infoOpen, setInfoOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setrows] = useState<any>([]);
  const [excelData, setExcelData] = useState<any>([]);
  const [doRefresh, setDoRefresh] = useState(false);

  const [selectedRow, setSelectedRow] = useState<any>(null);

  // useEffect(() => {
  //   console.log(selectedRow)
  // }, [selectedRow])

  const updateRefresh = (refreshState: boolean) => {
    setDoRefresh(refreshState);
  };

  const exportToExcel = () => {
    // Convert tableData to Excel format
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(data);

    // Trigger file download
    const link = document.createElement("a");
    link.href = url;
    link.download = "purchases.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredRows = searchTerm
    ? rows.filter((row: any) =>
        Object.values(row).some(
          (value: any) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : rows;

  useEffect(() => {
    const getallpurchase = async () => {
      dispatch(loading(true));
      try {
        const response: any = await expensesService.getExpenses(
          branch.branch,
          "All",
          'operational'
        );
        if (response.status > 0) {
          setrows(response.data.rows);
          setExcelData(response.data.rows);
        } else {
          setrows([]);
        }
      } catch (err) {
        console.error(err);
        toast.error(`Service Unavailable!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      dispatch(loading(false));
    };

    getallpurchase();
    updateRefresh(false);
  }, [branch.branch, doRefresh]);

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
  };

  // const handleInfoOpen = () => {
  //   setInfoOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInfoClose = () => {
    // setInfoOpen(false);
    setSelectedRow(null);
  };

  return (
    <React.Fragment>
      <Stack
        paddingX={"2%"}
        marginTop={"2px"}
        width="100%"
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          padding="10px"
          bgcolor="primary.main"
          color="primary.contrastText"
          direction="row"
          justifyContent={"space-between"}
          width={"100%"}
          marginY={"5px"}
          borderRadius={"10px"}
          className="custom-stack-layout"
        >
          <Stack direction={"row"} spacing={4}>
            {(roleobj?.role === "Finance" ||
              roleobj?.role === "Director" ||
              roleobj?.role === "Admin") && (
              <Button
                variant="contained"
                style={{ backgroundColor: "white", color: "#2196F3" }}
                onClick={handleClickOpen("paper")}
              >
                Create new Expenses
                <FaPlus style={{ marginLeft: "10px" }} />
              </Button>
            )}
            {open && (
              <InsertPurchase
                onClose={handleClose}
                updateRefresh={updateRefresh}
                expenseCat={{ disable: false, defaultValue: "Others" }}
                billable={{ disable: true, defaultValue: 'operational' }}
                expenseMandate={{ disable: false, defaultValue: "" }}
              />
            )}
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "#2196F3",
                marginLeft: 10,
              }}
              onClick={exportToExcel}
            >
              Export to Excel
              <FaFile style={{ marginLeft: "10px" }} />
            </Button>
            <Search style={{ marginLeft: 10 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                style={{ width: "40vw" }}
              />
            </Search>
          </Stack>
          <Typography fontSize={"25px"}>Expenses</Typography>
        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
            <Table
              className="table-custom-style"
              stickyHeader
              aria-label="sticky table"
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bolder",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={index}
                        // style={{
                        //   backgroundColor:
                        //     row.status === "Paid" ? "#86efac" : "white",
                        // }}
                        className={`${row.purchases_status === 'Yes' && 'success'}`}
                      >
                        <TableCell>
                          <IconButton
                            onClick={(e) => {
                              setSelectedRow(row);
                              console.log("Row", row);
                              console.log("SelectedRow", selectedRow);
                            }}
                            color="primary"
                            style={{ height: "8px" }}
                          >
                            <InfoIcon />
                          </IconButton>
                        </TableCell>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "300px",
                                }}
                              >
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 25, 30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          {selectedRow && (
            <PurchaseMaster
              onClose={handleInfoClose}
              updateRefresh={updateRefresh}
              id={selectedRow.id}
              client_id={selectedRow.client_name_id}
              vendor_id={selectedRow.to_id}
              team_id={selectedRow.team_name_id}
              Amount={selectedRow.amount}
              inv_no={selectedRow.invoice_no}
              inv_date={selectedRow.invoice_date}
              inv_due_date={selectedRow.invoice_due_date}
              Remarks={selectedRow.remarks}
              exp_type={selectedRow.expense_type_id}
              exp_cat={selectedRow.expense_category}
              Billable={selectedRow.billable}
              Tds={selectedRow.tds}
              expense_mandate={selectedRow.expense_mandate}
              expenseCat={{ disable: false, defaultValue: selectedRow.expense_category }}
              billable={{ disable: true, defaultValue: selectedRow.billable }}
              bill_type={{ disable: false, defaultValue: selectedRow.bill_type }}
              expenseMandate={{ disable: false, defaultValue: "" }}
              sgst={selectedRow.sgst}
              cgst={selectedRow.cgst}
              igst={selectedRow.igst}
              savedTax={selectedRow.tax}
            />
          )}
        </Paper>
      </Stack>
    </React.Fragment>
  );
};

export default Billable;
