import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import InsertSalaries from "../InsertModal/InsertSalaries";
import EditSalaries from "../EditdataModal/EditSalaries";
import axios from "../../utils/axios";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface Column {
  id:
    | "employee_name"
    | "employee_email"
    | "employee_designation"
    | "employee_salary"
    | "doj"
    | "lwd"
    | "status"
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "employee_name", label: "Name", minWidth: 100 },
  { id: "employee_email", label: "Email", minWidth: 100 },
  { id: "employee_designation", label: "Designation", minWidth: 100 },
  { id: "employee_salary", label: "Salary", minWidth: 100 },
  { id: "doj", label: "DOJ", minWidth: 100 },
  { id: "lwd", label: "LWD", minWidth: 100 },
  { id: "status", label: "Status", minWidth: 100 },
];

const Salaries = () => {
  const branch = useSelector((state: any) => state.branch);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setRows] = useState<any>([]);
  const [doRefresh, setDoRefresh] = useState(false);

  const updateRefresh = (refreshState: boolean) => {
    setDoRefresh(refreshState);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredRows = searchTerm
    ? rows.filter((row: any) =>
        Object.values(row).some(
          (value: any) =>
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(searchTerm.toLowerCase())
        )
      )
    : rows;

  useEffect(() => {
    const getallOverHeads = async () => {
      const response: any = await axios.post(`/get-salaries`, { branch: branch.branch});
      console.table(response.data.salaries);
      if (response.data.fetched) {
        setRows(response.data.salaries.reverse());
      } else {
        setRows([]);
      }
    };

    getallOverHeads();
    updateRefresh(false);
  }, [branch.branch, doRefresh]);

  const userRole: any = localStorage.getItem("access_token");
  let roleobj: any = null;
  if (userRole) {
    roleobj = jwtDecode(userRole);
  }

  // Function to format date as per requirement
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0]; // Trimming time part and returning only date
  };

  return (
    <Stack
      paddingX={"2%"}
      marginTop={"5px"}
      width="100%"
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Stack
        padding="10px"
        bgcolor="primary.main"
        color="primary.contrastText"
        direction="row"
        justifyContent={"space-between"}
        width={"100%"}
        marginY={"20px"}
        borderRadius={"10px"}
      >
        <Stack direction={"row"} spacing={4}>
          {(roleobj?.role !== "Finance" || roleobj?.role === "Admin") && <InsertSalaries updateRefresh={updateRefresh} />}
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              onChange={handleSearchChange}
              style={{ width: "40vw" }}
            />
          </Search>
        </Stack>
        <Typography fontSize={"25px"}>Salaries</Typography>
      </Stack>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, fontWeight: "bolder" }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell
                  key="edit-delete-cell"
                  align="center"
                  style={{ minWidth: 100, fontWeight: "bolder" }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      {columns.map((column) => {
                        const value = column.id === "doj" || column.id === "lwd" ? formatDate(row[column.id]) : row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                      <TableCell key="edit-delete-cell" align="center">
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"center"}
                        >
                          <EditSalaries
                            employee_name={row.employee_name}
                            employee_email={row.employee_email}
                            employee_designation={row.employee_designation}
                            employee_salary={row.employee_salary}
                            lwd={row.lwd}
                            doj={row.doj}
                            status={row.status}
                            id={row.employee_id}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[20, 25, 30]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Stack>
  );
};

export default Salaries;
