export const SET_BRANCH = 'SET_BRANCH';
export const BRANCH_VISIBLE = 'BRANCH_VISIBLE';
export const LOADING = 'LOADING';

export const setBranch = (branch: number) => ({
  type: SET_BRANCH,
  payload: branch
});

export const setBranchVisible = (isVisible: boolean) => ({
    type: BRANCH_VISIBLE,
    payload: isVisible
})

export const loading = (loading: boolean) => ({
  type: LOADING,
  payload: loading
})