import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import * as Util from '../utility';

export class CommonService {
  private https: HttpService;
  private methods: Util.Methods;

  constructor() {
    this.https = new HttpService();
    this.methods = new Util.Methods();
  }


  /**
 * Retrieves a list of users based on the provided filters.
 * 
 * @param payload An object containing optional filters for the users:
 *                - role: A string representing the role of the users.
 *                - status: A string with values "Active" or "Inactive" to filter users by status.
 *                - branchId: A number representing the branch ID to filter users by branch.
 * @returns A Promise that resolves with the response data of the user retrieval request.
 * @throws Error if there is an issue with the user retrieval request.
 */
  async getUsers(payload: {
    role?: string[];
    status?: "Active" | "Inactive";
    branchId?: number;
  }) {
    const baseURL = BASE_URL + `/get/users`;

    try {
      let response = await this.https.postRequest(baseURL, payload, null);
      return response;
    } catch (err) {
      throw new Error(`getUsers Service Error :: ${err}`);
    }
  }

  async getTeamMembers(payload: {
    manager: number
  }) {
    const baseURL = BASE_URL + `/leads/get-all-ops-teams`;

    try {
      let response = await this.https.postRequest(baseURL, payload, null);
      return response;
    } catch (err) {
      throw new Error(`getTeamMembers Service Error :: ${err}`);
    }
  }

  async getOwnVP(userId?: string | number) {
    const baseURL = BASE_URL + `/lead/get/vp?userId=${userId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async getTeamLeader(data: {userId: number, role?: string[]}) {
    const baseURL = BASE_URL + `/get/team/leader`;
    try {
      let response = await this.https.postRequest(baseURL, data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async getLeaderTeam(leaderId: string | number) {
    const baseURL = BASE_URL + `/get/team/${leaderId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }


  async getBDTeam(userId: string | number) {
    const baseURL = BASE_URL + `/get/team/business/${userId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  };

  async getBranchGst(branchId: string | number) {
    const baseURL = BASE_URL + `/getGst/${branchId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }


}
