import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import { TextField, FormControl, InputLabel, Select, MenuItem, Stack, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

import axios from "../../utils/axios";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const InsertPayment: React.FC<any> = ({ updateRefresh, onClose }) => {
    const [open, setOpen] = useState(false);

    const branch = useSelector((state: any) => state.branch);
    const [amount, setAmount] = useState("");
    const [transId, setTransId] = useState("");
    const [company, setCompany] = useState([]);
    const [vendor, setVendor] = useState([]);
    const [to, setTo] = useState("");
    const [from, setFrom] = useState<number>();
    const [type, setType] = useState("");
    const [payDate, setPayDate] = useState("");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);



    const handleToChange = (e: any) => {
        setTo(e.target.value);
    }

    const handleClose = () => setOpen(false);

    useEffect(() => {

        const getCompanies = async () => {
            const response: any = await axios.post(`/get-companies`, {})
            console.log(response.data);
            setCompany(response.data.companies);
        }

        const getVendors = async () => {
            const response: any = await axios.post(`/get-vendor-names`, {})
            console.log(response.data);
            setVendor(response.data.vendors);
        }

        getCompanies();
        getVendors();

    }, [])

    const handleInsert = async () => {
        setIsLoading(true);
        try {
            const response: any = await axios.post(`/create-payment`, {
                "from_id": branch.branch,
                "vendor_id": to,
                "amount": amount,
                "payment_type": type,
                "payment_date": payDate,
                "transaction_id": transId
            })

            toast.success(`Successfully Inserted!`, {
                position: 'top-right',
                autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            onClose();
            updateRefresh(true);
        } catch (err) {
            console.log(err)
            toast.error(`Please check all the entries carefully!`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        setIsLoading(false);
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            {/* <Button variant="contained" style={{ backgroundColor: "white", color: "#2196F3" }} onClick={handleClickOpen('paper')}>
                Add New Payment<FaPlus style={{ marginLeft: "10px" }} />
            </Button> */}
            <Dialog
                open
                onClose={onClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"100%"}>
                        <div>
                            <h2>Add New Payment</h2>
                        </div>
                        <div>
                            <h2>{localStorage.getItem('cur_branch')}</h2>
                        </div>
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <Stack
                        direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box sx={{ width: isMdUp ? 600 : '100%', minWidth: isMdUp ? 600 : '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, bgcolor: 'background.paper', p: 3, marginX: isMdUp ? 2 : 0 }}>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">To ( Vendor )</InputLabel>
                                <Select labelId="lead-label" label="To ( Vendor )" value={to} onChange={handleToChange}>
                                    <MenuItem key={0} value={0}></MenuItem>
                                    {
                                        vendor.map((vendor: any, index) => (
                                            <MenuItem key={vendor.to_id} value={vendor.to_id} >{vendor.to}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Amount"
                                type="number"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Transaction ID"
                                type="string"
                                value={transId}
                                onChange={(e) => setTransId(e.target.value)}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Type/ Mode</InputLabel>
                                <Select onChange={(e) => setType(e.target.value)} value={type} label="Type/ Mode" >
                                    <MenuItem value="Account">Account</MenuItem>
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Cheque">Cheque</MenuItem>
                                    <MenuItem value="NEFT">NEFT</MenuItem>
                                    <MenuItem value="Online">Online</MenuItem>
                                    <MenuItem value="RTGS">RTGS</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Paid On"
                                type="date"
                                value={payDate}
                                onChange={(e) => { setPayDate(e.target.value) }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={isLoading} variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Add</LoadingButton>
                    <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={onClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default InsertPayment;
