import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { FormControl, InputLabel, MenuItem, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";


interface EditAccountProps {
    id: number;
    updateRefresh: any,
    onClose: any;
    client_id: number;
    Amount: number;
    payment_type: string;
    payment_date: string;
    Tax: string;
    Narration: string;
    trans_id: string;
    chk_no: string;
    bank_name: string;
}

const CollectionMaster: React.FC<EditAccountProps> = ({
    id, payment_date, Amount, client_id, payment_type, Tax, Narration, trans_id, chk_no, bank_name, updateRefresh, onClose
}) => {
    const branch = useSelector((state: any) => state.branch);
    const [isEditing, setIsEditing] = useState(false);

    const [clientId, setClientId] = useState(client_id);
    const [amount, setAmount] = useState(Amount);
    const [type, setType] = useState(payment_type);
    const [payDate, setPayDate] = useState<string>(payment_date);
    const [tax, setTax] = useState(Tax);
    const [narration, setNarration] = useState(Narration);
    const [transId, setTransId] = useState(trans_id);
    const [isTrans, setIsTrans] = useState(false);
    const [chequeNo, setChequeNo] = useState(chk_no);
    const [isBank, setIsBank] = useState(false);
    const [bankName, setBankName] = useState(bank_name);
    const [isCheque, setIsCheque] = useState(false);

    const [allClients, setClients] = useState([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetState = () => {
        setClientId(client_id);
        setAmount(Amount);
        setType(payment_type);
        setPayDate(payment_date);
    }

    // const handleClientID = (e: any) => {
    //     const newSelectedClientId = e.target.value;
    //     const parsedOpstId = parseInt(newSelectedClientId, 10);
    //     setClientId(parsedOpstId);
    // };

    const handleType = (e: any) => {
        let type = e.target.value;

        setType(type);
    }

    const handleClientID = (e: any) => {
        setClientId(e.target.value);
    };

    const handleEdit = async () => {
        setIsLoading(true);
        try {
            const response = await axios.post(`update-collection`, {
                "id": id,
                "payment_date": payDate,
                "to_id": branch.branch,
                "client_id": clientId,
                "amount": amount,
                "tax": tax,
                "bank_name": bankName,
                "transaction_id": transId,
                "cheque_no": chequeNo,
                "payment_type": type,
                "narration": narration
            })

            if (response.data.updated) {
                toast.success(`Successfully Updated!`, {
                    position: 'top-right',
                    autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsEditing(false);
                onClose();
                updateRefresh(true);
            } else {
                toast.error(`Please check all the entries carefully`, {
                    position: 'top-right',
                    autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            console.log("collection update ::: ", err);

            toast.error(`Service unavailable`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setIsLoading(false);
    }

    const handlePayDate = (date: any) => {
        setPayDate(date.target.value);
    };

    useEffect(() => {
        const getAllClients = async () => {
            const response: any = await axios.post(`/get-all-clients-id`, { branch: branch.branch });
            setClients(response.data.data.rows);
        };

        getAllClients();
    }, [branch.branch]);

    useEffect(() => {
        type === "Online" ? setIsTrans(true) : setIsTrans(false);
        type === "Cash" ? setIsBank(true) : setIsBank(false);
        type === "Cheque" ? setIsCheque(true) : setIsCheque(false);
    }, [type])

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <Dialog
                open
                onClose={onClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={isMdUp ? 'row' : 'column'} alignItems={'center'} justifyContent={'space-between'}>
                        <h2>{isEditing ? "Edit Collection" : "Collection Details"}</h2>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            {!isEditing ? <IconButton color="primary" style={{ height: '8px' }}>
                                <EditIcon onClick={() => { setIsEditing(true) }} />
                            </IconButton> : <></>}
                            <h2 style={{ marginLeft: 10 }}>{localStorage.getItem('cur_branch')}</h2>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <form>
                        <Stack
                            direction={isMdUp ? 'row' : 'column'}
                            sx={{
                                width: isMdUp ? 1200 : '100%',
                                borderRadius: 2,
                                bgcolor: "background.paper",
                                p: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    width: isMdUp ? 400 : '100%',
                                    borderTopLeftRadius: 10,
                                    borderBottomLeftRadius: 10,
                                    bgcolor: "background.paper",
                                    p: 3,
                                    marginX: isMdUp ? 2 : 0
                                }}
                            >
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Payment Date"
                                    type="date"
                                    value={payDate}
                                    onChange={(e) => { setPayDate(e.target.value) }}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="lead-label">Client</InputLabel>
                                    <Select
                                        labelId="lead-label"
                                        label="Client Name"
                                        value={clientId}
                                        onChange={handleClientID}
                                        fullWidth
                                        readOnly={
                                            !isEditing ? true : false
                                        }
                                        IconComponent={isEditing ? undefined : () => null}
                                    >
                                        <MenuItem value=""></MenuItem>
                                        {allClients.map((client: any) => (
                                            <MenuItem key={client.id} value={client.id}>
                                                {client.brand_and_branch}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Principal amount"
                                    type="number"
                                    value={amount}
                                    onChange={(e) => setAmount(Number(e.target.value))}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="TDS amount"
                                    type="number"
                                    value={tax}
                                    onChange={(e) => setTax(e.target.value)}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Gross amount"
                                    type="number"
                                    value={Number(amount) + Number(tax)}
                                    InputProps={{
                                        readOnly: true
                                    }}
                                />
                                <FormControl fullWidth sx={{ mt: 2 }}>
                                    <InputLabel id="lead-label">Type/ Mode</InputLabel>
                                    <Select onChange={handleType} value={type} label="Type/ Mode"
                                        readOnly={
                                            !isEditing ? true : false
                                        }
                                        IconComponent={isEditing ? undefined : () => null}>
                                        <MenuItem value="Cash">Cash</MenuItem>
                                        <MenuItem value="Cheque">Cheque</MenuItem>
                                        <MenuItem value="Online">Online</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Transaction Id"
                                    type="string"
                                    value={transId}
                                    onChange={(e) => { setTransId(e.target.value) }}
                                    style={{ display: isTrans ? 'block' : 'none' }}
                                />
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Bank name"
                                    type="string"
                                    value={bankName}
                                    onChange={(e) => { setBankName(e.target.value) }}
                                    style={{ display: isBank ? 'block' : 'none' }}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Cheque number"
                                    type="string"
                                    value={chequeNo}
                                    onChange={(e) => { setChequeNo(e.target.value) }}
                                    style={{ display: isCheque ? 'block' : 'none' }}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                                <TextField
                                    sx={{ mt: 2 }}
                                    fullWidth
                                    label="Narration"
                                    type="string"
                                    value={narration}
                                    onChange={(e) => { setNarration(e.target.value) }}
                                    multiline
                                    rows={4}
                                    InputProps={{
                                        readOnly: !isEditing ? true : false
                                    }}
                                />
                            </Box>
                            <Box
                                sx={{
                                    width: 400,
                                    borderTopRightRadius: 10,
                                    borderBottomRightRadius: 10,
                                    bgcolor: "background.paper",
                                    p: 3,
                                    marginX: isMdUp ? 2 : 0
                                }}
                            >
                            </Box>
                        </Stack>
                    </form>
                </DialogContent>
                {isEditing ? <DialogActions>
                    <LoadingButton
                        variant="contained"
                        style={{ borderRadius: 5 }}
                        onClick={handleEdit}
                        loading={isLoading}
                    >
                        Edit
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: 10, borderRadius: 5 }}
                        onClick={onClose}
                    >
                        Cancel Edit
                    </Button>
                </DialogActions> :
                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{ marginLeft: 10, borderRadius: 5 }}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    );
};

export default CollectionMaster;
