import React, { Fragment, useEffect, useState, SyntheticEvent } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../Actions/branchActions";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import { ClientService } from "../../core/service";
import { DesktopDatePicker } from "../../global-component/date-picker";
import {
  Button,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
// import { ClientHealth } from "../../enums/client";
import {_ClientHealth} from '../../enums/client';
import * as Util from "../../core/utility";
import { Col, Container, Row } from "react-bootstrap";

const clientService: ClientService = new ClientService();
const methods: Util.Methods = new Util.Methods();

const user_role: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

type TableDataSource = {
  client_id: number;
  client_name: string;
  start_date: string;
  end_date: string;
  _dates: string[];
  status: string[];
  active_client: string[];
  comments: string[];
};

const _Dates = new Date();
const intialDateRange = {
  start_date: [_Dates.getFullYear(), "01", "01"].join("-"),
  end_date: [
    _Dates.getFullYear(),
    (_Dates.getMonth() + 1).toString().padStart(2, "0"),
    _Dates.getDay().toString().padStart(2, "0"),
  ].join("-"),
};

const ClientHealth: React.FC = () => {
  const dispatch = useDispatch();
  const branch = useSelector((state: any) => state.branch);
  const [dataSource, setDataSource] = useState<TableDataSource[]>([]);
  const [sortedData, setSortedData] = useState<TableDataSource[]>([]);
  const [tableHeader, setTableHeader] = useState<string[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dateRange, setDateRange] = useState(intialDateRange);

  useEffect(() => {
    getClientReview();
  }, []);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    console.log(+event.target.value)
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function getClientReview(event?: any) {
    event?.preventDefault();
    try {
      dispatch(loading(true));
      clientService
        .ClientHealth(dateRange.start_date, dateRange.end_date, branch?.branch)
        .then((res) => {
          if (res.status > 0 && res.data.fetchedRows > 0) {
            const tranformDatas = transformData(res.data.rows);
            setDataSource(tranformDatas.dataSource);
            setTableHeader(tranformDatas.header);
          } else {
            console.error(res);
          }
        })
        .catch((err) =>
          console.error(`getClient Review api failed Data Error :: ${err}`)
        )
        .finally(() => {
          dispatch(loading(false));
        });
    } catch (error) {
      throw new Error(`getClientReview function failed Error :: ${error}`);
    }
  }

  function transformData(data: any[]): {
    dataSource: TableDataSource[];
    header: string[];
  } {
    let result: any[] = [];
    let header: string[] = [];
    try {
      const _Dates = Array.from(new Set(data.map((val) => val.month)));
      const clients = Array.from(
        new Set(data.map((val) => val.client_id))
      ).filter(Boolean);
      header = [
        "Legal Name",
        ..._Dates.map((val) => dayjs(new Date(`${val}-01`)).format("MMM-YYYY")),
      ];
      const createObj = (client_id: number) => {
        result.push({
          client_id: client_id,
          client_name: "",
          start_date: "",
          end_date: "",
          _dates: _Dates,
          status: Array(_Dates.length).fill(null),
          active_client: Array(_Dates.length).fill("No"),
          comments: Array(_Dates.length).fill(""),
        });
      };

      clients.forEach((val) => {
        createObj(val);
      });

      data.forEach((val) => {
        if (!val.client_id) {
          return;
        }

        const clientIdIndex = clients.indexOf(val.client_id);
        const dateIndex = _Dates.indexOf(val.month);
        if (clientIdIndex !== -1 && dateIndex !== -1) {
          result[clientIdIndex].client_name = val.legal_name;
          result[clientIdIndex].start_date = val.start_date;
          result[clientIdIndex].end_date = val.end_date;
          result[clientIdIndex].status[dateIndex] = val.status;
          result[clientIdIndex].active_client[dateIndex] = val.active_client;
          result[clientIdIndex].comments[dateIndex] = val.comments;
        }
      });
    } catch (err) {
      throw new Error(`tranform Client Review Failed :: ${err}`);
    }

    return { dataSource: result, header: header };
  }

  function updateStatus(
    obj: {
      client_id: string;
      update_date: string;
      updater_id: string;
      status: string;
    },
    pIndex: number,
    cIndex: number
  ) {
    if (obj.client_id) {
      dispatch(loading(true));
      obj.update_date += "-01";
      clientService
        .UpdateClientHealth(obj)
        .then((res) => {
          if (res.status > 0 && res.data.affectedRows > 0) {
            getClientReview();
          }
        })
        .catch((err) =>
          console.log(`update client review status failed :: ${err}`)
        )
        .finally(() => {
          dispatch(loading(false));
        });
    }
  }

  useEffect(() => {
    if (dataSource.length > 0) {
      setSortedData(
        dataSource.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      );
    }
  }, [page, rowsPerPage, dataSource]);

  return (
    <Fragment>
      <Container fluid>
        <Row>
          <form onSubmit={getClientReview}>
            <div className="client-filter">
              <DesktopDatePicker
                views={["month", "year"]}
                name="start_date"
                format="MMM-YYYY"
                maxDate={new Date().toString()}
                defaultValue={dateRange.start_date}
                onChange={(v) =>
                  setDateRange((prev) => ({ ...prev, start_date: v }))
                }
                label={"To"}
                size="small"
              />
              <DesktopDatePicker
                views={["month", "year"]}
                defaultValue={dateRange.end_date}
                maxDate={new Date().toString()}
                minDate={dateRange.start_date}
                name="end_date"
                format="MMM-YYYY"
                onChange={(v) =>
                  setDateRange((prev) => ({ ...prev, end_date: v }))
                }
                label={"From"}
                size="small"
              />
              <Button type="submit" variant="contained">
                Search
              </Button>
            </div>
          </form>
        </Row>
        <Row>
          <Col>
            <TableContainer>
              <Table
                id="client_revoew_table_id"
                className="table-custom-style"
                stickyHeader
                aria-label="sticky table"
                size="small"
              >
                <TableHead>
                  <TableRow>
                    {tableHeader.map((data: string, index: number) => (
                      <TableCell key={`client_review_header_${index}`}>
                        {data}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedData.map((data: TableDataSource, pindex: number) => (
                    <TableRow
                      style={{ height: "60px" }}
                      key={`client_review_body_row_${pindex}_${methods.getRandNumber()}_${JSON.stringify(data)}`}
                    >
                      <TableCell>{data.client_name}</TableCell>
                      {data.status.map((val: string, cindex: number) => (
                        <TableCell>
                          {data.active_client[cindex] === "Yes" ? (
                            <CellEditComponent
                              key={`client_review_child_cell_${cindex}`}
                              parentData={data}
                              pindex={pindex}
                              cindex={cindex}
                              selectOptions={_ClientHealth}
                              onChange={(e) => updateStatus(e, pindex, cindex)}
                            />
                          ) : (
                            <span
                              key={`client_review_child_cell_${cindex}`}
                              className="close-icon-span"
                            >
                              {" "}
                              <CloseIcon className="custom-close-icon" />{" "}
                            </span>
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20, 25, 30]}
              component="div"
              count={dataSource.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

type FormType = {
  client_id: string;
  update_date: string;
  updater_id: string;
  comments: string;
  status: string;
};

interface CellEditProps {
  parentData: TableDataSource;
  pindex: number;
  cindex: number;
  selectOptions: { label: string; value: string | number }[];
  onChange: (form: FormType) => void;
}

const CellEditComponent: React.FC<CellEditProps> = ({
  parentData,
  pindex,
  cindex,
  onChange,
  selectOptions,
}: CellEditProps) => {
  const [random] = useState(methods.getRandNumber());
  const [toggle, setToggle] = useState(false);

  function onSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setToggle(!toggle);
    onChange(
      Object.fromEntries(
        new FormData(event.target as HTMLFormElement)
      ) as FormType
    );
  }

  return (
    <div className={` edit-component `} id={`cell-edit-component_${random}_id`}>
      <span className="temp-value" onClick={() => setToggle(!toggle)}>
        {parentData.active_client[cindex] === "Yes" && (
          <span
            className={`edit-status element-complete ${
              parentData.status[cindex] ? "element-complete" : "element-pending"
            }`}
          >
            {parentData.status[cindex] ? parentData.status[cindex] : "Pending"}
          </span>
        )}
      </span>
      <div
        className={`edit-dialog ${
          toggle ? "active-toggle" : "inactive-toggle"
        }`}
      >
        <div className={`child-edit-component`}>
          <div className="dialog-title">
            <span>{parentData.client_name}</span>
            <button>
              <CloseIcon onClick={() => setToggle(!toggle)} />
            </button>
          </div>
          <form onSubmit={onSubmit}>
            <div className="form-edit">
              <input
                type="text"
                defaultValue={parentData?.client_id}
                name="client_id"
              />
              <input
                type="text"
                defaultValue={parentData?._dates[cindex]}
                name="update_date"
              />
              <input type="text" defaultValue={roleobj?.id} name="updater_id" />

              <FormControl fullWidth>
                <Select
                  required
                  disabled={Boolean(parentData.status[cindex])}
                  className="w-100"
                  labelId={`cell-edit-select-label-input_${random}_id`}
                  id={`cell-edit-select-label_${random}_id`}
                  size="small"
                  name="status"
                  defaultValue={
                    parentData.status[cindex] ? parentData.status[cindex] : ""
                  }
                >
                  {selectOptions.map((data, index) => (
                    <MenuItem
                      key={`edit-component-${random}-${index}_key`}
                      value={data.value || ""}
                    >
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                disabled={Boolean(parentData.status[cindex])}
                required
                id={`comments-${random}_id`}
                className="w-100"
                label="Comments"
                multiline
                rows={4}
                defaultValue={parentData.comments[cindex]}
                name="comments"
              />
              <LoadingButton
                disabled={Boolean(parentData.status[cindex])}
                loading={false}
                loadingPosition="start"
                variant="contained"
                startIcon={<SendIcon />}
                type="submit"
              >
                Save
              </LoadingButton>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ClientHealth;
