import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { FormControl, InputLabel, MenuItem, Select, Stack, TextField, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";


interface EditAccountProps {
    id: number;
    vendor: string;
    vendor_gst: string;
    company: number;
    company_gst: string;
    amount: number;
    payType: string;
    date: string;
    transaction_id: string;
    updateRefresh: any;
    onClose: any;
}

const PaymentMaster: React.FC<EditAccountProps> = ({
    id, vendor, vendor_gst, company, company_gst, amount, payType, date, transaction_id, updateRefresh, onClose
}) => {
    const branch = useSelector((state: any) => state.branch);
    const [isEditing, setIsEditing] = useState(false);
    const [Amount, setAmount] = useState(amount);
    const [Vendor, setVendor] = useState<string>(vendor);
    const [type, setType] = useState(payType);
    const [payDate, setPayDate] = useState(date);
    const [vendors, setVendors] = useState([]);
    const [transId, setTransId] = useState(transaction_id);

    // const resetState = () => {
    //     setAmount(amount);
    //     setTransId(transaction_id);
    //     setVendor(vendor);
    //     setType(payType);
    //     setPayDate(date);
    // }

    useEffect(() => {

        const getVendors = async () => {
            const response: any = await axios.post(`get-vendor-names`, {})
            console.log(response.data);
            setVendors(response.data.vendors);
        }

        getVendors();

    }, [])

    const handleEdit = async () => {
        try {
            const response = await axios.post(`update-payment`, {
                "id": id,
                "from_id": branch.branch,
                "vendor_id": Vendor,
                "amount": Amount,
                "payment_type": type,
                "payment_date": payDate
            })

            if (response.data.updated) {
                toast.success(`Successfully Updated!`, {
                    position: 'top-right',
                    autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                onClose();
                updateRefresh(true);
            } else {
                toast.warning(`Please check all the entries carefully`, {
                    position: 'top-right',
                    autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            console.log("update payment error :: ", err);

            toast.error(`Service unavailable`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <Dialog
                open
                onClose={onClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <h2>{isEditing ? "Edit Payment" : "Payment Details"}</h2>
                        {!isEditing ? <IconButton color="primary" style={{ height: '8px' }}>
                            <EditIcon onClick={() => { setIsEditing(true) }} />
                        </IconButton> : <></>}
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <Stack direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box
                            sx={{
                                width: isMdUp ? 600 : '100%',
                                minWidth: isMdUp ? 600 : '100%',
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                bgcolor: "background.paper",
                                p: 3,
                                marginX: isMdUp ? 2 : 0
                            }}
                        >
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Company GST"
                                type="string"
                                aria-readonly
                                value={company_gst}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">To ( Vendor )</InputLabel>
                                <Select labelId="lead-label" label="Team Member" value={Vendor} onChange={(e) => setVendor(e.target.value)}
                                    readOnly={
                                        !isEditing ? true : false
                                    }
                                    IconComponent={isEditing ? undefined : () => null}>
                                    <MenuItem value=""></MenuItem>
                                    {
                                        vendors.map((vendor: any, index) => (
                                            <MenuItem key={vendor.to_id} value={vendor.to_id} >{vendor.to}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Vendor GST"
                                type="string"
                                aria-readonly
                                value={vendor_gst}
                                InputProps={{
                                    readOnly: true
                                }}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Type/ Mode</InputLabel>
                                <Select onChange={(e) => setType(e.target.value)} value={type} label="Type/ Mode" readOnly={
                                    !isEditing ? true : false
                                }
                                    IconComponent={isEditing ? undefined : () => null} >
                                    <MenuItem value="Account">Account</MenuItem>
                                    <MenuItem value="Cash">Cash</MenuItem>
                                    <MenuItem value="Cheque">Cheque</MenuItem>
                                    <MenuItem value="NEFT">NEFT</MenuItem>
                                    <MenuItem value="Online">Online</MenuItem>
                                    <MenuItem value="RTGS">RTGS</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Amount"
                                type="number"
                                aria-readonly
                                value={Amount}
                                onChange={(e) => setAmount(Number(e.target.value))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Transaction ID"
                                type="string"
                                value={transId}
                                onChange={(e) => setTransId(e.target.value)}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Paid On"
                                type="date"
                                value={payDate}
                                onChange={(e) => setPayDate(e.target.value)}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                                InputLabelProps={{
                                    shrink: true
                                }}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                {isEditing ? <DialogActions>
                    <Button
                        variant="contained"
                        style={{ borderRadius: 5 }}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: 10, borderRadius: 5 }}
                        onClick={onClose}
                    >
                        Cancel Edit
                    </Button>
                </DialogActions> :
                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{ marginLeft: 10, borderRadius: 5 }}
                            onClick={onClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    );
};

export default PaymentMaster;
