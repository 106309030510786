import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import axios from "../../utils/axios";




const InsertSalaries: React.FC<any> = ({updateRefresh}) => {
  const [open, setOpen] = useState(false);
  const [salary, setSalary] = useState<number>();
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("Active");
  const [designation, setDesignation] = useState("");
  const [doj, setDoj] = useState<Date | null>(null);
  const [lwd, setLwd] = useState<Date | null>(null);

  const [allDesignation, setAllDesignation] = useState([]);


  const handleStatusChange = (e: any) => {
    const newStatusSelected = e.target.value; // This is the id of the selected bd_team
    console.log(newStatusSelected);
    setStatus(newStatusSelected);
  }

  const handleDesignationChange = (e: any) => {
    const newDesignationSelected = e.target.value; // This is the id of the selected bd_team
    console.log(newDesignationSelected);
    setDesignation(newDesignationSelected);
  }

  const handleDojDateChange = (date: Date | null) => {
    setDoj(date);
  };

  const handleLwdDateChange = (date: Date | null) => {
    setLwd(date);
  };

  //   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // take name,id from token

  const handleInsert = async () => {

    const response: any = await axios.post(`/create-employee`, {
      "employee_name": Name,
      "employee_email": email, "employee_designation": designation, "employee_salary": salary, "doj": doj?.toISOString().substring(0, 10), "lwd": lwd?.toISOString().substring(0, 10), "status": status,
    })

    console.log(response);

    toast.success(`Successfully Inserted!`, {
      position: 'top-right',
      autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setOpen(false);
    updateRefresh(true);
  }

  useEffect(() => {

    const getAllDesignations = async () => {
      const response: any = await axios.post(`/get-designations`, {})
      console.log(response.data);
      setAllDesignation(response.data.designations);
    }

    getAllDesignations();
  }, [])

  const handleinsertopen = () => {
    setOpen(true);
  }

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      <Button variant="contained" onClick={handleinsertopen}>
        Insert a new Entry <FaEdit style={{ marginLeft: "10px" }} />
      </Button>
      <Modal
        open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: isMdUp ? 400 : '100%', borderRadius: 2, bgcolor: 'background.paper', p: 3 }}>
          <h1 style={{ marginBottom: 30 }}>New employee</h1>
          <form>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Name"
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Email"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="lead-label">Designation</InputLabel>
              <Select labelId="lead-label" label="Employee" value={designation} onChange={handleDesignationChange}>
                <MenuItem value=""></MenuItem>
                {
                  allDesignation.map((desig: any) => (
                    <MenuItem key={desig.designation} value={desig.designation} >{desig.designation}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Salary"
              type="number"
              value={salary}
              onChange={(e) => setSalary(parseInt(e.target.value))}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Date of Joining" value={doj}
                  onChange={handleDojDateChange} />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker label="Last Working Day" value={lwd}
                  onChange={handleLwdDateChange} />
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="lead-label">User</InputLabel>
              <Select labelId="lead-label" label="Status" value={status} onChange={handleStatusChange}>
                <MenuItem key={"Active"} value={"Active"} >{"Active"}</MenuItem>
                <MenuItem key={"Inactive"} value={"Inactive"} >{"Inactive"}</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Create</Button>
              <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default InsertSalaries;
