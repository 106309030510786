import React from "react";
// import logo from './logo.svg';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Components/Login";
import Home from "./Components/Home";
import Header from "./Components/Header";
import { loadUser } from "./Actions/User";
import { Provider, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import ApproveMeeting from "./Components/ApproveMeeting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalenderTabs from "./Components/CalenderTabs";
import Footer from "./Components/Footer";
import OptionCard from "./Components/OptionCard";
import ComingSoon from "./Components/ComingSoon";
import TablesDashboard from "./Components/TablesDashboard";
import Test from "./Test";
import HRDashboard from "./Components/HRDashboard";
import FinanceDashboard from "./Components/FinanceDashboard";
import store from "./Redux/store";
import { Loader } from "./global-component";
import "./App.css";
import "./Assets/styles/styles.css";

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadUser());
  }, []);

  const { isAuthenticated } = useSelector((state: any) => state.user);
  return (
    <Provider store={store}>
      {/* {!isAuthenticated && <LoginRedirect/>} */}
      <ToastContainer autoClose={2000} />
      <Router>
        <Loader />
        <Header />
        <div className="main-content-wrapper" style={{ width: "100%" }}>
          <Routes>
            <Route
              path="/"
              element={isAuthenticated ? <OptionCard /> : <Login />}
            />
            {["/it_helpdesk"].map((path) => (
              <Route key={path} path={path} element={<ComingSoon />} />
            ))}

            {/* they all will go in auth */}
            <Route
              path="/erp"
              element={isAuthenticated ? <TablesDashboard /> : <Login />}
            />
            <Route path="/test" element={<Test />} />
            <Route
              path="/finance_helpdesk"
              element={isAuthenticated ? <FinanceDashboard /> : <Login />}
            />
            <Route
              path={"/hr_helpdesk"}
              element={isAuthenticated ? <HRDashboard /> : <Login />}
            />
            <Route
              path="/confroom"
              element={isAuthenticated ? <Home /> : <Login />}
            />
            <Route
              path="/home"
              element={isAuthenticated ? <OptionCard /> : <Login />}
            />
            <Route
              path="/booking/:room_id/:title"
              element={isAuthenticated ? <CalenderTabs /> : <Login />}
            />
            <Route
              path="/approve-meeting"
              element={isAuthenticated ? <ApproveMeeting /> : <Login />}
            />
          </Routes>
        </div>
      </Router>
      <Footer />
    </Provider>
  );
}

export default App;
