import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import * as Util from "../utility";

export class LeadService {
  private https: HttpService;
  private methods: Util.Methods;

  constructor() {
    this.https = new HttpService();
    this.methods = new Util.Methods();
  }

  async createLead<T>(data: T) {
    const baseURL = BASE_URL + `/lead/create/lead`;
    try {
      let response = await this.https.postRequest(baseURL, data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async getLeadDetail(leadId: number | string) {
    const baseURL = BASE_URL + `/get/lead/details?leadId=${leadId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async updateLeadDetails(data:any) {
    const baseURL = BASE_URL + `/lead/update`;
    try {
      let response = await this.https.postRequest(baseURL,data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async createRemark(data:{user_id: number, lead_id: number, remarks: string}) {
    const baseURL = BASE_URL + `/create/lead/remark`;
    try {
      let response = await this.https.postRequest(baseURL,data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async getLeadRemark(lead_id: string | number) {
    const baseURL = BASE_URL + `/lead/remark?lead_id=${lead_id}`;
    try {
      let response = await this.https.getRequest(baseURL,null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async getAllLeads(branch_id: string | number, role: string, user_id: number) {
    const baseURL = BASE_URL + `/leads/get-all-leads?branch_id=${branch_id}&role=${role}&user_id=${user_id}`;
    try {
      let response = await this.https.getRequest(baseURL,null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

}
