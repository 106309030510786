import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import './Footer.css'

const Footer = () => {
  return (
    <MDBFooter bgColor='light' className='text-center text-lg-left'>
      <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        Copyright  &copy; 2023. All rights reserved - Powered by 
        <a className='text-dark' href='https://www.clanconnect.ai/'>
        &nbsp; ClanConnect
        </a>
      </div>
    </MDBFooter>
  )
}

export default Footer
