import React, { Fragment, useEffect, useState } from "react";
import axios from "../../utils/axios";
import Divider from "@mui/material/Divider";
import { TextField, Checkbox, Autocomplete, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../Actions/branchActions";
import { toast } from "react-toastify";
import CurrentBillingStats from "../Analytics/Finance/CurrentBillingStats";
import { LineBarColumn } from "../../global-component/graphs/line-bar-column";
import { MBDatePicker } from "../../global-component";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { CommonService } from "../../core/service";
import { STATUS, EXPENSE_CATEGORY, BILLABLE } from "../../enums/expense_report";
import dayjs from "dayjs";
import { jwtDecode } from "jwt-decode";

const format = "YYYY-MM-DD";

const user_role: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

const commonService: CommonService = new CommonService();

type AutoCompleteType = { label: string; value: string };
const initialFilter = {
  start_date: "2024-01-01",
  end_date: dayjs(new Date()).format(format),
  branch: 0,
  expense_category: [] as AutoCompleteType[],
  billable: [] as AutoCompleteType[], // ["Yes"],
  status: [] as AutoCompleteType[], //  ["Not Paid"],
  lead_manager_id: [{label: '', value: roleobj?.id}] as typeof STATUS,
};

const TeamLeadExpenseReport: React.FC = () => {
  const dispatch = useDispatch();
  const branch = useSelector((state: any) => state.branch);
  const [teamLead, setTeamlead] = useState<typeof STATUS>([]);
  // chart Section
  const [chart, setChart] = useState<{
    chartData: any[];
    category: string[];
    stack: boolean;
  }>({
    chartData: [],
    category: [],
    stack: false,
  });
  // filter Section
  const [filter, setFilter] = useState<typeof initialFilter>(initialFilter);
  // table Data
  const [tableData, setTableData] = useState<any[]>([]);

  useEffect(() => {
    getCurrentBilling();

    commonService
      .getUsers({ role: ["Team Lead"] })
      .then((res) => {
        if (res.status > 0) {
          // res.data.rows
          const responseData = res.data.rows as {
            user_id: string;
            user_name: string;
          }[];
          let datas = responseData.map((val) => ({
            label: val.user_name,
            value: val.user_id,
          }));
          setTeamlead(datas);
          setFilter((prev) => ({
            ...prev,
            lead_manager_id: datas.filter((val) => val.value === roleobj?.id),
          }));
        }
      })
      .catch((error) => {
        throw new Error(
          `get bdTeam list Error in create new lead ==> ${error}`
        );
      });
  }, []);

  // useEffect(() => {
  //     setFilter(prev => ({...prev, end_date: dayjs(new Date()).format(format)}))
  // }, [filter.start_date])

  const getCurrentBilling = async () => {
    try {
      const readyFilterData = {
        ...filter,
        expense_category: filter.expense_category.map((val: any) => val?.value),
        billable: filter.billable.map((val: any) => val?.value),
        status: filter.status.map((val: any) => val?.value),
        branch: branch.branch,
        lead_manager_id: filter.lead_manager_id.map((val: any) => val?.value),
      };
      dispatch(loading(true));
      const response: any = await axios.post(
        "/report/get-fixed-expenses",
        readyFilterData
      );

      if (response.data.data.status > 0) {
        const res: any = response.data.data;
        const result = transformExpenseDataForGraph(res.rows);
        setTableData(_TransformIntoTable(res.rows));
        setChart((prev) => ({
          ...prev,
          chartData: result.dataSource,
          category: result.categories,
          stack: true,
        }));
      }
    } catch (err) {
      console.error(err);
      toast.error(`Service Unavailable!`, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    dispatch(loading(false));
  };

  function _TransformIntoTable(dataSource: any[]) {
    let tranformData: any[] = [];
    try {
      let months = Array.from(new Set(dataSource.map((val) => val.MONTH)));
      const updateTranform = (month: string) => {
        tranformData.push({
          MONTH: month,
          total_tax: 0,
          total_amount: 0,
        });
      };

      months.forEach((val) => {
        updateTranform(val);
      });

      dataSource.forEach((val) => {
        let monthIndex = months.indexOf(val.MONTH);

        if (monthIndex !== -1 && !val.expense_category) {
          return;
        }

        tranformData[monthIndex].total_tax += val.total_tax;
        tranformData[monthIndex].total_amount += val.total_amount;
      });
    } catch (err) {
      console.log(`_TranformIntoTable Method Failed Error :: ${err}`);
    }

    return tranformData;
  }

  function transformExpenseDataForGraph(expenseData: any[]) {
    // Determine unique months, categories, and billable statuses
    const months = Array.from(new Set(expenseData.map((item) => item.MONTH)));
    const categories = Array.from(
      new Set(expenseData.map((item) => item.expense_category).filter(Boolean))
    );
    const statuses = ["Yes", "No"];

    // Initialize the data structure with zeros for each month
    const transformedData: any[] = [];

    const addEntry = (name: string, stack: string) => {
      transformedData.push({
        name: name,
        data: Array(months.length).fill(0),
        stack: stack,
      });
    };

    // Add entries for each category and status combination
    categories.forEach((category) => {
      statuses.forEach((status) => {
        addEntry(
          `${category} & ${
            status === "Yes" ? "Billable" : "Non-Billable"
          } Principal`,
          `${category[0]} & ${status === "Yes" ? "B" : "NB"}`
        );
        addEntry(
          `${category} & ${status === "Yes" ? "Billable" : "Non-Billable"} GST`,
          `${category[0]} & ${status === "Yes" ? "B" : "NB"}`
        );
      });
    });

    // Add entries for 'ALL Principal' and 'All GST'
    addEntry("ALL Principal", "All");
    addEntry("All GST", "All");

    // Populate the data
    expenseData.forEach((item) => {
      const monthIndex = months.indexOf(item.MONTH);
      if (monthIndex === -1 || !item.expense_category) {
        return;
      }

      const principal = item.total_amount;
      const gst = item.total_tax;

      const categoryIndex = categories.indexOf(item.expense_category);
      const statusIndex = statuses.indexOf(item.billable);

      if (categoryIndex !== -1 && statusIndex !== -1) {
        const baseIndex = (categoryIndex * statuses.length + statusIndex) * 2;
        transformedData[baseIndex].data[monthIndex] += principal;
        transformedData[baseIndex + 1].data[monthIndex] += gst;
      }

      // Update 'ALL Principal' and 'All GST'
      transformedData[transformedData.length - 2].data[monthIndex] += principal;
      transformedData[transformedData.length - 1].data[monthIndex] += gst;
    });

    let newTransform = transformedData.filter((val) =>
      (val.name + "").toLowerCase().includes("gst")
    );

    newTransform = newTransform.concat(
      transformedData.filter(
        (val) => !(val.name + "").toLowerCase().includes("gst")
      )
    );

    // Prepare the final structure for the graph
    return {
      categories: months,
      dataSource: newTransform,
    };
  }

  return (
    <Fragment>
      <div className="expense-report-filter">
        <MBDatePicker
          onChange={(e) => setFilter((prev) => ({ ...prev, start_date: e }))}
          className="w-100"
          size="small"
          label="To"
          defaultValue={filter.start_date}
        />
        <MBDatePicker
          onChange={(e) => setFilter((prev) => ({ ...prev, end_date: e }))}
          className="w-100"
          size="small"
          label="From"
          defaultValue={filter.end_date}
          minDate={filter.start_date}
          maxDate={new Date().toString()}
        />
        <Autocomplete
          id="EXPENSE_CATEGORY_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={EXPENSE_CATEGORY}
          size="small"
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, expense_category: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Expense Category" />
          )}
        />
        <Autocomplete
          id="BILLABLE_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={BILLABLE}
          defaultValue={filter.billable}
          size="small"
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, billable: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Billable" />}
        />
        <Autocomplete
          id="STATUS_expense_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          options={STATUS}
          size="small"
          defaultValue={filter.status}
          onChange={(event, value) =>
            setFilter((prev) => ({ ...prev, status: value }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Status" />}
        />
        <Autocomplete
          disabled={roleobj?.role === "Team Lead"}
          id="teamLead_expense_id"
          className="w-100"
          limitTags={1}
          multiple={true}
          disableCloseOnSelect={true}
          value={filter.lead_manager_id}
          options={teamLead}
          size="small"
          onChange={(e, v) =>
            setFilter((prev) => ({ ...prev, lead_manager_id: v }))
          }
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={"expense-report-filter" + option.value}>
              <Checkbox
                icon={<CheckBoxOutlineBlank fontSize="small" />}
                checkedIcon={<CheckBox fontSize="small" />}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.label}
            </li>
          )}
          renderInput={(params) => <TextField {...params} label="Team Lead" />}
        />
        <LoadingButton
          loading={false}
          loadingPosition="start"
          startIcon={<SearchIcon />}
          variant="contained"
          onClick={getCurrentBilling}
        >
          {" "}
          Search
        </LoadingButton>
      </div>
      <Divider className="divider-margin" />
      <div className="account-table">
        <Stack
          direction={"column"}
          width={"100%"}
          style={{ padding: "10px 0px" }}
        >
          <CurrentBillingStats data={tableData} name={"ExpenseReports"} />
          <LineBarColumn
            dataSource={chart.chartData}
            id="director-finance-fixed-expenses-report"
            title={`${filter.expense_category
              .map((val: any) => val?.label)
              .join(", ")} Expenses (${dayjs(filter.start_date).format(
              "MMM-YYYY"
            )} to ${dayjs(filter.end_date).format("MMM-YYYY")})`}
            chartType="column"
            categories={chart.category}
            exporting={true}
            yAxisTitle="Amount ( in rupees )"
            stackingColumns={true}
          />
        </Stack>
      </div>
    </Fragment>
  );
};

export default TeamLeadExpenseReport;
