import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  TableSortLabel,
  TableFooter,
} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";
import * as Util from '../../../core/utility';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";


const methods: Util.Methods = new Util.Methods();


const user_role: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface Column {
  id: "MONTH" | "total_amount" | "total_tax";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: "MONTH", label: "Month", minWidth: 180 },
  { id: "total_amount", label: "Total Princple Amount", minWidth: 300 },
  { id: "total_tax", label: "Total tax amount", minWidth: 300 },
];

const PaymentsReport = ({ data, name }: any) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [rows, setrows] = useState<any>([]);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [tableFooterTotal, setTableFooterTotal] = useState({
    total_tax: 0,
    total_amount: 0,
  });

  useEffect(() => {
    setrows(data);
  }, [data]);

  const [sortModel, setSortModel] = useState<any>([
    { field: "MONTH", sort: "desc" },
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const filteredRows = searchTerm
    ? rows.filter((row: any) =>
      Object.values(row).some(
        (value: any) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    : rows;

  useEffect(() => {
    let _Sorted: any[] = filteredRows
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .sort(
        (a: any, b: any) =>
          (a[sortModel[0].field] > b[sortModel[0].field] ? 1 : -1) *
          (sortModel[0].sort === "desc" ? 1 : -1)
      );

    setSortedData(_Sorted);

    return () => {
      // clean useEffect
    };
  }, [page, rowsPerPage, page, rowsPerPage, rowsPerPage, rows, sortModel]);

  useEffect(() => {
    const temp = {
      total_tax: 0,
      total_amount: 0,
    };
    sortedData.forEach((val: { total_tax: number; total_amount: number }) => {
      temp.total_amount += val.total_amount;
      temp.total_tax += val.total_tax;
    });

    setTableFooterTotal(temp);

    return () => {
      // cleaning
    }

  }, [sortedData]);

  return (
    <React.Fragment>
      <Stack
        // paddingX={"2%"}
        padding={"10px 0px"}
        marginTop={"2px"}
        width="100%"
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
            <Table
              className="table-custom-style"
              stickyHeader
              aria-label="sticky table"
              size="small"
            >
              <TableHead className="table-head">
                <TableRow style={{ color: "white" }}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bolder",
                      }}
                    >
                      <TableSortLabel
                        active={sortModel[0].field === column.id}
                        direction={sortModel[0].sort}
                        onClick={() =>
                          setSortModel([
                            {
                              field: column.id,
                              sort:
                                sortModel[0].sort === "desc" ? "asc" : "desc",
                            },
                          ])
                        }
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                  <TableCell style={{ fontWeight: "bolder" }}>
                    Gross Amount
                  </TableCell>
                  <TableCell style={{ color: 'white', width: 30 }}>
                    <button
                      style={{ background: 'transparent', border: 'none', color: 'white' }}
                      onClick={() =>
                        methods.exportToExcel(filteredRows, `${name}.xlsx`)
                      }
                    >
                      <CloudDownloadIcon />
                    </button>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedData.map((row: any, index: number) => {
                  return (
                    <TableRow
                      hover
                      role="button"
                      tabIndex={-1}
                      key={index}
                      style={{
                        backgroundColor:
                          row.status === "Paid" ? "#86efac" : "white",
                      }}
                    >
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                maxWidth: "200px",
                                color:
                                  row.status === "Closed" ? "white" : "black",
                                fontWeight:
                                  row.status === "WIP" ? "bold" : "initial",
                              }}
                            >
                              {column.format && typeof value === "number"
                                ? methods._formatINR(Number(column.format(value)))
                                : value}
                            </div>
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        {methods._formatINR(Number(row.total_amount) + Number(row.total_tax))}
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  );
                })}

              </TableBody>
              <TableFooter>
                <TableRow >
                  <TableCell >
                    Total
                  </TableCell>
                  <TableCell >
                    {methods._formatINR(tableFooterTotal.total_amount)}
                  </TableCell>
                  <TableCell >
                    {methods._formatINR(tableFooterTotal.total_tax)}
                  </TableCell>
                  <TableCell >
                    {methods._formatINR(tableFooterTotal.total_amount +
                      tableFooterTotal.total_tax)}
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 15, 20]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Stack>
    </React.Fragment>
  );
};

export default PaymentsReport;
