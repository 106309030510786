import React, { Fragment, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination
} from "@mui/material";
import axios from "../../utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { loading } from "../../Actions/branchActions";
import { RevenueService } from "../../core/service/revenue.service";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import * as Util from "../../core/utility";

const user_id: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

interface leadItem {
  month: string;
  lead_name: string;
  won: number | null;
  lost: number | null;
  isCurrentRevenue: boolean;
  revenue: number;
  vp: string;
  tl: string;
  period_start: string;
  period_end: string;
  isShowPeriod: boolean;
}

interface revenueItem {
  month: string;
  won: number;
  lost: number;
  revenue: number;
  tl: string;
}

function CurrentRevenue({ startd, endd, teams, doApply, setDoApply }: any) {

  const revenueService: RevenueService = new RevenueService();
  const methods: Util.Methods = new Util.Methods();

  const dispatch = useDispatch();

  const branch = useSelector((state: any) => state.branch);
  const [leads, setleads] = useState([]);


  useEffect(() => {
    // dispatch(loading(true))
    // const fetchRevenue = async () => {
    //   const response = await axios.post(`/get-revenue`, { branch: branch.branch, user_role: roleobj?.role, teams: teams.map((team: any) => team.user_id) });
    //   setleads(response.data.data.rows);
    //   dispatch(loading(false))
    // }
    // fetchRevenue();

    dispatch(loading(true))
    // console.log("teams :: ", teams)
    revenueService.getRevenue(branch.branch, roleobj?.role, teams.map((team: any) => team.user_id)).then((res) => {
      if (res.status > 0) {
        setleads(res.data.rows);
      }
      dispatch(loading(false));
    }).catch((err) => console.log(`get all leads error :: ${err}`));

    setDoApply(false);

  }, [doApply, branch.branch])


  const [page, setPage] = useState(0);
  const [totalRevPage, setTotalRevPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalRevPerPage, setTotalRevPerPage] = useState(20);
  const [filteredRows, setFilteredRows] = useState<leadItem[]>([]);
  const [filteredRevenue, setFilteredRevenue] = useState<revenueItem[]>([]);


  const generateRevenue = (start: any, end: any, leads: any) => {
    let currentDate = new Date(start);
    const end_date = new Date(end);

    const result: any = [];
    let cumulativeRevenue = 0;


    while (currentDate <= end_date) {
      const month = currentDate.toLocaleString("default", { year: "numeric", month: 'long' });

      leads.forEach((lead: any) => {
        const lead_start_date = new Date(lead.start_date);
        const lead_end_date = new Date(lead.end_date);

        if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() < lead_end_date.getMonth()) {
          if (lead.status === "Converted") {

            cumulativeRevenue += Number(lead.price);

            const wonLeadItem: leadItem = {
              month: month,
              isCurrentRevenue: false,
              lead_name: lead.client_lead_name,
              lost: null,
              won: lead.price,
              period_start: lead.start_date,
              period_end: lead.end_date,
              revenue: cumulativeRevenue,
              tl: lead.tl_name,
              vp: lead.vp_name,
              isShowPeriod: true
            }

            result.push(wonLeadItem);
          }
        }

        if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() === lead_end_date.getMonth()) {

          if (lead.status === "Converted") {

            cumulativeRevenue -= Number(lead.price);

            const lostLeadItem: leadItem = {
              month: month,
              isCurrentRevenue: false,
              lead_name: lead.client_lead_name,
              lost: lead.price,
              won: null,
              period_start: lead.start_date,
              period_end: lead.end_date,
              revenue: cumulativeRevenue,
              tl: lead.tl_name,
              vp: lead.vp_name,
              isShowPeriod: true
            }

            result.push(lostLeadItem);
          }
        }
      });

      if (result.length >= 1) {
        result[result.length - 1].isCurrentRevenue = true;
      }

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return result;
  };

  useEffect(() => {
    const ApplyFilter = () => {
      if (leads) {
        const filteredData: leadItem[] = generateRevenue(startd, endd, leads);
        const filterRevenue: revenueItem[] = revenueService.generateTotalRevenue(startd, endd, leads, teams);
        // console.log(filterRevenue);
        setFilteredRows(filteredData);
        setFilteredRevenue(filterRevenue);
      }
    };

    ApplyFilter();
    setDoApply(false);
  }, [leads, doApply])

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleTotalRevChangePage = (event: any, newPage: any) => {
    setTotalRevPage(newPage);
  };

  const handleTotalRevChangeRowsPerPage = (event: any) => {
    setTotalRevPerPage(parseInt(event.target.value, 10));
    setTotalRevPage(0);
  };

  return (
    <div style={{ width: '95vw' }}>
      <div style={{ width: '100%', marginTop: 30 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead style={{ backgroundColor: '#2196F3' }}>
              <TableRow style={{ color: 'white' }}>
                <TableCell style={{ color: 'white' }}>Month</TableCell>
                <TableCell style={{ color: 'white' }}>Lost</TableCell>
                <TableCell style={{ color: 'white' }}>Won</TableCell>
                <TableCell style={{ color: 'white' }}>Total Revenue</TableCell>
                <TableCell style={{ color: 'white' }}>Team Leader</TableCell>
                <TableCell style={{ color: 'white', width: 30 }}>
                  <button
                    style={{ background: 'transparent', border: 'none', color: 'white' }}
                    onClick={() =>
                      methods.exportToExcel(filteredRows, "CurrentRevenue")
                    }
                  >
                    <CloudDownloadIcon />
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRevenue.slice(totalRevPage * totalRevPerPage, totalRevPage * totalRevPerPage + totalRevPerPage).map((item: revenueItem, index: any) => (
                <TableRow key={index}>
                  <TableCell>{item.month}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.lost), false)}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.won), false)}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.revenue), false)}</TableCell>
                  <TableCell>{item.tl}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredRevenue.length}
          rowsPerPage={totalRevPerPage}
          page={totalRevPage}
          onPageChange={handleTotalRevChangePage}
          onRowsPerPageChange={handleTotalRevChangeRowsPerPage}
        />
      </div>
      <div style={{ width: '100%', marginTop: 30 }}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead style={{ backgroundColor: '#2196F3' }}>
              <TableRow style={{ color: 'white' }}>
                <TableCell style={{ color: 'white' }}>Month</TableCell>
                <TableCell style={{ color: 'white' }}>Client</TableCell>
                <TableCell style={{ color: 'white' }}>Contract Period</TableCell>
                <TableCell style={{ color: 'white' }}>Lost</TableCell>
                <TableCell style={{ color: 'white' }}>Won</TableCell>
                <TableCell style={{ color: 'white' }}>Current Revenue</TableCell>
                <TableCell style={{ color: 'white' }}>Month Revenue</TableCell>
                <TableCell style={{ color: 'white' }}>VP (BD)</TableCell>
                <TableCell style={{ color: 'white' }}>Team Leader</TableCell>
                <TableCell style={{ color: 'white', width: 30 }}>
                  <button
                    style={{ background: 'transparent', border: 'none', color: 'white' }}
                    onClick={() =>
                      methods.exportToExcel(filteredRows, "BusinessOverview")
                    }
                  >
                    <CloudDownloadIcon />
                  </button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item: leadItem, index: any) => (
                <TableRow key={index}>
                  <TableCell>{item.month}</TableCell>
                  <TableCell>{item.lead_name}</TableCell>
                  <TableCell style={{ minWidth: '150px' }}>{item.isShowPeriod ? `${item.period_start} to ${item.period_end}` : ""}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.lost), false)}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.won), false)}</TableCell>
                  <TableCell>{methods._formatINR(Number(item.revenue), false)}</TableCell>
                  <TableCell style={{ color: item.isCurrentRevenue ? 'green' : 'black', fontWeight: item.isCurrentRevenue ? 'bold' : 'normal' }}>{item.isCurrentRevenue ? methods._formatINR(Number(item.revenue), false) : null}</TableCell>
                  <TableCell>{item.vp}</TableCell>
                  <TableCell>{item.tl}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
}

export default CurrentRevenue;
