import React, { useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { Autocomplete, Checkbox, TextField } from '@mui/material';
import { MBDatePicker } from '../../global-component';
import { LocalService } from '../../core/service';
import dayjs from 'dayjs';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import axios from "../../utils/axios";
import { Stack } from '@mui/system';
import { LineBarColumn } from '../../global-component/graphs/line-bar-column';
import { RevenueService } from '../../core/service/revenue.service';
import { useSelector, useDispatch } from 'react-redux';
import { loading } from '../../Actions/branchActions';

const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

interface salary {
  mon: string;
  total_salary: number;
}

interface purchase {
  mon: string;
  amount: number;
  team_id: number;
}

interface team {
  user_id: number;
  user_name: string;
}

interface reportData {
  month: string;
  team_leader: string;
  metric: number;
}

export default function DirectorReports() {

  const localService: LocalService = new LocalService();
  const revenueService: RevenueService = new RevenueService();

  const branch = useSelector((state: any) => state.branch);
  const dispatch = useDispatch();

  const currentDate = new Date();
  const oneMonthAgoDate = new Date(currentDate);
  oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() + 3);
  const oneMonthAgoYear = oneMonthAgoDate.getFullYear();
  const oneMonthAgoMonth = String(oneMonthAgoDate.getMonth() + 1).padStart(2, '0');
  const oneMonthAgoDay = String(oneMonthAgoDate.getDate()).padStart(2, '0');
  const formattedOneMonthAgoDate = `${oneMonthAgoYear}-${oneMonthAgoMonth}-${oneMonthAgoDay}`;

  // Six months ago
  const sixMonthsAgoDate = new Date(currentDate);
  sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 3);
  const sixMonthsAgoYear = sixMonthsAgoDate.getFullYear();
  const sixMonthsAgoMonth = String(sixMonthsAgoDate.getMonth() + 1).padStart(2, '0');
  const sixMonthsAgoDay = String(sixMonthsAgoDate.getDate()).padStart(2, '0');
  const formattedSixMonthsAgoDate = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;

  const handleExpense = () => {
    setDoApply(true);
  }

  const [startd, setstart] = useState(formattedSixMonthsAgoDate);
  const [endd, setend] = useState(dayjs().date.toString());
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<any[]>((roleobj?.role === "Team Lead") ? [{ user_name: roleobj?.name, user_id: roleobj?.id }] : []);
  const [doApply, setDoApply] = useState(false);

  const [dataLoaded, setDataLoaded] = useState(false);
  const [dataTransformed, setDataTransformed] = useState(false);

  const [purchases, setPurchases] = useState<purchase[]>([]);
  const [salaries, setSalaries] = useState<salary[]>([]);
  const [leads, setLeads] = useState<any[]>([]);

  const [filteredRows, setFilteredRows] = useState<any[]>([]);

  const [churnStats, setChurnStats] = useState<any[]>([]);
  const [performaceStats, setPerformanceStats] = useState<any[]>([]);
  const [productivityStats, setProductivityStats] = useState<any[]>([]);

  const handleChurnRate = () => {
    const churnRate: reportData[] = [];
    for (let i = 0; i < filteredRows.length; i++) {
      let churn = (Number(filteredRows[i].lost) / Number(filteredRows[i].revenue)).toFixed(2);
      let item: reportData = {
        month: filteredRows[i].month,
        team_leader: filteredRows[i].team_leader,
        metric: Number(churn)
      }

      churnRate.push(item);
    }

    setChurnStats(churnRate);
    // console.log(churnStats)
  }

  const handlePerformance = () => {
    const performance: reportData[] = [];
    for (let i = 1; i < filteredRows.length; i++) {
      let perf = ((filteredRows[i].revenue - filteredRows[i - 1].revenue) / Number(filteredRows[i].revenue)).toFixed(2);
      let item: reportData = {
        month: filteredRows[i].month,
        team_leader: filteredRows[i].team_leader,
        metric: Number(perf)
      }

      performance.push(item);
    }

    setPerformanceStats(performance);
  }

  const handleProductivity = () => {
    const productivity: reportData[] = [];
    for (let i = 0; i < filteredRows.length; i++) {
      let prod = (Number(filteredRows[i].revenue) / (Number(filteredRows[i].salary) + Number(filteredRows[i].overheads))).toFixed(2);
      let item: reportData = {
        month: filteredRows[i].month,
        team_leader: filteredRows[i].team_leader,
        metric: Number(prod)
      }

      productivity.push(item);
    }

    setProductivityStats(productivity);
    // console.log(churnStats)
  }

  useEffect(() => {
    const getAllTeams = async () => {
      axios.post(`/get-all-teams`, {}).then(
        (response: any) => {
          setTeams(response.data.data.rows);
        }
      ).catch((err) => {
        console.error("teams list error :: ", err)
        localService.toastify("Error fetching teams", "error", 2000);
      }).finally(() => {

      })
    }

    (roleobj?.role === "Director" || roleobj?.role === "Admin") && getAllTeams();
  }, []);

  useEffect(() => {
    dispatch(loading(true));
    const fetchData = async () => {
      await revenueService.getMonthlyPurchases(selectedTeams, startd, endd, branch.branch).then((res) => {
        if (res.status > 0) {
          setPurchases(res.data.rows);
        }
      }).catch((err) => console.log(`get all purchases error :: ${err}`));

      await revenueService.getMonthlySalary(startd, endd, branch.branch).then((res) => {
        if (res.status > 0) {
          setSalaries(res.data.rows);
        }
      }).catch((err) => console.log(`get all salaries error :: ${err}`));

      await revenueService.getRevenue(branch.branch, roleobj?.role, selectedTeams.map((team: any) => team.user_id)).then((res) => {
        if (res.status > 0) {
          setLeads(res.data.rows);
        }
        dispatch(loading(false));
      }).catch((err) => console.log(`get all leads error :: ${err}`));

      setDataLoaded(true);

      dispatch(loading(false));
    }

    fetchData();
    setDoApply(false);
  }, [doApply])

  useEffect(() => {
    setDoApply(true);
  }, [])

  useEffect(() => {
    const transformData = async () => {
      try {
        const revenue: any[] = await revenueService.generateTotalRevenue(startd, endd, leads, selectedTeams);
        const filterRows: any[] = await revenueService.makeReportData(purchases, salaries, teams, revenue);
        setFilteredRows(filterRows);

        setDataLoaded(false);
        setDataTransformed(true);
      } catch (error) {
        console.log(error)
      }
    }

    transformData();
  }, [dataLoaded])

  useEffect(() => {
    if (filteredRows.length > 0) {
      handleChurnRate();
      handlePerformance();
      handleProductivity();
    }

    setDataTransformed(false);
  }, [filteredRows, dataTransformed])


  return (
    <>
      <div className="expense-report-filter">
        <MBDatePicker
          defaultValue={formattedSixMonthsAgoDate}
          onChange={(e) => setstart(e)}
          className="w-100"
          size="small"
          label="From"
        />
        <MBDatePicker
          onChange={(e) => setend(e)}
          className="w-100"
          size="small"
          label="To"
          maxDate={dayjs().add(1, 'day').date.toString()}
        />
        {
          roleobj?.role === "Team Lead" ?
            <></> :
            <Autocomplete
              id="TEAM_id"
              className="w-100"
              limitTags={1}
              multiple={true}
              disableCloseOnSelect={true}
              options={teams}
              size="small"
              onChange={(event, value) =>
                setSelectedTeams(value)
              }
              getOptionLabel={(option) => option.user_name}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={"teams-filter" + option.user_id}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank fontSize="small" />}
                    checkedIcon={<CheckBox fontSize="small" />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.user_name}
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Teams" />
              )}
            />
        }
        <LoadingButton
          loading={false}
          variant="contained"
          onClick={handleExpense}
        >
          {" "}
          Apply
        </LoadingButton>
      </div>
      <div>
        {
          filteredRows.length > 0 &&
          <Stack direction={'column'} width={'100%'}>
            <LineBarColumn
              dataSource={[
                {
                  name: "client churn percent",
                  data: churnStats.map((item: any) => item.metric)
                }
              ]}
              id='director-admin-churn-report'
              title='Churn Rate'
              chartType='column'
              categories={churnStats.map((item: any) => item.month + '  ' + item.team_leader)}
              exporting={true}
              yAxisTitle='churn rate' />
            <LineBarColumn
              dataSource={[
                {
                  name: "percentage growth/decline rate",
                  data: performaceStats.map((item: any) => item.metric)
                }
              ]}
              id='director-admin-performace-review-report'
              title='Performace Review'
              chartType='bar'
              categories={performaceStats.map((item: any) => item.month + '  ' + item.team_leader)}
              exporting={true}
              yAxisTitle='performance' />
            <LineBarColumn
              dataSource={[
                {
                  name: "performance",
                  data: productivityStats.map((item: any) => item.metric)
                }
              ]}
              id='director-admin-productivity-report'
              title='Productivity'
              chartType='column'
              categories={productivityStats.map((item: any) => item.month + '  ' + item.team_leader)}
              exporting={true}
              yAxisTitle='productivity' />
          </Stack>
        }
      </div>
    </>
  );
}
