import React from "react";
import LockIcon from "@mui/icons-material/Lock";

export class AccessDenied extends React.Component {

  render(): React.ReactNode {
    return (
      <div className="access_denied">
        <LockIcon />
        <span>Access Denied</span>
        <span>
          You are not authorized to access the current module. Please contact
          your system administrator.
        </span>
      </div>
    );
  }
}
