import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TableCell, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";


interface EditAccountProps {
    id: number;
    expense_type: string;
    expense_category: string;
    frequency: string;
    is_billable: boolean;
    non_billable: boolean;
    team_lead: boolean;
    is_client: boolean;
    description: string;
    updateRefresh: any;
}

const ExpenseTypeMaster: React.FC<EditAccountProps> = ({
    id, expense_type, expense_category, frequency, is_billable, non_billable, team_lead, is_client, description, updateRefresh
}) => {
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [type, setType] = useState(expense_type);
    const [category, setCategory] = useState(expense_category);
    const [freq, setFreq] = useState(frequency);
    const [billable, setBillable] = useState(is_billable);
    const [nonBillable, setNonBillable] = useState(non_billable);
    const [team, setTeam] = useState(team_lead);
    const [client, setClient] = useState(is_client);
    const [desc, setDesc] = useState(description);

    const resetState = () => {
        setType(expense_type);
        setCategory(expense_category);
        setFreq(frequency);
        setBillable(is_billable);
        setNonBillable(non_billable);
        setTeam(team_lead);
        setClient(is_client);
        setDesc(description);
    }

    const handleEdit = async () => {

        try {
            const response = await axios.post(`update-expense-types`, {
                "id": id,
                "expense_type": type,
                "expense_category": category,
                "frequency": freq,
                "billable": billable ? "Yes" : "No",
                "non_billable": nonBillable ? "Yes" : "No",
                "team_lead": team ? "Yes" : "No",
                "client": client ? "Yes" : "No",
                "description": desc
            })

            if (response.data.updated) {
                toast.success(`Successfully Updated!`, {
                    position: 'top-right',
                    autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setOpen(false);
            }
        } catch (err) {
            console.log("API error ::: " + err);

            toast.error(`Please check all the entries carefully`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(true);
        }
        updateRefresh(true);

    }

    const handlePopup = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <TableCell>
                <IconButton color="primary" style={{ height: '8px' }}>
                    <InfoIcon onClick={handlePopup} />
                </IconButton>
            </TableCell>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <h2>{isEditing ? `Edit '${type}'` : `'${type}' Details`}</h2>
                        {!isEditing ? <IconButton color="primary" style={{ height: '8px' }}>
                            <EditIcon onClick={() => { setIsEditing(true) }} />
                        </IconButton> : <></>}
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <Stack
                        direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box sx={{ width: isMdUp ? 600 : '100%', minWidth: isMdUp ? 600 : '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, bgcolor: 'background.paper', p: 3, marginX: isMdUp ? 2 : 0 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Expense Type"
                                type="text"
                                value={type}
                                onChange={(e) => setType(e.target.value)}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Expense Category</InputLabel>
                                <Select onChange={(e) => setCategory(e.target.value)} value={category} label="Expense Category"
                                    readOnly={
                                        !isEditing ? true : false
                                    }
                                    IconComponent={isEditing ? undefined : () => null}
                                >
                                    <MenuItem value="Fixed">Fixed</MenuItem>
                                    <MenuItem value="One Time">One Time</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                    <MenuItem value="Variable">Variable</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl fullWidth sx={{ mt: 2 }}>
                                <InputLabel id="lead-label">Frequency</InputLabel>
                                <Select onChange={(e) => setFreq(e.target.value)} value={freq} label="Frequency"
                                    readOnly={
                                        !isEditing ? true : false
                                    }
                                    IconComponent={isEditing ? undefined : () => null}
                                >
                                    <MenuItem value="Half Yearly">Half Yearly</MenuItem>
                                    <MenuItem value="Monthly">Monthly</MenuItem>
                                    <MenuItem value="On Demand">On Demand</MenuItem>
                                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                                    <MenuItem value="Yearly">Yearly</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={billable} onChange={(e) => { setBillable(!billable) }} />}
                                    label="Billable" disabled={!isEditing ? true : false}
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={nonBillable} onChange={(e) => { setNonBillable(!nonBillable) }} />}
                                    label="Non Billable" disabled={!isEditing ? true : false}
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={team} onChange={(e) => { setTeam(!team) }} />}
                                    label="Team Lead" disabled={!isEditing ? true : false}
                                />
                            </FormControl>
                            <FormControl>
                                <FormControlLabel
                                    control={<Checkbox checked={client} onChange={(e) => { setClient(!client) }} />}
                                    label="Client" disabled={!isEditing ? true : false}
                                />
                            </FormControl>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Description"
                                type="text"
                                value={desc}
                                multiline
                                rows={4}
                                onChange={(e) => setDesc(e.target.value)}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                {isEditing ? <DialogActions>
                    <Button
                        variant="contained"
                        style={{ borderRadius: 5 }}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: 10, borderRadius: 5 }}
                        onClick={() => { setIsEditing(false); resetState() }}
                    >
                        Cancel Edit
                    </Button>
                </DialogActions> :
                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{ marginLeft: 10, borderRadius: 5 }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    );
};

export default ExpenseTypeMaster;
