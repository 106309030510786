import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { TdsTypes } from "../../enums/purchases";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import InfoIcon from "@mui/icons-material/Info";

import {
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  // styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs, { Dayjs } from "dayjs";
import { LoadingButton } from "@mui/lab";
import { Props } from "../InsertModal/InsertPurchase";
import { LocalService, CommonService } from "../../core/service";

const localService: LocalService = new LocalService();
const commonService: CommonService = new CommonService();

const GstPercent = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "12%", value: 12 },
  { label: "18%", value: 18 },
  { label: "28%", value: 28 },
];

interface EditAccountProps extends Props {
  id: number;
  client_id: number;
  vendor_id: string;
  team_id: number;
  Amount: string;
  inv_no: string;
  inv_date: string;
  inv_due_date: string;
  Remarks: string;
  exp_type: string;
  exp_cat: string;
  Billable: string;
  bill_type: { disable: boolean; defaultValue: string };
  Tds: string;
  updateRefresh: any;
  onClose: any;
  expense_mandate: string;
  cgst: number;
  sgst: number;
  igst: number;
  savedTax: string;
}

const PurchaseMaster: React.FC<EditAccountProps> = ({
  id,
  client_id,
  vendor_id,
  team_id,
  Amount,
  inv_no,
  inv_date,
  inv_due_date,
  Remarks,
  exp_type,
  Tds,
  updateRefresh,
  onClose,
  expenseCat,
  bill_type,
  expense_mandate = "External",
  cgst,
  sgst,
  igst,
  savedTax = "0",
}) => {
  const branch = useSelector((state: any) => state.branch);
  // const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [ClientID, setClientId] = useState(client_id);
  const [VendorID, setVendorId] = useState<string>(vendor_id);
  const [teamID, setTeamID] = useState(team_id);
  const [amount, setAmount] = useState(Amount);
  const [invoiceNumber, setInvoiceNumber] = useState(inv_no);
  const [invDate, setInvDate] = useState<string>(inv_date);
  const [invDueDate, setInvDueDate] = useState<string>(inv_due_date);
  const [remarks, setRemarks] = useState(Remarks);
  const [expenseType, setExpenseType] = useState(exp_type);
  const [expenseCategory, setExpenseCategory] = useState(
    expenseCat.defaultValue
  );
  // const [expenseFrequency, setExpenseFrequency] = useState("");
  const [formBillable, setBillable] = useState(
    bill_type.defaultValue.toLowerCase()
  );
  const [tdsDeduction, setTdsDeduction] = useState(1);
  const [tds, setTds] = useState(Tds);
  const [expenseMandatory, setExpenseMandatory] = useState(expense_mandate);

  // const [isBillable, setIsBillable] = useState(false);
  // const [isNonBillable, setIsNonBillable] = useState(false);
  const [isClientApplicable, setIsClientApplicable] = useState(false);
  const [isTeamApplicable, setIsTeamApplicable] = useState(false);
  const [tax, setTax] = useState<{ cgst: number; sgst: number; igst: number }>({
    cgst: cgst,
    sgst: sgst,
    igst: igst,
  });
  const [taxPercent, setTaxPercent] = useState<number>(parseInt(savedTax));

  const [allClients, setClients] = useState([]);
  const [allVendors, setVendors] = useState<
    { id: number; branch_name: string; gst: string }[]
  >([]);
  const [allExpenses, setAllExpenses] = useState<any>([]);
  const [allTeams, setAllTeams] = useState([]);
  const categories = ["Fixed", "Variable", "Others"];
  // const frequencies = ["Monthly", "Quarterly", "Yearly", "Half Yearly"];

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClientID = (e: any) => {
    setClientId(e.target.value);
  };

  const handleTeamID = (e: any) => {
    setTeamID(e.target.value);
  };

  const handleVendorID = (e: any) => {
    setVendorId(e.target.value);
  };

  const handleTdsDeduction = (e: any) => {
    setTdsDeduction(Number(e.target.value));
  };

  useEffect(() => {
    if (!isNaN(parseInt(amount + "")) && !isNaN(parseInt(taxPercent + ""))) {
      handlePercent();
    }

    return () => {};
  }, [amount, taxPercent, VendorID]);

  const handlePercent = async () => {
    try {
      // Validate Vender Id
      if (isNaN(parseInt(VendorID))) {
        throw new Error(`Vendor is missing.`);
      }
      // Validate Principal Amount
      if (isNaN(parseInt(amount))) {
        throw new Error(`Principal amount is missing`);
      }

      /* We have two cases if user belongs to same State use SGST, CGST if belongs to another to use only IGST */
      // Filter Vender Detail
      const vendorDetail = allVendors.filter(
        (val) => val.id === parseInt(VendorID)
      );
      if (vendorDetail.length === 0) {
        throw new Error(`Vendor Detail not found. `);
      }

      const model: typeof tax = {
        cgst: 0,
        sgst: 0,
        igst: 0,
      };
      // Validate GST NO and set Amount
      if (vendorDetail[0].gst.length > 5) {
        const vendorGst = parseInt(vendorDetail[0].gst?.substring(0, 2));
        const res = (await commonService.getBranchGst(
          branch.branch
        )) as unknown as {
          data: {
            fetchedRows: number;
            rows: { company_id: number; gst: string }[];
          };
        };
        if (res.data.fetchedRows > 0) {
          const companyGst = parseInt(res.data.rows[0].gst?.substring(0, 2));
          if (companyGst === vendorGst) {
            model.cgst = _calculateTax(+amount, taxPercent) / 2;
            model.sgst = _calculateTax(+amount, taxPercent) / 2;
          } else {
            model.igst = _calculateTax(+amount, taxPercent);
          }
        } else {
          throw new Error(`Company Gst is not found.`);
        }
      } else {
        model.igst = _calculateTax(+amount, taxPercent);
      }
      // set tax
      setTax(model);
    } catch (error) {
      localService.toastify(`${error}`, "error", 1000);
    }
  };

  const _calculateTax = (value: number, percentNumber: number) => {
    return (value * percentNumber) / 100;
  };

  const handleEdit = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(`create-update/purchase`, {
        id: id,
        expense_type: expenseType,
        expense_category: expenseCategory,
        bill_type: formBillable,
        amount: amount,
        tds: tds,
        team_id: isTeamApplicable ? teamID : 0,
        "`from`": branch.branch,
        "`to`": VendorID,
        status: "Not Paid",
        client_id: isClientApplicable ? ClientID : 0,
        invoice_no: invoiceNumber,
        invoice_date: invDate,
        invoice_due_date: invDueDate,
        remarks: remarks,
        expense_mandate: expenseMandatory,
        tax: taxPercent,
        ...tax,
      });

      if (response.data.status > 0) {
        toast.success(`Successfully Updated!`, {
          position: "top-right",
          autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        updateRefresh(true);
        onClose();
        // handleClose();
      }
    } catch (err) {
      console.log("API error ::: " + err);

      toast.error(`Please check all the entries carefully`, {
        position: "top-right",
        autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const getAllClients = async () => {
      const response: any = await axios.post(`/get-all-clients-id`, {
        branch: branch.branch,
      });
      setClients(response.data.data.rows);
    };

    const getAllVendor = async () => {
      const response: any = await axios.post(`/get-all-vendors`, {});
      setVendors(response.data.vendors);
    };

    const getExpenseTypes = async () => {
      const response: any = await axios.post(`/get-expense-types`, {});
      setAllExpenses(response.data.expenses);
    };

    const getManagers = async () => {
      const response: any = await axios.post(`/get-all-managers`, {});
      setAllTeams(response.data.managers);
    };

    getAllClients();
    getAllVendor();
    getExpenseTypes();
    getManagers();
  }, []);

  useEffect(() => {
    if (expenseType) {
      // const associatedCategory: any = allExpenses.find(
      //   (expense: any) => expense.id === expenseType
      // )?.expense_category;
      // const associatedFrequency: any = allExpenses.find(
      //   (expense: any) => expense.id === expenseType
      // )?.frequency;
      // const doBillable: any = allExpenses.find(
      //   (expense: any) => expense.id === expenseType
      // )?.billable;
      // const doNonBillable: any = allExpenses.find(
      //   (expense: any) => expense.id === expenseType
      // )?.non_billable;
      const clientAvaialble: any = allExpenses.find(
        (expense: any) => expense.id === expenseType
      )?.client;
      const teamAvaialable: any = allExpenses.find(
        (expense: any) => expense.id === expenseType
      )?.team_lead;

      // doBillable === "Yes" ? setIsBillable(true) : setIsBillable(false);
      // doNonBillable === "Yes"
      //   ? setIsNonBillable(true)
      //   : setIsNonBillable(false);
      clientAvaialble === "Yes"
        ? setIsClientApplicable(true)
        : setIsClientApplicable(false);
      teamAvaialable === "Yes"
        ? setIsTeamApplicable(true)
        : setIsTeamApplicable(false);
    }
  }, [expenseType, allExpenses]);

  const handleExpenseTypeChange = (e: any) => {
    setExpenseType(e.target.value);
  };

  const handleCategoryChange = (e: any) => {
    setExpenseCategory(e.target.value);
  };

  const handleBillableChange = (e: any) => {
    setBillable(e.target.value);
  };

  // const handleFrequencyChange = (e: any) => {
  //   setExpenseFrequency(e.target.value);
  // };

  // const handlePopup = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  const _calculateGrossAmount = (amount: number, tds: number) => (_calculateTax(Number(amount), taxPercent) + Number(amount)) - Number(tds);

  return (
    <React.Fragment>
      {/* <IconButton color="primary" style={{ height: '8px' }}>
        <InfoIcon onClick={handlePopup} />
      </IconButton> */}
      <Dialog
        open
        onClose={onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // maxWidth='xl'
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            className="primary-border"
          >
            <div className="form-header">
              <h2>
                {isEditing
                  ? `Edit '${invoiceNumber}'`
                  : `'${invoiceNumber}' Details`}
              </h2>
              <h2>{localStorage.getItem("cur_branch")}</h2>
            </div>

            {!isEditing ? (
              <IconButton color="primary" style={{ height: "8px" }}>
                <EditIcon
                  onClick={() => {
                    setIsEditing(true);
                  }}
                />
              </IconButton>
            ) : (
              <></>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form style={{ pointerEvents: `${!isEditing ? "none" : "all"}` }}>
            <Stack
              direction={isMdUp ? "row" : "column"}
              sx={{
                width: isMdUp ? 1200 : "100%",
                borderRadius: 2,
                bgcolor: "background.paper",
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                {/* <FormControl fullWidth sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Invoice Date" value={invDate}
                    onChange={(e: any) => setInvDate(e.target.value)} />
                </LocalizationProvider>
              </FormControl>
              <FormControl fullWidth sx={{ mt: 2 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker label="Invoice Due Date" value={invDueDate} minDate={invDate}
                    onChange={(e: any) => setInvDueDate(e.target.value)} />
                </LocalizationProvider>
              </FormControl> */}
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Invoice Date"
                  type="date"
                  value={invDate}
                  onChange={(e) => setInvDate(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Invoice Due Date"
                  type="date"
                  value={invDueDate}
                  onChange={(e) => setInvDueDate(e.target.value)}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Vendor</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="To"
                    value={VendorID}
                    onChange={handleVendorID}
                  >
                    <MenuItem value=""></MenuItem>
                    {allVendors.map((vendor: any) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        {vendor.brand_and_branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Invoice number"
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Expense Type</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Expense Type"
                    value={expenseType}
                    onChange={handleExpenseTypeChange}
                  >
                    <MenuItem value=""></MenuItem>
                    {allExpenses.map((expense: any) => (
                      <MenuItem key={expense.id} value={expense.id}>
                        {expense.expense_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Principle Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">GST%</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Tax"
                    value={taxPercent}
                    onChange={(e) =>
                      setTaxPercent(parseInt(e.target.value + ""))
                    }
                    required
                  >
                    {GstPercent.map((data, index) => (
                      <MenuItem
                        key={`key_${data.label}_${index + 2 * 100}`}
                        value={data.value}
                      >
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="CGST"
                  type="number"
                  value={tax.cgst}
                  disabled={true}
                />
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="SGST"
                  type="number"
                  value={tax.sgst}
                  disabled={true}
                />
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="IGST"
                  type="number"
                  value={tax.igst}
                  disabled={true}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Tds Type</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Source"
                    value={tdsDeduction}
                    onChange={handleTdsDeduction}
                    required
                  >
                    {TdsTypes.map((data, index) => (
                      <MenuItem key={`tds-type${index}_id`} value={data.value}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="TDS"
                  type="number"
                  value={tds}
                  onChange={(e) => setTds(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Gross Amount"
                  type="number"
                  value={_calculateGrossAmount(Number(amount), Number(tds))}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <FormControl
                  className="box-border-global"
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Expense Category</strong>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={expenseCategory}
                    onChange={handleCategoryChange}
                    name="radio-buttons-group"
                  >
                    {categories.map((category, index) => (
                      <FormControlLabel
                        disabled={expenseCat.disable}
                        key={category}
                        value={category}
                        control={<Radio />}
                        label={category}
                        checked={expenseCategory === category} // Check if the category matches the selected expense category
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl className="box-border-global" fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Billable</strong>
                  </FormLabel>

                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    // defaultValue="Yes"
                    value={formBillable}
                    onChange={handleBillableChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      disabled={bill_type.disable}
                      value="operational"
                      control={<Radio />}
                      label="Operational"
                    />
                    <FormControlLabel
                      disabled={bill_type.disable}
                      value="billable"
                      control={<Radio />}
                      label="Billable"
                    />
                    <FormControlLabel
                      disabled={bill_type.disable}
                      value="non billable"
                      control={<Radio />}
                      label="Not Billable"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl className="box-border-global" fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Expense Mandatory</strong>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="expense_mandatory_aria_id"
                    defaultValue="External"
                    name="radio-buttons-group"
                    value={expenseMandatory}
                    onChange={(e) => setExpenseMandatory(e.target.value)}
                  >
                    <FormControlLabel
                      value="External"
                      control={<Radio />}
                      label="External"
                    />
                    <FormControlLabel
                      value="Internal"
                      control={<Radio />}
                      label="Internal"
                    />
                  </RadioGroup>
                </FormControl>
                {/* {expenseCategory === "Fixed" ? <FormControl fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">Expense Frequency</FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={expenseFrequency}
                    onChange={handleFrequencyChange}
                    name="radio-buttons-group"
                  >
                    {frequencies.map((frequency, index) => (
                      <FormControlLabel
                        key={frequency}
                        value={frequency}
                        control={<Radio />}
                        label={frequency}
                        checked={expenseFrequency === frequency} // Check if the category matches the selected expense category
                      />
                    ))}
                  </RadioGroup>
                </FormControl> : <></>} */}
              </Box>
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <FormControl
                  fullWidth
                  sx={{ mt: 2, display: isTeamApplicable ? "block" : "none" }}
                >
                  <InputLabel id="lead-label">Team Lead</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Team Lead"
                    value={teamID}
                    onChange={handleTeamID}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allTeams.map((team: any) => (
                      <MenuItem key={team.user_id} value={team.user_id}>
                        {team.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ mt: 2, display: isClientApplicable ? "block" : "none" }}
                >
                  <InputLabel id="lead-label">Client</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Client"
                    value={ClientID}
                    onChange={handleClientID}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allClients.map((client: any) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.brand_and_branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Narration"
                  type="text"
                  multiline
                  rows={4}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Box>
            </Stack>
          </form>
        </DialogContent>
        {isEditing ? (
          <DialogActions>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              style={{ borderRadius: 5 }}
              onClick={handleEdit}
            >
              Update
            </LoadingButton>
            <Button
              variant="outlined"
              style={{ marginLeft: 10, borderRadius: 5 }}
              onClick={(e) => {
                setIsEditing(!isEditing);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        ) : (
          <DialogActions>
            <Button
              variant="outlined"
              style={{ marginLeft: 10, borderRadius: 5 }}
              onClick={onClose}
            >
              Close
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default PurchaseMaster;
