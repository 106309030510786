import React, { useState } from "react";
interface OptCardProps {
  id: number;
  url: string;
  title: string;
  description: string;
  onClick: () => void;
}

const OptCard: React.FC<OptCardProps> = (props) => {
  const handleCardClick = () => {
    props.onClick();
  };

  return (
    <>
      <div className="card" onClick={handleCardClick}>
        <div className="card-item">
          <img src={props.url} alt="room-pic" />
          <div className="card-content">
            <h1>{props.title}</h1>
            <p>{props.description}</p>

            <br />
          </div>
        </div>
      </div>
    </>
  );
};

export default OptCard;
