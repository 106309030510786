import { BASE_URL } from "../../config/config";
import * as Util from "../utility";
import { HttpService } from "./https.service";
import { LocalService } from "./local.service";

interface revenueItem {
    month: string;
    won: number;
    lost: number;
    revenue: number;
    tl: string;
}

interface expenses {
    month: string;
    salary: number;
    overheads: number;
    infra: number;
    team_leader?: string;
    revenue: number;
}

interface salary {
    mon: string;
    total_salary: number;
}

interface purchase {
    mon: string;
    amount: number;
    team_id: number;
}

interface team {
    user_id: number;
    user_name: string;
}

interface reportItem {
    won: number;
    lost: number;
    revenue: number;
    salary: number;
    overheads: number;
    infra: number;
    month: string;
    team_leader: string;
}

export class RevenueService {

    private localService: LocalService = new LocalService();
    private https: HttpService;

    constructor() {
        this.https = new HttpService();
    }

    public generateTotalRevenue = (start: any, end: any, leads: any, teams: any) => {
        let currentDate = new Date(start);
        const end_date = new Date(end);

        const result: any = [];
        let tl = 0;


        while (currentDate <= end_date) {

            const month = currentDate.toLocaleString("default", { year: "numeric", month: 'long' });
            let totalWon = 0;
            let totalLost = 0;
            let totalRevenue = 0;

            leads.forEach((lead: any) => {
                const lead_start_date = new Date(lead.start_date);
                const lead_end_date = new Date(lead.end_date);
                const curTeam = teams[tl];
                const team_lead = curTeam ? curTeam.user_id : -1;

                if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() < lead_end_date.getMonth() && lead.manager_leads_id === team_lead) {
                    if (lead.status === "Converted") {

                        totalRevenue += Number(lead.price);
                        totalWon += Number(lead.price);
                    }
                }

                if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() === lead_end_date.getMonth() && lead.manager_leads_id === team_lead) {

                    if (lead.status === "Converted") {

                        totalRevenue -= Number(lead.price);
                        totalLost += Number(lead.price);
                    }
                }

                if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() < lead_end_date.getMonth() && team_lead === -1) {
                    if (lead.status === "Converted") {

                        totalRevenue += Number(lead.price);
                        totalWon += Number(lead.price);
                    }
                }

                if (currentDate.getMonth() >= lead_start_date.getMonth() && currentDate.getMonth() === lead_end_date.getMonth() && team_lead === -1) {

                    if (lead.status === "Converted") {

                        totalRevenue -= Number(lead.price);
                        totalLost += Number(lead.price);
                    }
                }

            });

            let cur_team = teams[tl];
            let reveueEntry: revenueItem = {
                lost: totalLost,
                won: totalWon,
                revenue: totalRevenue,
                month: month,
                tl: cur_team ? cur_team.user_name : ''
            }

            result.push(reveueEntry);

            tl += 1;

            if (tl >= teams.length) {
                currentDate.setMonth(currentDate.getMonth() + 1);
                tl = 0;
            }
        }

        return result;
    };

    public makeData = (purc: purchase[], sal: salary[], teams: team[], revenue: any[]) => {
        const result: expenses[] = [];

        for (let i = 0; i < purc.length; i++) {

            let currentDate = new Date(`${purc[i].mon}-01`);
            let salEntry = sal.find((s: any) => s.mon === purc[i].mon);
            let curSalary = salEntry ? salEntry.total_salary : 0;
            let item: expenses = {
                salary: Number(curSalary),
                month: currentDate.toLocaleString("default", { year: "numeric", month: 'long' }),
                infra: 0,
                overheads: Number(purc[i].amount),
                team_leader: (teams.find((team: any) => team.user_id === purc[i].team_id) || {}).user_name || '',
                revenue: revenue[i].revenue
            }

            result.push(item);
        }

        return result;
    }

    public makeReportData = (purc: purchase[], sal: salary[], teams: team[], revenue: any[]) => {
        const result: reportItem[] = [];

        for (let i = 0; i < purc.length; i++) {

            let currentDate = new Date(`${purc[i].mon}-01`);
            let salEntry = sal.find((s: any) => s.mon === purc[i].mon);
            let curSalary = salEntry ? salEntry.total_salary : 0;
            let item: reportItem = {
                salary: Number(curSalary),
                month: currentDate.toLocaleString("default", { year: "numeric", month: 'long' }),
                infra: 0,
                overheads: Number(purc[i].amount),
                team_leader: (teams.find((team: any) => team.user_id === purc[i].team_id) || {}).user_name || '',
                revenue: revenue[i].revenue,
                won: revenue[i].won,
                lost: revenue[i].lost
            }

            result.push(item);
        }

        return result;
    }

    async getMonthlyPurchases(teams: team[], startd: any, endd: any, branch: any) {
        const baseURL = BASE_URL + `/get-${teams.length > 0 ? '' : 'all-'}monthly-purchases`;
        try {
            let response = await this.https.postRequest(baseURL, {
                branch: branch,
                start_date: startd,
                end_date: endd,
                teams: teams.map((team: team) => team.user_id)
            }, null);
            return response;
        } catch (err) {
            this.localService.toastify("Error fetching purchases", "error", 2000);
            throw new Error(`getMonthlyPurchasesError  ==> ${err}`);
        }
    }

    async getMonthlySalary(startd: any, endd: any, branch: any) {
        const baseURL = BASE_URL + `/get-monthly-salary`;
        try {
            let response = await this.https.postRequest(baseURL, {
                branch: branch, 
                start_date: startd, 
                end_date: endd, 
            }, null);
            return response;
        } catch (err) {
            this.localService.toastify("Error fetching salary", "error", 2000);
            throw new Error(`getMonthlySalaryError  ==> ${err}`);
        }
    }

    async getRevenue(branch: any, role: string, teams: any) {
        const baseURL = BASE_URL + `/get-revenue`;
        try {
            let response = await this.https.postRequest(baseURL, {
                branch: branch,
                user_role: role, 
                teams: teams
            }, null);
            return response;
        } catch (err) {
            this.localService.toastify("Error fetching leads", "error", 2000);
            throw new Error(`getRevenue  ==> ${err}`);
        }
    }

    // public getMonthlyPurchases = async (teams: team[], startd: any, endd: any, branch: any): Promise<purchase[]> => {
    //     axios.post(`/get-${teams.length > 0 ? '' : 'all-'}monthly-purchases`,
    //         {
    //             branch: branch,
    //             start_date: startd,
    //             end_date: endd,
    //             teams: teams.map((team: team) => team.user_id)
    //         }).then((resp) => {
    //             this.purchases = resp.data.data.rows;
    //         }).catch((error) => {
    //             console.error("getPurchases :: ", error)
    //             this.purchases = [];
    //             this.localService.toastify("Error fetching purchases", "error", 2000);
    //         }).finally(() => {
    //         })
    //     return this.purchases;

    // }

    // public getMonthlySalary = async (startd: any, endd: any, branch: any): Promise<salary[]> => {
    //     axios.post(`/get-monthly-salary`,
    //         {
    //             branch: branch,
    //             start_date: startd,
    //             end_date: endd,
    //         }).then((resp: any) => {
    //             this.salaries = resp.data.data.rows;
    //         }).catch((error) => {
    //             console.error("getMonthlySalary :: ", error)
    //             this.salaries = [];
    //             this.localService.toastify("Error fetching salaries", "error", 2000);
    //         }).finally(() => {
    //         })
    //     return this.salaries;

    // }
}
