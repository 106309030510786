import { useState, useEffect, useRef } from 'react';
import axios from '../utils/axios'
import './Login.css';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../Actions/User';
import MeetingCards from './MeetingCards';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { toast } from 'react-toastify';

const Login = () => {
  const loginBtnRef = useRef<any>(null);
  const [email, setEmail] = useState('');
  const { isAuthenticated } = useSelector((state: any) => state.user)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [domain, setDomain] = useState('@value360india.com');
  const [otp, setOtp] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [message, setMessage] = useState('');
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  const [isValidEmail, setIsValidEmail] = useState("");
  const [meetingData, setMeetingData] = useState<any[] | []>([]);

  let complete_email: any;

  useEffect(() => {
    // getMeetingsData()
  }, [])
  // console.log(email)
  // console.log(isValidEmail)

  const handlechange = async (value: any) => {
    // console.log(value)
    setOtp(value)
  }

  const handleAuthenticate = async (e: any) => {
    e.preventDefault();
    complete_email = `${email}${domain}`

    try {
      if (!email) {
        setIsValidEmail('Please enter an email address.');
        return;
      }

      if (!emailRegex.test(complete_email)) {
        setIsValidEmail('Please enter a valid email address.');
        return;
      }

      if (otp.length < 4) {
        setMessage("OTP must be of 4 digits")
        return;
      }
      complete_email = `${email}${domain}`
      dispatch(loginUser(complete_email, otp));
      // }

    } catch (error) {
      console.log(error);
      setMessage('Wrong PIN or Email');
    }
  }

  const handleEmailSubmit = async (e: any) => {
    e.preventDefault();
    complete_email = `${email}${domain}`

    try {
      if (!email) {
        setIsValidEmail('Please enter an email address.');
        return;
      }

      if (!emailRegex.test(complete_email)) {
        setIsValidEmail('Please enter a valid email address.');
        return;
      }
      // console.log("email submit", isValidEmail)
      // if(isValidEmail){
      await axios.post('/send-otp', { complete_email }).then((res: any) => {
        // console.log("data", res)
        if (res) {
          setOtpSent(true);
          toast.success(res.data.result)
        }

      })
      // }

    } catch (error) {
      console.log(error);
      setMessage('Failed to send OTP');
    }
  };


  const handleOtpSubmit = (e: any) => {
    e.preventDefault();
    // console.log(otp, otp.length)
    if (otp.length < 4) {
      setMessage("OTP must be of 4 digits")
      return;
    }
    complete_email = `${email}${domain}`
    dispatch(loginUser(complete_email, otp));
    // navigate('/home')

  };

  function matchIsNumeric(text: any) {
    const isNumber = typeof text === 'number'
    const isString = typeof text === 'string'
    return (isNumber || (isString && text !== '')) && !isNaN(Number(text))
  }

  const validateChar = (value: string, index: number) => {
    return matchIsNumeric(value)
  }

  return (
    <>
      <div className="background">

        {!isAuthenticated &&
          <form className="form-login" >
            <h3>Login Here</h3>
            {!otpSent && (
              // <form >

              <div className='login-container'>
                <div className='login-domain'>
                  <TextField
                    sx={{ width: '55%' }}
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />

                  <Box sx={{ width: '40%' }}>
                    <FormControl fullWidth >
                      <InputLabel id="demo-simple-select-label">Domain</InputLabel>
                      <Select

                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Domain"
                        value={domain}

                        onChange={(e) => setDomain(e.target.value)}
                      >
                        <MenuItem value="@value360india.com">@value360india.com</MenuItem>
                        <MenuItem value="@thepopkorn.com">@thepopkorn.com</MenuItem>
                        <MenuItem value="@clanconnect.ai">@clanconnect.ai</MenuItem>

                      </Select>
                    </FormControl>
                  </Box>


                </div>
                <p style={{ color: "red" }}>{isValidEmail}</p>
                {/* <button ></button> */}
                <MuiOtpInput
                  sx={{ marginTop: 2, marginBottom: 5 }}
                  value={otp}
                  onChange={handlechange}
                  TextFieldsProps={{
                    type: 'number'
                  }}
                  onComplete={(e: any) => {
                    if (loginBtnRef.current) {
                      loginBtnRef.current.focus();
                    }
                  }}
                  // validateChar={validateChar}
                  length={4}
                />
                <Button ref={loginBtnRef} variant="contained" size="large" onClick={handleAuthenticate}>
                  Login with PIN
                </Button>
                <Button variant="text" size="large" onClick={handleEmailSubmit}>
                  Forgot PIN
                </Button>

              </div>

              // </form>
            )}

            {otpSent && !otpVerified && (

              <div className='otp-container'>
                <p style={{ textAlign: "center" }}><strong>OTP Sent to : </strong>{email + domain}</p>
                <MuiOtpInput
                  value={otp}
                  onChange={handlechange}
                  validateChar={validateChar}
                  length={4}
                />

                <p style={{ color: 'red' }}>{message}</p>
                <Button variant="contained" size="large" onClick={handleOtpSubmit}>
                  Verify OTP
                </Button>
              </div>
            )}

          </form>
        }

      </div>
    </>
  )
}

export default Login