import axios from "axios";
import * as Util from "../utility";
import { LocalService } from "./local.service";

type RequestResponse = {
  status: number;
  data: {
    lead: { lead_id: number; branch_id: number; created_by: number; updated_by: number; vp_leads_id: number; lead_manager_id: number; manager_leads_id: number; client_id: number; client_lead_name: string; lead_date: string; source: string; referral: string; mandate: string; projected_fee: number; description: string; status: string; start_date: string; end_date: string; sow: string; poc: string; poc_email: string; poc_contact: string; account_poc: string; account_email: string; account_contact: string; price: number; bd_team_members: { user_id: number; employee_id: number; user_name: string; }[]; ops_team_members: { user_id: number; employee_id: number; user_name: string; }[]; };
    masterClients: { client_id: number; company_id: number; legal_name: string; brand_name: string; branch: string; address: string; city: string; state: string; country: string; pin: string; pan: string; gst: string; };
    affectedRows: number;
    changedRows: number;
    fieldCount: number;
    insertId: number;
    message: string;
    protocol41: true;
    serverStatus: number;
    warningCount: number;
    status: number;
    fetchedRows: number;
    rows: any[];
  };
};

export class HttpService {
  private http = axios.create();
  private localService = new LocalService();
  private methods = new Util.Methods();

  /*
       If User Header is null then auto set dynamic server header
      */

  // ---------------------------------------------------------------- Dynamic Header / Server Header ----------------------------------------------------------//
  private getHTTPOption(): any {
    let jwtToken: any = "";
    const md5Authorization = this.methods.getAuthKey();
    jwtToken = this.localService.getToken();

    // check for null or unidentified token
    if (
      jwtToken == null ||
      jwtToken === "undefined" ||
      jwtToken === "no token found"
    ) {
      jwtToken = "";
    }

    const httpOptions = {
      headers: {
        Authorization: md5Authorization,
        token: jwtToken,
        "Content-Type": "application/json ; charset=UTF-8",
      },
    };

    return httpOptions;
  }

  // ---------------------------------------------------------------- Upload Header ---------------------------------------------------------------- //
  private getUploadingOption(): any {
    let jwtToken: any = "";
    const md5Authorization = this.methods.getAuthKey();
    jwtToken = this.localService.getToken();

    // check for null or unidentified token
    if (
      jwtToken == null ||
      jwtToken === "undefined" ||
      jwtToken === "no token found"
    ) {
      jwtToken = "";
    }

    const httpOptions = {
      headers: {
        Authorization: md5Authorization,
        token: jwtToken,
      },
    };

    return httpOptions;
  }

  async getRequest(url: string, options: any, key?: string): Promise<RequestResponse> {
    let response: { [key: string]: any } = {};
    try {
      if (options == null || options === "" || options === "undefined") {
        options = this.getHTTPOption();
      } else if ((options + "").toString().toLocaleLowerCase() === "file") {
        options = this.getUploadingOption();
      }
      response = await axios.get(url, options);

      //save token in local storage
      const token = response.headers["token"];
      if ((token + "").toString().toLocaleLowerCase() !== "undefined") {
        this.localService.setToken(token);
      }

      response = response.data;
    } catch (error: any) {
      response["info"] = `Get Request Error ==> ${new Error(
        error.toString()
      )} stack ==> ${new Error(error.toString()).stack}`;
    } finally {
      return response as RequestResponse;
    }
  }

  // ---------------------------------------------------------------- Post Request ----------------------------------------------------------------

  async postRequest(url: string, payload: any, options: any) {
    let result: any;
    try {
      if (options == null || options === "" || options === "undefined") {
        options = this.getHTTPOption();
      } else if ((options + "").toString().toLocaleLowerCase() === "file") {
        options = this.getUploadingOption();
      }

      result = new Promise(async (resolve, reject) => {
        this.http
          .post(url, payload, options)
          .then((res) => {
            const token = res.headers["token"];
            if ((token + "").toString().toLocaleLowerCase() !== "undefined") {
              this.localService.setToken(token);
            }
            resolve(res.data);
          })
          .catch((errors) => {
            //handle errors
            console.log(
              `Request Error HTTPS :: URL(${url}) :: Options(${JSON.stringify(
                options
              )}) ERROR :: `,
              errors
            );
            // if user is unauthorized the navigate login router again
            if (errors.response.status === 401) {
              window.location.href = "/login";
            }
            reject(errors.response);
          });
      });

      return result;
    } catch (error: any) {
      //     console.log(`unauthorized access :: URL(${url}) :: Options(${JSON.stringify(options)}) error ==>`, error)
      //   if (error.status === 401) {
      //     window.location.href = '/login';
      //   }
    }
  }
}
