import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Leads from "./DataTables/Leads";
import Accounts from "./DataTables/Accounts";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { IoMdArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { loading, setBranchVisible } from "../Actions/branchActions";
import { AccessDenied } from "../global-component";
import LockIcon from "@mui/icons-material/Lock";
import Stats from "./DataTables/Statistics";
import DirectorReports from "./Analytics/DirectorReports";
import TeamLeadExpenseReport from "./Analytics/expense_report";
import ClientHealth from "./InfoModal/client_health";
import ClientReview from "./InfoModal/client_review";
const user_role: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

export default function TableDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [value, setValue] = React.useState("1");
  // const branch = useSelector((state: any) => state.branch);

  React.useEffect(() => {
    dispatch(setBranchVisible(true));
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (roleobj?.role !== "HR" && roleobj?.role !== "Finance") {
    return (
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box px={"30px"}>
            <Button
              onClick={() => {
                navigate("/");
              }}
            >
              <IoMdArrowBack color="#000" size={25} />
            </Button>
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Business Development" value="1" />
              <Tab label="Monthly Statistics" value="2" />
              <Tab label="Business Overview" value="3" />
              <Tab label="Performance" value="4" />
              <Tab label="Expense Report" value="5" />
              <Tab label="Client Review" value="6" />
              <Tab label="Client Health" value="7" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Leads />
          </TabPanel>
          {roleobj?.role === "Director" ||
          roleobj?.role === "Admin" ||
          roleobj.role === "Team Lead" ? (
            <TabPanel value="2">
              <Stats />
            </TabPanel>
          ) : (
            <TabPanel value="2">
              <AccessDenied />
            </TabPanel>
          )}
          {roleobj?.role === "Director" ||
          roleobj?.role === "Admin" ||
          roleobj?.role === "Team Lead" ? (
            <TabPanel value="3">
              <Accounts />
            </TabPanel>
          ) : (
            <TabPanel value="3">
              <AccessDenied />
            </TabPanel>
          )}
          {roleobj?.role === "Director" ||
          roleobj?.role === "Admin" ||
          roleobj?.role === "Team Lead" ? (
            <TabPanel value="4">
              <DirectorReports />
            </TabPanel>
          ) : (
            <TabPanel value="4">
              <AccessDenied />
            </TabPanel>
          )}
          {roleobj?.role === "Director" || roleobj?.role === "Team Lead" ? (
            <TabPanel value="5">
              <TeamLeadExpenseReport />
            </TabPanel>
          ) : (
            <TabPanel value="5">
              <AccessDenied />
            </TabPanel>
          )}
          {roleobj?.role === "Director" || roleobj?.role === "Team Lead" ? (
            <TabPanel value="6">
              <ClientReview />
            </TabPanel>
          ) : (
            <TabPanel value="6">
              <AccessDenied />
            </TabPanel>
          )}
          {roleobj?.role === "Director" || roleobj?.role === "Team Lead" ? (
            <TabPanel value="7">
              <ClientHealth />
            </TabPanel>
          ) : (
            <TabPanel value="7">
              <AccessDenied />
            </TabPanel>
          )}
        </TabContext>
      </Box>
    );
  } else {
    return (
      <>
        <p style={{ margin: 10, fontWeight: "bold" }}>Access Denied</p>
        <p style={{ margin: 10 }}>
          You are not authorized to access the current module. Please contact
          your system administrator.
        </p>
      </>
    );
  }
}
