import React from "react";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

interface Props {
    label: string;
    size?: 'small' | 'large';
    className?: string;
}

export const CustomDivider: React.FC<Props> = ({label, size, className}:Props) => {
  return (
    <Divider className={`custom-divider ${className}`}>
      <Chip label={`${label}`} size="small" />
    </Divider>
  );
};
