import React, { useState, useEffect } from "react";
import OptCard from "./OptCard";
import { Container, Grid } from "@mui/material";
import erpImage from '../Assets/images/erp.png'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from "react-router-dom";



import "./OptionCard.css";
import { useDispatch } from "react-redux";
import { setBranchVisible } from "../Actions/branchActions";
interface CardData {
  id: number;
  url: string;
  title: string;
  description: string;
}

const options: CardData[] = [
  {
    id: 1,
    title: "Conference Room",
    description: "Equipped space for meetings/presentations.",
    url: "https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/id_1.jpg",
  },
  {
    id: 2,
    title: "ERP",
    description: "Handle ERP",
    url: erpImage,
  },
  {
    id: 3,
    title: "Finance Desk",
    description: "Manages finances, budgets, and transactions.",
    url: "https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/tech.jpg",
  },
  {
    id: 4,
    title: "HR Desk",
    description: "Handles employee matters and policies",
    url: "https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/tech.jpg",
  },
  {
    id: 5,
    title: "IT Team",
    description: "Handles technology and support.",
    url: "https://value360-conf-booking-system.s3.ap-south-1.amazonaws.com/public-images/conf-room/tech.jpg",
  },

];

const user_role:any = localStorage.getItem('access_token');

let roleobj:any = null;
if(user_role)
{
roleobj = jwtDecode(user_role);
}

const OptionCard: React.FC = () => {
  const history = useNavigate();
  const dispath = useDispatch();
  const [defaultView, setDefaultView] = useState<any>(
    window.innerWidth < 768 ? 12 : 6
  );

  const handleVisit = (cardData: CardData) => {
    console.log(" Card's Data:", cardData);

    if (cardData.id === 1) {
      history("/confroom");
    } else if (cardData.id === 5) {
      history("/");
    } else if (cardData.id === 3) {
      history("/finance_helpdesk");
      //BD manaer gonna be HR
    } else if (cardData.id === 4) {
      history("/hr_helpdesk");
    }
    else if (cardData.id === 2) {
      history("/erp");
    }
   
   
    // if (cardData.id === 2 || cardData.id === 3 || cardData.id === 4) {
    //   console.log("Preventing default");
    // } else {
    //   console.log("Redirecting to home");
    //   history("/confroom");
    // }
    // !foundItems ? history("/home") : e.preventDefault();
  };

  useEffect(() => {
    dispath(setBranchVisible(false))
  }, [])

  return (
    <>
      <div className="card-container">
        <Container>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {options.map((cardData, index) => (
              <Grid item xs={defaultView}>
                <OptCard
                  id={cardData.id}
                  url={cardData.url}
                  key={index}
                  title={cardData.title}
                  description={cardData.description}
                  onClick={() => handleVisit(cardData)}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
    </>
  );
};

export default OptionCard;
