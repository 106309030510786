import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Stack, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import axios from "../../utils/axios";
import { jwtDecode } from "jwt-decode";
import InsertResource from "../InsertModal/InsertResource";
import EditResources from "../EditdataModal/EditResources";

const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
    },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch",
            },
        },
    },
}));

interface Column {
    id:
    | "user_name"
    | "employee_name"
    | "name"
    | "assinged_date"
    | "exit_date"
    label: string;
    minWidth?: number;
    align?: "right";
    format?: (value: number) => string;
}

const columns: Column[] = [
    { id: "user_name", label: "User", minWidth: 100 },
    { id: "employee_name", label: "Employee", minWidth: 100 },
    { id: "name", label: "Name", minWidth: 100 },
    { id: "assinged_date", label: "Assigned date", minWidth: 100 },
    { id: "exit_date", label: "Exit Date", minWidth: 100 }
];

const Resources = () => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState("");
    const [rows, setrows] = useState<any>([]);

    const [doRefresh, setDoRefresh] = useState(false);

    const updateRefresh = (refreshState: boolean) => {
        setDoRefresh(refreshState);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const filteredRows = searchTerm
        ? rows.filter((row: any) =>
            Object.values(row).some(
                (value: any) =>
                    value &&
                    value
                        .toString()
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
            )
        )
        : rows;

    useEffect(() => {
        const getAllResources = async () => {
            const response: any = await axios.post(
                `/get-all-resource`,
                {}
            );
            console.table(response.data.resources);
            if (response.data.fetched) {
                setrows(response.data.resources.reverse());
            } else {
                setrows([]);
            }
        };

        getAllResources();
    }, [doRefresh]);

    const user_role: any = localStorage.getItem('access_token');

    let roleobj: any = null;
    if (user_role) {
        roleobj = jwtDecode(user_role);
    }

    return (
        <Stack
            paddingX={"2%"}
            marginTop={"5px"}
            width="100%"
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
        >
            <Stack
                padding="10px"
                bgcolor="primary.main"
                color="primary.contrastText"
                direction="row"
                justifyContent={"space-between"}
                width={"100%"}
                marginY={"20px"}
                borderRadius={"10px"}
            >
                <Stack direction={"row"} spacing={4}>
                    {(roleobj?.role !== "Finance" || roleobj?.role === "Admin") &&
                        <InsertResource updateRefresh={updateRefresh} />
                    }

                    <Search>
                        <SearchIconWrapper>
                            <SearchIcon />
                        </SearchIconWrapper>
                        <StyledInputBase
                            placeholder="Search"
                            inputProps={{ "aria-label": "search" }}
                            onChange={handleSearchChange}
                            style={{ width: '40vw' }}
                        />
                    </Search>
                </Stack>
                <Typography fontSize={"25px"}>Resources</Typography>
            </Stack>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
                    <Table stickyHeader aria-label="sticky table" size="small">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth, fontWeight: "bolder" }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                                <TableCell
                                    key="edit-delete-cell"
                                    align="center"
                                    style={{ minWidth: 100, fontWeight: "bolder" }}
                                >
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredRows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row: any, index: number) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === "number"
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                            <TableCell key="edit-delete-cell" align="center">
                                                <Stack
                                                    direction="row"
                                                    spacing={2}
                                                    justifyContent={"center"}
                                                >
                                                    <EditResources id={row.id} user={row.user_id} employee={row.employee_id} name={row.name} exit_date={row.exit_date} assinged_date={row.assigned_date} />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[20, 25, 30]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Stack>
    );
};

export default Resources;
