import React, { SyntheticEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogActions,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";

import axios from "../../utils/axios";
import { FormLabel } from "react-bootstrap";
import { Stack, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";
import { TdsTypes } from "../../enums/purchases";
// import { MBDatePicker } from "../../global-component";
import { LoadingButton } from "@mui/lab";
import { CommonService, LocalService } from "../../core/service";

const commonService: CommonService = new CommonService();
const localService: LocalService = new LocalService();

const GstPercent = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "12%", value: 12 },
  { label: "18%", value: 18 },
  { label: "28%", value: 28 },
];

export interface Props {
  onClose: () => void;
  updateRefresh: (bool: boolean) => void;
  expenseCat: { disable: boolean; defaultValue: string };
  billable: {
    disable: boolean;
    defaultValue: "billable" | "non billable" | "operational";
  };
  expenseMandate: { disable: boolean; defaultValue: string };
}

const InsertPurchase: React.FC<Props> = ({
  onClose,
  updateRefresh,
  expenseCat,
  billable,
  expenseMandate,
}: Props) => {
  const branch = useSelector(
    (state: { branch: string | number } | any) => state.branch
  );
  const [ClientID, setClientId] = useState("");
  const [VendorID, setVendorId] = useState("");
  const [teamID, setTeamID] = useState("");
  const [amount, setAmount] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invDate, setInvDate] = useState<Dayjs | null>(dayjs());
  const [invDueDate, setInvDueDate] = useState<Dayjs | null>(
    dayjs().add(28, "day")
  );
  const [remarks, setRemarks] = useState("");
  const [expenseType, setExpenseType] = useState("");
  const [expenseCategory, setExpenseCategory] = useState(
    expenseCat.defaultValue
  );
  // const [expenseFrequency, setExpenseFrequency] = useState("");
  const [bill_type, setBill_type] = useState(billable.defaultValue);
  const [tdsDeduction, setTdsDeduction] = useState(1);
  const [tds, setTds] = useState("");

  const [taxPercent, setTaxPercent] = useState<number>(18);
  const [tax, setTax] = useState<{ cgst: number; sgst: number; igst: number }>({
    cgst: 0,
    sgst: 0,
    igst: 0,
  });

  // const [isBillable, setIsBillable] = useState(false);
  // const [isNonBillable, setIsNonBillable] = useState(false);
  const [isClientApplicable, setIsClientApplicable] = useState(false);
  const [isTeamApplicable, setIsTeamApplicable] = useState(false);

  const [allClients, setClients] = useState([]);
  const [allVendors, setVendors] = useState<
    { id: number; branch_name: string; gst: string }[]
  >([]);
  const [allExpenses, setAllExpenses] = useState<any>([]);
  const [allTeams, setAllTeams] = useState([]);
  const categories = ["Fixed", "Variable", "Others"];
  const [scroll] = useState<DialogProps["scroll"]>("paper");
  const [expenseMandatory, setExpenseMandatory] = useState("External");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClientID = (e: any) => {
    setClientId(e.target.value);
  };

  const handleTeamID = (e: any) => {
    setTeamID(e.target.value);
  };

  const handleVendorID = (e: any) => {
    setVendorId(e.target.value);
  };

  const handleTdsDeduction = (e: any) => {
    setTdsDeduction(Number(e.target.value));
  };

  useEffect(() => {
    const tax = Math.round(Number(amount) * (tdsDeduction / 100));
    setTds(tax.toString());
  }, [tdsDeduction, amount]);

  useEffect(() => {
    const getAllClients = async () => {
      const response: any = await axios.post(`/get-all-clients-id`, {
        branch: branch.branch,
      });
      setClients(response.data.data.rows);
    };

    const getAllVendor = async () => {
      const response: any = await axios.post(`/get-all-vendors`, {});
      setVendors(response.data.vendors);
    };

    const getExpenseTypes = async () => {
      const response: any = await axios.post(`/get-expense-types`, {});
      setAllExpenses(response.data.expenses);
    };

    const getManagers = async () => {
      const response: any = await axios.post(`/get-all-managers`, {});
      setAllTeams(response.data.managers);
    };

    getAllClients();
    getAllVendor();
    getExpenseTypes();
    getManagers();

    return;
  }, []);

  useEffect(() => {
    if (expenseType) {
      const associatedCategory: any = allExpenses.find(
        (expense: any) => expense.id === expenseType
      )?.expense_category;
      const clientAvaialble: any = allExpenses.find(
        (expense: any) => expense.id === expenseType
      )?.client;
      const teamAvaialable: any = allExpenses.find(
        (expense: any) => expense.id === expenseType
      )?.team_lead;

      setExpenseMandatory(
        allExpenses.find((obj: { id: string }) => obj.id === expenseType)
          ?.expense_mandate || "External"
      );
      clientAvaialble === "Yes"
        ? setIsClientApplicable(true)
        : setIsClientApplicable(false);
      teamAvaialable === "Yes"
        ? setIsTeamApplicable(true)
        : setIsTeamApplicable(false);

      // Set the expense category variable accordingly
      setExpenseCategory(associatedCategory || "");
    }
  }, [expenseType, allExpenses]);

  const handleExpenseTypeChange = (e: any) => {
    setExpenseType(e.target.value);
  };

  const handleCategoryChange = (e: any) => {
    setExpenseCategory(e.target.value);
  };

  const handleBillableChange = (e: any) => {
    setBill_type(e.target.value);
  };

  useEffect(() => {
    if (!isNaN(parseInt(amount + "")) && !isNaN(parseInt(taxPercent + ""))) {
      handlePercent();
    }

    return () => {};
  }, [amount, taxPercent, VendorID]);

  const handlePercent = async () => {
    try {
      // Validate Vender Id
      if (isNaN(parseInt(VendorID))) {
        throw new Error(`Vendor is missing.`);
      }
      // Validate Principal Amount
      if (isNaN(parseInt(amount))) {
        throw new Error(`Principal amount is missing`);
      }

      /* We have two cases if user belongs to same State use SGST, CGST if belongs to another to use only IGST */
      // Filter Vender Detail
      const vendorDetail = allVendors.filter(
        (val) => val.id === parseInt(VendorID)
      );
      if (vendorDetail.length === 0) {
        throw new Error(`Vendor Detail not found. `);
      }

      const model: typeof tax = {
        cgst: 0,
        sgst: 0,
        igst: 0,
      };
      // Validate GST NO and set Amount
      if (vendorDetail[0].gst.length > 5) {
        const vendorGst = parseInt(vendorDetail[0].gst?.substring(0, 2));
        const res = (await commonService.getBranchGst(
          branch.branch
        )) as unknown as {
          data: {
            fetchedRows: number;
            rows: { company_id: number; gst: string }[];
          };
        };
        if (res.data.fetchedRows > 0) {
          const companyGst = parseInt(res.data.rows[0].gst?.substring(0, 2));
          if (companyGst === vendorGst) {
            model.cgst = _calculateTax(+amount, taxPercent) / 2;
            model.sgst = _calculateTax(+amount, taxPercent) / 2;
          } else {
            model.igst = _calculateTax(+amount, taxPercent);
          }
        } else {
          throw new Error(`Company Gst is not found.`);
        }
      } else {
        model.igst = _calculateTax(+amount, taxPercent);
      }
      // set tax
      setTax(model);
    } catch (error) {
      localService.toastify(`${error}`, "error", 1000);
    }
  };

  const _calculateTax = (value: number, percentNumber: number) => {
    return (value * percentNumber) / 100;
  };

  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  async function handleSubmit(event: SyntheticEvent) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`/create-update/purchase`, {
        expense_type: expenseType,
        expense_category: expenseCategory,
        bill_type: bill_type,
        amount: amount,
        tds: tds,
        team_id: isTeamApplicable ? teamID : 0,
        "`from`": branch.branch,
        "`to`": VendorID,
        status: "Not Paid",
        client_id: isClientApplicable ? ClientID : 0,
        invoice_no: invoiceNumber,
        invoice_date: invDate?.toISOString().substring(0, 10),
        invoice_due_date: invDueDate?.toISOString().substring(0, 10),
        remarks: remarks,
        expense_mandate: expenseMandatory,
        tax: taxPercent,
        ...tax,
      });

      if (response.data.status > 0) {
        toast.success(`Successfully Inserted!`, {
          position: "top-right",
          autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        updateRefresh(true);
        onClose();
      }
    } catch (err) {
      toast.error(`Check all the entries carefully`, {
        position: "top-right",
        autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setIsLoading(false);
  };

  const _calculateGrossAmount = (amount: number, tds: number) => (_calculateTax(Number(amount), taxPercent) + Number(amount)) - Number(tds);

  return (
    <div>
      <Dialog
        open
        onClose={onClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // maxWidth='xl'
        fullScreen
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="scroll-dialog-title">
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <div>
                <h2>Create Expense</h2>
              </div>
              <div>
                <h2>{localStorage.getItem("cur_branch")}</h2>
              </div>
            </Stack>
          </DialogTitle>
          <DialogContent>
            <Stack
              direction={isMdUp ? "row" : "column"}
              sx={{
                width: isMdUp ? 1200 : "100%",
                borderRadius: 2,
                bgcolor: "background.paper",
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Invoice Date"
                      value={invDate}
                      onChange={(e: any) => setInvDate(e.target.value)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Invoice Due Date"
                      value={invDueDate}
                      minDate={invDate}
                      onChange={(e: any) => setInvDueDate(e.target.value)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Vendor</InputLabel>
                  <Select
                    required
                    labelId="lead-label"
                    label="To"
                    value={VendorID}
                    onChange={handleVendorID}
                  >
                    {/* <MenuItem value=""><em>None</em></MenuItem> */}
                    {allVendors.map((vendor: any) => (
                      <MenuItem key={vendor.id} value={vendor.id}>
                        {vendor.brand_and_branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Invoice number"
                  required
                  type="text"
                  value={invoiceNumber}
                  onChange={(e) => setInvoiceNumber(e.target.value)}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Expense Type</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Expense Type"
                    value={expenseType}
                    onChange={handleExpenseTypeChange}
                    required
                  >
                    {allExpenses.map((expense: any) => (
                      <MenuItem key={expense.id} value={expense.id}>
                        {expense.expense_type}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  required
                  fullWidth
                  label="Principle Amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">GST%</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Tax"
                    value={taxPercent}
                    onChange={(e) => setTaxPercent(+e.target.value)}
                    required
                  >
                    {GstPercent.map((data, index) => (
                      <MenuItem
                        key={`key_${data.label}_${index + 2 * 100}`}
                        value={data.value}
                      >
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="CGST"
                  type="number"
                  value={tax.cgst}
                  disabled={true}
                />
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="SGST"
                  type="number"
                  value={tax.sgst}
                  disabled={true}
                />
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="IGST"
                  type="number"
                  value={tax.igst}
                  disabled={true}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Tds Type</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Source"
                    value={tdsDeduction}
                    onChange={handleTdsDeduction}
                    required
                  >
                    {TdsTypes.map((data, index) => (
                      <MenuItem key={`tds-type${index}_id`} value={data.value}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="TDS"
                  type="number"
                  value={tds}
                  onChange={(e) => setTds(e.target.value)}
                />
                <TextField
                  required
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Gross Amount"
                  type="number"
                  value={_calculateGrossAmount(Number(amount), Number(tds))}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <FormControl
                  className="box-border-global"
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Expense Category</strong>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={expenseCategory}
                    onChange={handleCategoryChange}
                    name="radio-buttons-group"
                  >
                    {categories.map((category, index) => (
                      <FormControlLabel
                        key={category}
                        value={category}
                        control={<Radio disabled={expenseCat.disable} />}
                        label={category}
                        checked={expenseCategory === category} // Check if the category matches the selected expense category
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormControl className="box-border-global" fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Billable</strong>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={bill_type}
                    onChange={handleBillableChange}
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      // disabled={!isBillable}
                      disabled={billable.disable}
                      value="operational"
                      control={<Radio />}
                      label="Operational"
                    />
                    <FormControlLabel
                      // disabled={!isBillable}
                      disabled={billable.disable}
                      value="billable"
                      control={<Radio />}
                      label="Billable"
                    />
                    <FormControlLabel
                      // disabled={!isNonBillable}
                      disabled={billable.disable}
                      value="non billable"
                      control={<Radio />}
                      label="Not Billable"
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl className="box-border-global" fullWidth>
                  <FormLabel id="demo-radio-buttons-group-label">
                    <strong>Expense Mandatory</strong>
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="expense_mandatory_aria_id"
                    defaultValue="External"
                    name="radio-buttons-group"
                    value={expenseMandatory}
                    onChange={(e) => setExpenseMandatory(e.target.value)}
                  >
                    <FormControlLabel
                      value="External"
                      control={<Radio />}
                      label="External"
                    />
                    <FormControlLabel
                      value="Internal"
                      control={<Radio />}
                      label="Internal"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Box
                sx={{
                  width: isMdUp ? 400 : "100%",
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <FormControl
                  fullWidth
                  sx={{ mt: 2, display: isTeamApplicable ? "block" : "none" }}
                >
                  <InputLabel id="lead-label">Team Lead</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Team Lead"
                    value={teamID}
                    onChange={handleTeamID}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allTeams.map((team: any) => (
                      <MenuItem key={team.user_id} value={team.user_id}>
                        {team.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl
                  fullWidth
                  sx={{ mt: 2, display: isClientApplicable ? "block" : "none" }}
                >
                  <InputLabel id="client-label">Client</InputLabel>
                  <Select
                    labelId="client-label"
                    label="Client"
                    value={ClientID}
                    onChange={handleClientID}
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {allClients.map((client: any) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.brand_and_branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Narration"
                  type="text"
                  multiline
                  rows={4}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={isLoading}
              variant="contained"
              style={{ borderRadius: 5 }}
              type="submit"
            >
              Create
            </LoadingButton>
            <Button
              variant="outlined"
              style={{ marginLeft: 10, borderRadius: 5 }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default InsertPurchase;
