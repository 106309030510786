import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material';
import { IoMdArrowBack } from "react-icons/io";
import { margin } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { setBranchVisible } from '../../../Actions/branchActions';
import BillingReport from './BillingReport';
import PerformanceReviewReport from './PerformaceReviewReport';
import ChurnReport from './ChurnReport';
import ProductivityRatio from './ProductivityRatio';
import FixedExpenseReport from './FixedExpenseReport';
import CollectionReport from './CollectionReport';
import PaymentReport from './payments';


const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}


export default function MasterFinanceReports() {

  const dispatch = useDispatch();

  const [value, setValue] = React.useState('1');
  const branch = useSelector((state: any) => state.branch);

  React.useEffect(() => {
    dispatch(setBranchVisible(true))
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (roleobj?.role === "Director" || roleobj?.role === "Finance" || roleobj?.role === "Admin") {
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <TabContext value={value}>
          <Box px={'30px'} >
            <TabList 
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Billings" value="1" />
              <Tab label="Collections" value="3" />
              <Tab label="Expenses" value="2" />
              <Tab label="Payments" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1"><BillingReport /></TabPanel>
          <TabPanel value="2"><FixedExpenseReport /></TabPanel>
          <TabPanel value="3"><CollectionReport /></TabPanel>
          <TabPanel value="4"><PaymentReport /></TabPanel>
        </TabContext>
      </Box>
    );
  }

  else {
    return (<>
    <p style={{ margin: 10, fontWeight: "bold" }}>Access Denied</p>
    <p style={{ margin: 10 }}>You are not authorized to access the current module. Please contact your system administrator.</p>
    </>)
  }

}
