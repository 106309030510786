import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  Typography,
  TableSortLabel,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { styled, alpha } from "@mui/material/styles";
import { InsertLead } from "../InsertModal/InsertLead";
import { jwtDecode } from "jwt-decode";
import * as XLSX from "xlsx";
import LeadMaster from "../MasterForms/LeadMaster";
import { FaFile } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { LeadService } from "../../core/service";
import { MandateFilter } from "../../enums/leads";
import { loading } from "../../Actions/branchActions";
import * as Util from "../../core/utility";
import dayjs from "dayjs";

const leadService: LeadService = new LeadService();
const methods: Util.Methods = new Util.Methods();

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

interface Column {
  id:
    | "vp_name"
    | "lead_manager"
    | "team_leader"
    | "client_lead_name"
    | "description"
    | "projected_fee"
    | "status"
    | "legal_name"
    | "start_date"
    | "end_date"
    | "price"
    | "created_at"
    | "created_at";
  label: string;
  minWidth?: number;
  align?: "right";
  format?: (value: number) => string;
}

const user_id: any = localStorage.getItem("access_token");

let roleobj: any = null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

const columns: Column[] = [
  { id: "vp_name", label: "VP Lead", minWidth: 180 },
  { id: "lead_manager", label: "Lead Manager", minWidth: 200 },
  { id: "team_leader", label: "Team Leader", minWidth: 200 },
  { id: "client_lead_name", label: "Lead Name", minWidth: 200 },
  { id: "projected_fee", label: "Projected fee", minWidth: 160 },
  { id: "status", label: "Status", minWidth: 80 },
  { id: "start_date", label: "Start Date", minWidth: 150 },
  { id: "end_date", label: "End Date", minWidth: 150 },
  { id: "price", label: "Price", minWidth: 100 },
  { id: "created_at", label: "Created At", minWidth: 150 },
];

const Leads = () => {
  const branch = useSelector((state: any) => state.branch);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [searchTerm, setSearchTerm] = useState("");
  const [bdmanagerid, setbdmanagerid] = useState<number>(roleobj?.id);
  const [rows, setrows] = useState<any>([]);
  const [filterRows, setFilterRows] = useState<any>([]);
  // const [open, setOpen] = useState(false);
  const [excelData, setExcelData] = useState<any>([]);
  const [doRefresh, setDoRefresh] = useState(false);
  const [mandateFilter, setMandateFilter] = useState<string>(
    "Retainer || Project"
  );

  const dispatch = useDispatch();

  const updateRefresh = (refreshState: boolean) => {
    setDoRefresh(refreshState);
  };

  // const handleClose = () => setOpen(false);

  const [sortModel, setSortModel] = useState<any>([
    { field: "", sort: "desc" },
  ]);

  const exportToExcel = () => {
    // Convert tableData to Excel format
    const worksheet = XLSX.utils.json_to_sheet(excelData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Generate Excel file
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(data);

    // Trigger file download
    const link = document.createElement("a");
    link.href = url;
    link.download = "leads.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setPage(0);
  };

  const filteredRows = searchTerm
    ? filterRows.filter((row: any) =>
        Object.values(row).some(
          (value: any) =>
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : filterRows;

  const handleMandateFilter = (e: any) => {
    let filter = e.target.value;
    setMandateFilter(filter);

    let tempRows = rows.filter((row: any) => {
      if (filter === "Retainer || Project") {
        return row;
      }
      return row.mandate === filter;
    });

    setFilterRows(tempRows);
  };

  useEffect(() => {
    dispatch(loading(true));
    const fetchRole = async () => {
      if (roleobj?.role === "BD Manager") {
        setbdmanagerid(roleobj?.id);
      }
    };
    console.log(branch);

    leadService
        .getAllLeads(branch.branch, roleobj.role, roleobj.id)
        .then((res) => {
          if (res.status > 0) {
            setrows(res.data.rows);
            setFilterRows(res.data.rows);
            setExcelData(res.data.rows);

            dispatch(loading(false));
          }
        })
        .catch((err) => console.log(`get all lead error :: ${err}`));

    // if (
    //   roleobj?.role === "Director" ||
    //   roleobj?.role === "Admin" ||
    //   roleobj?.branch === branch.branch
    // ) {
    //   leadService
    //     .getAllLeads(branch.branch, roleobj.role, roleobj.id)
    //     .then((res) => {
    //       if (res.status > 0) {
    //         setrows(res.data.rows);
    //         setFilterRows(res.data.rows);
    //         setExcelData(res.data.rows);

    //         dispatch(loading(false));
    //       }
    //     })
    //     .catch((err) => console.log(`get all lead error :: ${err}`));
    // } else {
    //   leadService
    //     .getAllLeads(0, roleobj.role, roleobj.id)
    //     .then((res) => {
    //       if (res.status > 0) {
    //         setrows(res.data.rows);
    //         setFilterRows(res.data.rows);
    //         setExcelData(res.data.rows);

    //         dispatch(loading(false));
    //       }
    //     })
    //     .catch((err) => console.log(`get all lead error :: ${err}`));
    // }

    // const getallBDleads = async () => {
    //   const response = await axios.post(
    //     `/leads/get-all-leads`, { branch_id: branch.branch }
    //   );
    //   console.log(response.data.leads);
    //   if (response.data.fetched > 0) {
    //     setrows(response.data.leads.rows.reverse());
    //     setExcelData(response.data.leads.rows.reverse());
    //   } else {
    //     setrows([]);
    //   }
    //   // setrows([]);
    // };

    fetchRole();
    updateRefresh(false);
    dispatch(loading(false));

    return () => {
      //clean useEffect
    };
  }, [bdmanagerid, doRefresh]);

  return (
    <React.Fragment>
      <Stack
        paddingX={"2%"}
        marginTop={"2px"}
        width="100%"
        direction={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack
          padding="10px"
          bgcolor="primary.main"
          color="primary.contrastText"
          direction="row"
          justifyContent={"space-between"}
          width={"100%"}
          marginY={"5px"}
          borderRadius={"10px"}
          className="custom-stack-layout"
        >
          <Stack direction={"row"} spacing={4}>
            {roleobj?.role === "BD Manager" ||
            roleobj?.role === "BD Team" ||
            roleobj?.role === "Admin" ||
            roleobj?.role === "Director" ||
            roleobj?.role === "BD VP" ? (
              <InsertLead updateRefresh={updateRefresh} />
            ) : (
              <></>
            )}
            <Button
              variant="contained"
              style={{
                backgroundColor: "white",
                color: "#2196F3",
                marginLeft: 10,
              }}
              onClick={exportToExcel}
            >
              Export to Excel
              <FaFile style={{ marginLeft: "10px" }} />
            </Button>
            <Search style={{ marginLeft: 10 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                onChange={handleSearchChange}
                style={{ width: "40vw" }}
              />
            </Search>
            <FormControl size="small">
              <InputLabel id="lead-label" sx={{ color: "white" }}>
                Mandate
              </InputLabel>
              <Select
                labelId="lead-label"
                label="Mandate"
                name="mandate"
                value={mandateFilter}
                onChange={handleMandateFilter}
                required
                sx={{
                  color: "white",
                }}
                IconComponent={() => null}
              >
                {MandateFilter.map((data, index) => (
                  <MenuItem key={`insert-lead_${index}_id`} value={data.value}>
                    {data.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography fontSize={"25px"}>Leads</Typography>
        </Stack>
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: "80vh", overflowX: "scroll" }}>
            <Table
              className="custom-table-header"
              stickyHeader
              aria-label="sticky table"
              size="small"
            >
              <TableHead className="table-header">
                <TableRow>
                  <TableCell
                    key="info-cell"
                    align="center"
                    style={{ minWidth: 30, fontWeight: "bolder" }}
                  ></TableCell>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        fontWeight: "bolder",
                      }}
                    >
                      <TableSortLabel
                        active={sortModel[0].field === column.id}
                        direction={sortModel[0].sort}
                        onClick={() =>
                          setSortModel([
                            {
                              field: column.id,
                              sort:
                                sortModel[0].sort === "desc" ? "asc" : "desc",
                            },
                          ])
                        }
                      >
                        {column.label}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .sort(
                    (a: any, b: any) =>
                      (a[sortModel[0].field] > b[sortModel[0].field] ? 1 : -1) *
                      (sortModel[0].sort === "desc" ? 1 : -1)
                  )
                  .map((row: any, index: number) => {
                    return (
                      <TableRow
                        hover
                        role="button"
                        tabIndex={-1}
                        key={index}
                        // className={` ${(row.status + '').toLowerCase()} ${new Date(row.end_date) <= new Date() ? 'inactive-row' : ''}`}
                        className={`${(row.status + "").toLowerCase()} ${
                          (row.status + "").toLowerCase() === "expired" &&
                          "inactive-row"
                        }`}
                      >
                        <LeadMaster updateRefresh={updateRefresh} data={row} />
                        {columns.map((column) => {
                          let value = "";
                          switch (column.id) {
                            case "projected_fee":
                              value = methods._formatINR(row[column.id]);
                              break;
                            case "price":
                              value = methods._formatINR(
                                parseInt(row[column.id])
                              );
                              break;
                            case "created_at":
                              value = dayjs(new Date(row[column.id])).format(
                                "DD, MMM, YYYY"
                              );
                              break;
                            case "start_date":
                              value = dayjs(new Date(row[column.id])).format(
                                "DD, MMM, YYYY"
                              );
                              break;
                            case "end_date":
                              value = dayjs(new Date(row[column.id])).format(
                                "DD, MMM, YYYY"
                              );
                              break;
                            default:
                              value = row[column.id];
                              break;
                          }
                          return (
                            <TableCell key={column.id} align={column.align}>
                              <div
                                style={{
                                  whiteSpace: "nowrap",
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  maxWidth: "200px",
                                  color:
                                    row.status === "Closed" ? "white" : "black",
                                  fontWeight:
                                    row.status === "WIP" ? "bold" : "initial",
                                }}
                              >
                                {value}
                                {/* {typeof value === 'number' ? '' : value} */}
                                {/* {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value} */}
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 25, 30]}
            component="div"
            count={filterRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Stack>
    </React.Fragment>
  );
};

export default Leads;
