import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Leads from './DataTables/Leads';
import OverHeads from './DataTables/OverHeads';
import Salaries from './DataTables/Salaries';
import AnalyticsDashboardChart from './AnalyticsDashboard/AnalyticsDashboardChart';
import Accounts from './DataTables/Accounts';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { IoMdArrowBack } from "react-icons/io";
import Resources from './DataTables/Resources';
import { useDispatch } from 'react-redux';
import { setBranchVisible } from '../Actions/branchActions';
import {AccessDenied} from '../global-component';


const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

export default function HRDashboard() {

  const navigate = useNavigate();

  const [value, setValue] = React.useState('1');
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setBranchVisible(true))
  }, [])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (roleobj?.role === "Director" || roleobj?.role === "Finance" || roleobj?.role === "HR") {
    return (
      <Box sx={{ width: '100%', typography: 'body1' }}>
        <Button onClick={() => { navigate("/") }}><IoMdArrowBack color='#000' size={25} /></Button>
        <TabContext value={value}>
          <Box px={'30px'} >
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Employees" value="1" />
              <Tab label="Project Resources" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1"><Salaries /></TabPanel>
          <TabPanel value="2"><Resources /></TabPanel>
        </TabContext>
      </Box>
    );
  }
  else {
    return <AccessDenied/>
  }

}
