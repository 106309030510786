import React, { useState, useEffect, Fragment } from "react";
import Highcharts from "highcharts";
import highchartsNoData from "highcharts/modules/no-data-to-display";
import HighchartsExporting from "highcharts/modules/exporting";
import BarChartIcon from "@mui/icons-material/BarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
}
interface Props {
  id?: string;
  className?: string;
  stackingColumns?: boolean;
  dataSource: any[];
  title?: string;
  categories?: any[];
  chartType?: string;
  exporting?: boolean;
  height?: string;
  yAxisTitle?: string;
  legend?: boolean;
  toolTipName?: string;
}

export const PieChart: React.FC<Props> = ({
  id = "",
  className = "",
  dataSource = [],
  title = "",
  categories = [],
  exporting = false,
  height,
  yAxisTitle = "Values",
  legend = true,
  toolTipName = "",
}: Props) => {
  const [chartId] = useState(Math.round(Math.random() * 1000));
  const [labelsEnabled, setLabelsEnabled] = useState<boolean>(legend);

  useEffect(() => {
    try {
      highchartsNoData(Highcharts);
      let options: any //Highcharts.Options;
      options = {
        chart: {
          type: "pie",
          height: height,
        },
        title: {
          text: title || "",
        },
        xAxis: {
          labels: {
            style: {
              textOverflow: "none",
            },
          },
          categories: categories,
        },
        yAxis: {
          title: {
            text: yAxisTitle,
          },
        },
        // legend: {
        //   enabled: legend,
        // },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        tooltip: {
          pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
        },

        plotOptions: {
          series: {
            dataLabels: [
              {
                enabled: labelsEnabled,
                distance: 20,
                format: "{point.name} ({point.y})"
              },
              {
                enabled: labelsEnabled,
                distance: -40,
                format: "{point.percentage:.1f}%",
              },
            ],
          },
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            // dataLabels: {
            //   enabled: labelsEnabled,
            //   format: "{point.name} ({point.y})",
            // },
            showInLegend: true,
          },
          //   pie: {
          //     allowPointSelect: true,
          //     cursor: "pointer",
          //     dataLabels: {
          //       enabled: labelsEnabled,
          //       format: '{point.name} ({point.y})'
          //     },
          //     showInLegend: true,
          //   },
        },
        lang: {
          noData: "No data to display!",
        },
        noData: {
          position: {
            x: 0,
            y: 0,
            align: "center",
            verticalAlign: "middle",
          },
        },
        series: dataSource,
        exporting: {
          enabled: exporting,
          buttons: {
            contextButton: {
              menuItems: [
                "viewFullscreen",
                "printChart",
                //'downloadPNG',
                "downloadJPEG",
                "downloadPDF",
                //'downloadSVG',
                "downloadCSV",
                // "downloadXLS",
              ],
            },
          },
        },
        credits: {
          enabled: false,
        },
      };

      Highcharts.chart(`pie_chart_container-${chartId}`, options);
      return;
    } catch (error) {
      throw new Error(
        `Chart Component Failed component failed :: ${JSON.stringify(error)}`
      );
    }
  }, [chartId, title, labelsEnabled, categories, dataSource]);

  return (
    <div
      className={`highchart-custom-container ${className} `}
      id={`pie_chart_${chartId}`}
    >
      <div className="highchart-btns">
        <fieldset>
          <input
            name="chart"
            id={`label-${chartId}_id`}
            type="checkbox"
            checked={labelsEnabled}
            onClick={() => setLabelsEnabled(!labelsEnabled)}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <label htmlFor={`label-${chartId}_id`}>Label</label>
        </fieldset>
      </div>
      <div className="highchart-content">
        <div id={`pie_chart_container-${chartId}`} />
      </div>
    </div>
  );
};
