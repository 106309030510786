export const EXPENSE_CATEGORY = [
    {label: 'Variable', value: 'Variable'},
    {label: 'Fixed', value: 'Fixed'}
]

export const BILLABLE = [
    {label: 'Yes', value: 'Yes'},
    {label: 'No', value: 'No'}
]

export const STATUS = [
    {label: 'Not Paid', value: 'Not Paid'},
    {label: 'Paid', value: 'Paid'},
    {label: 'Partially Paid', value: 'Partially Paid'}
]