import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogActions,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import { FaEdit, FaPlus } from "react-icons/fa";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import axios from "../../utils/axios";
import { Stack, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";



const InsertCollection: React.FC<any> = ({ updateRefresh, onClose }) => {
  const branch = useSelector((state: any) => state.branch);
  const [open, setOpen] = useState(false);

  const [clientId, setClientId] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [payDate, setPayDate] = useState<string>(new Date().toISOString().split('T')[0]);
  const [tax, setTax] = useState("");
  const [narration, setNarration] = useState("");
  const [transId, setTransId] = useState("");
  const [isTrans, setIsTrans] = useState(false);
  const [chequeNo, setChequeNo] = useState("");
  const [isBank, setIsBank] = useState(false);
  const [bankName, setBankName] = useState("");
  const [isCheque, setIsCheque] = useState(false);

  const [allClients, setClients] = useState([]);
  const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

  const handleType = (e: any) => {
    let type = e.target.value;

    setType(type);

    type === "Online" ? setIsTrans(true) : setIsTrans(false);
    type === "Cash" ? setIsBank(true) : setIsBank(false);
    type === "Cheque" ? setIsCheque(true) : setIsCheque(false);
  }

  const handleClientID = (e: any) => {
    setClientId(e.target.value);
  };

  const handleClose = () => setOpen(false);

  const handleInsert = async () => {
    const response = await axios.post(`/insert-collection`, {
      "payment_date": payDate,
      "to_id": branch.branch,
      "client_id": clientId,
      "amount": amount,
      "tax": tax,
      "bank_name": bankName,
      "transaction_id": transId,
      "cheque_no": chequeNo,
      "payment_type": type,
      "narration": narration
      // "bill_number": billNum
    });

    console.log(response);

    toast.success(`Successfully Inserted!`, {
      position: "top-right",
      autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    updateRefresh(true);
    onClose();
  };

  useEffect(() => {
    const getAllClients = async () => {
      const response: any = await axios.post(`/get-all-clients-id`, { branch: branch.branch });
      setClients(response.data.data.rows);
    };

    getAllClients();
  }, [branch.branch]);

  const handleinsertopen = () => {
    setOpen(true);
  };

  const theme = useTheme();
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <div>
      <Dialog
        open
        onClose={onClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // maxWidth='xl'
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"100%"}>
              <div>
                <h2>Create collection</h2>
              </div>
              <div>
                <h2>{localStorage.getItem('cur_branch')}</h2>
              </div>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <form>
            <Stack
              direction={isMdUp ? 'row' : 'column'}
              sx={{
                width: isMdUp ? 1200 : '100%',
                borderRadius: 2,
                bgcolor: "background.paper",
                p: 3,
              }}
            >
              <Box
                sx={{
                  width: isMdUp ? 400 : '100%',
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0
                }}
              >
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Payment Date"
                  type="date"
                  value={payDate}
                  onChange={(e) => { setPayDate(e.target.value) }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Client</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Client Name"
                    value={clientId}
                    onChange={handleClientID}
                    fullWidth
                  >
                    <MenuItem value=""></MenuItem>
                    {allClients.map((client: any) => (
                      <MenuItem key={client.id} value={client.id}>
                        {client.brand_and_branch}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Principal amount"
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="TDS amount"
                  type="number"
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Gross amount"
                  type="number"
                  value={Number(amount) + Number(tax)}
                  InputProps={{
                    readOnly: true
                  }}
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Type/ Mode</InputLabel>
                  <Select onChange={handleType} value={type} label="Type/ Mode" >
                    <MenuItem value="Cash">Cash</MenuItem>
                    <MenuItem value="Cheque">Cheque</MenuItem>
                    <MenuItem value="Online">Online</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Transaction Id"
                  type="string"
                  value={transId}
                  onChange={(e) => { setTransId(e.target.value) }}
                  style={{ display: isTrans ? 'block' : 'none' }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Bank name"
                  type="string"
                  value={bankName}
                  onChange={(e) => { setBankName(e.target.value) }}
                  style={{ display: isBank ? 'block' : 'none' }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Cheque number"
                  type="string"
                  value={chequeNo}
                  onChange={(e) => { setChequeNo(e.target.value) }}
                  style={{ display: isCheque ? 'block' : 'none' }}
                />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Narration"
                  type="string"
                  value={narration}
                  onChange={(e) => { setNarration(e.target.value) }}
                  multiline
                  rows={4}
                />
              </Box>
              <Box
                sx={{
                  width: isMdUp ? 400 : '100%',
                  borderTopRightRadius: 10,
                  borderBottomRightRadius: 10,
                  bgcolor: "background.paper",
                  p: 3,
                  marginX: isMdUp ? 2 : 0
                }}
              >
              </Box>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Create</Button>
          <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsertCollection;
