import {useEffect, useState} from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';
import Calendar from '../Components/Calender';
import { MuiThemeProvider } from 'material-ui/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './CalenderTabs.css'
import Container from '@mui/material/Container'
// import useEffect from 'react';

interface TabPanelProps {
    value: number;
    index: number;
    children: React.ReactNode;
}

function TabPanel({ value, index, children }: TabPanelProps) {
    return (
        <div hidden={value !== index}>
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}



// export default useBodyClass;
const CalenderTabs = () => {
    const [value, setValue] = useState<any>(0);
    const location = useLocation();
    const navigate = useNavigate();
    const { title, room_id } = location.state || {};

    useEffect(() => {
        const { body } = document;
        if (body) {
            body.classList.add('no-scroll');
        }

        return () => {
            if (body) {
                body.classList.remove('no-scroll');
            }
        };
    }, ['no-scroll']);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const handleBackButton = () => {
        navigate('/home')
    };
    return (

        <Container maxWidth="xl">

            <h1 style={{ textAlign: 'center', position: "relative" }}><ArrowBackIcon className='back-button' style={{ position: 'absolute', left: "0", top: "12px" }} onClick={handleBackButton} />{title}</h1>
            <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                <Tabs value={value} onChange={handleChange} className='booking-tabs' centered>
                    <Tab label="All Booking" />
                    <Tab label="My Booking" />
                    <Tab label="Pending Booking" />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                {/* Component to render inside the first tab */}
                <MuiThemeProvider>
                    <Calendar room_title={title} room_id={room_id} data_type={'all_booking'} />
                </MuiThemeProvider>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {/* Component to render inside the first tab */}
                <MuiThemeProvider>
                    <Calendar room_title={title} room_id={room_id} data_type={'my_booking'} />
                </MuiThemeProvider>
            </TabPanel>
            <TabPanel value={value} index={2}>
                {/* Component to render inside the first tab */}
                <MuiThemeProvider>
                    <Calendar room_title={title} room_id={room_id} data_type={'pending'} />
                </MuiThemeProvider>
            </TabPanel>

        </Container>
    )
}

export default CalenderTabs