import { useEffect, useState } from 'react'
import Card from './Card'
import './Home.css';
import axios from '../utils/axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Grid } from '@mui/material';
import LoginRedirect from './LoginRedirect';

const token = localStorage.getItem('access_token');
const headers = {
  authorization: token ? `${token}` : '',
  'Content-Type': 'application/json',
};

const Home = () => {
  const [cardData, setCardData] = useState<any[] | []>([]);
  const { isAuthenticated } = useSelector((state: any) => state.user)
  const [redirected, setRedirected] = useState(false);
  const [defaultView, setDefaultView] = useState<any>(window.innerWidth < 768 ? 12:6);
  const navigate = useNavigate();
  // const {data} = useSelector((state:any) => state.user)
  // console.log("header", data)
  useEffect(() => {

    getCardsData()
  }, [])

  const getCardsData = async () => {
    try {
      // console.log("token", token);
      const response = await axios.get('/get-card-data', { headers });
      // console.log("res getCardsData data", response);
      if (response.data) {
        const getCardsData = response?.data?.data;
        // console.log("res getCardsData data", getCardsData);
        setCardData(getCardsData);
      }
    } catch (error) {
      console.log('API Error:', error);
      // Handle error state or display an error message to the user
    }
  };

  // console.log("cardData", cardData)
  return (

    <>
    {isAuthenticated  ? (
      <>
      <h1 style={{ textAlign: "center" }}><u>Conference Rooms</u></h1>
      <div className='card-container'>
        <Container >
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {cardData?.map((card: any) => (

              <Grid item xs={defaultView}>
                <Card
                  id={card && card.id}
                  room_id={card && card.conf_room_id}
                  title={card && card.conf_room_name}
                  description={card && card.description}
                  location={card && card.location}
                  imageUrl={card && card.imageUrl}
                  seating_capacity={card && card.seating_capacity}
                  wall_projector={card && card.wall_projector}
                  conferencing_phone={card && card.conferencing_phone}
                  lan_conn_count={card && card.lan_conn_count}
                  power_socket_count={card && card.power_socket_count}
                  wi_fi={card && card.wi_fi}
                  television={card && card.television}
                  hdmi_connectivity={card && card.hdmi_connectivity}
                  presentation_pointer={card && card.presentation_pointer}
                  url={card && card.url}
                />
              </Grid>
            ))}
          </Grid>
        </Container>
      </div>
      </>
      ) : (
        <LoginRedirect/>
      )}
    </>

  )
}

export default Home