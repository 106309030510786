import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from "react";
import * as Utils from "../core/utility";
import {
  LocalizationProvider,
  MobileDatePicker,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const methods: Utils.Methods = new Utils.Methods();

interface Props {
  label: string;
  className?: string;
  sx?: number;
  format?: string;
  minDate?: string;
  maxDate?: string;
  defaultValue?: string;
  name?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
  readOnly?: boolean;
  showFormat?: boolean;
  size?: "small" | "medium";
  views?: ('year'| 'month'| 'day')[]
}

const outPutDateFormat = "YYYY-MM-DD";

export const MBDatePicker: React.FC<Props> = ({
  label,
  className = "",
  sx = 0,
  format = "DD-MM-YYYY",
  minDate = "",
  maxDate = "",
  defaultValue = new Date().toString(),
  name = "",
  onChange,
  disabled = false,
  readOnly = false,
  size = "medium",
  showFormat = false,
}: Props) => {
  const [random] = useState(methods.getRandNumber);
  const [date, setDate] = useState(() =>
    defaultValue ? defaultValue : new Date().toString()
  );

  useEffect(() => {
    if (onChange) {
      onChange(dayjs(date).format(outPutDateFormat));
    }
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <MobileDatePicker
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        sx={{ mt: sx }}
        label={label}
        format={format}
        minDate={dayjs(minDate)}
        maxDate={dayjs(maxDate)}
        defaultValue={dayjs(defaultValue)}
        name={name}
        onChange={(event) => setDate(dayjs(event).format("LL"))}
        slotProps={{
          textField: {
            helperText: showFormat ? format : null,
            size: size,
          },
        }}
      />
    </LocalizationProvider>
  );
};

export const DesktopDatePicker: React.FC<Props> = ({
  label,
  className = "",
  sx = 0,
  format = "DD-MM-YYYY",
  minDate = "",
  maxDate = "",
  defaultValue = new Date().toString(),
  name = "",
  onChange,
  disabled = false,
  readOnly = false,
  size = "medium",
  showFormat = false,
  views = ['year', 'month', 'day']
}: Props) => {
  const [random] = useState(methods.getRandNumber);
  const [date, setDate] = useState(() =>
    defaultValue ? defaultValue : new Date().toString()
  );

  useEffect(() => {
    if (onChange) {
      onChange(dayjs(date).format(outPutDateFormat));
    }
  }, [date]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        disabled={disabled}
        readOnly={readOnly}
        className={className}
        sx={{ mt: sx }}
        label={label}
        format={format}
        minDate={dayjs(minDate)}
        maxDate={dayjs(maxDate)}
        defaultValue={dayjs(defaultValue)}
        views={views}
        name={name}
        onChange={(event) => setDate(dayjs(event).format("LL"))}
        slotProps={{
          textField: {
            helperText: showFormat ? format : null,
            size: size,
          },
        }}
      />
    </LocalizationProvider>
  );
};
