import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { toast } from "react-toastify";
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';


import axios from "../../utils/axios";

interface EditAccountProps {
  employee_designation:string;
  employee_salary:string;
  employee_email:string;
  employee_name:string;
  lwd:string;
  doj:string;
  status:string;
  id: number;

}


const EditSalaries: React.FC<EditAccountProps> = ({
  employee_designation,
  employee_salary,
  employee_email,
  lwd,
  doj,
  status,
  employee_name,
  id
}) => {
  const [open, setOpen] = useState(false);
  const [salary, setSalary] = useState(employee_salary);
  const [Name, setName] = useState(employee_name);
  const [email, setEmail] = useState(employee_email);
  const [Status, setStatus] = useState(status);
  const [designation, setDesignation] = useState(employee_designation);
  const [Doj, setDoj] = useState(doj);
  const [Lwd, setLwd] = useState(lwd);

  const [allDesignation, setAllDesignation] = useState([]);

  const handleStatusChange = (e: any) => {
    const newStatusSelected = e.target.value; // This is the id of the selected bd_team
    console.log(newStatusSelected);
    setStatus(newStatusSelected);
  }

  const handleDesignationChange = (e: any) => {
    const newDesignationSelected = e.target.value; // This is the id of the selected bd_team
    console.log(newDesignationSelected);
    setDesignation(newDesignationSelected);
  }

 

  //   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // take name,id from token

  const handleInsert = async () => {

    const response: any = await axios.post(`/update-employee`, {
      "employee_name": Name,
      "employee_email": email, "employee_designation": designation, "employee_salary": salary, "doj": Doj, "lwd": Lwd, "status": Status, "id": id
    })

    console.log(response);

    toast.success(`Successfully Inserted!`, {
      position: 'top-right',
      autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setOpen(false);
    window.location.reload();
  }

  useEffect(() => {

    const getAllDesignations = async () => {
      const response: any = await axios.post(`/get-designations`, {})
      console.log(response.data);
      setAllDesignation(response.data.designations);
    }

    getAllDesignations();
  }, [])

  const handleinsertopen = () => {
    setOpen(true);
  }

  return (
    <div>
      <IconButton color="primary" style={{ height: '10px' }}>
        <EditIcon onClick={handleinsertopen} />
      </IconButton>
      <Modal
        open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ width: 400, borderRadius: 2, bgcolor: 'background.paper', p: 3 }}>
          <h1 style={{ marginBottom: 30 }}>Edit employee</h1>
          <form>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="lead-label">Designation</InputLabel>
              <Select labelId="lead-label" label="Employee" value={designation} onChange={handleDesignationChange}>
                <MenuItem value=""></MenuItem>
                {
                  allDesignation.map((desig: any) => (
                    <MenuItem key={desig.designation} value={desig.designation} >{desig.designation}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Salary"
              type="number"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
            />
            <FormControl fullWidth sx={{ mt: 2 }}>
             
                <TextField label="Date of Joining" type="date" value={Doj}
                  onChange={() =>setDoj(Doj) } />
          
              
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
             
                <TextField label="Last Working Day" type="date" value={Lwd}
                 onChange={()=>setLwd(Lwd)} />
           
            </FormControl>
            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel id="lead-label">User</InputLabel>
              <Select labelId="lead-label" label="Status" value={Status} onChange={handleStatusChange}>
                <MenuItem key={"Active"} value={"Active"} >{"Active"}</MenuItem>
                <MenuItem key={"Inactive"} value={"Inactive"} >{"Inactive"}</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Edit</Button>
              <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSalaries;


