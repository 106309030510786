import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {ExpenseClientDetail} from '../../types/finance';
// import CurrentBilling from "../Analytics/Finance/CurrentBilling";
// import { LocalService } from "../../core/service";
// import { HttpService } from "../../core/service";
// import { base_url } from '../../constants';

import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogProps,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  // FormLabel,
  useTheme,
  Autocomplete,
  Checkbox,
} from "@mui/material";
import { toast } from "react-toastify";
// import { FaPlus } from "react-icons/fa";

import axios from "../../utils/axios";
import { Stack, useMediaQuery } from "@mui/system";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import dayjs from "dayjs";
import { ExpensesService } from "../../core/service";
import * as Util from '../../core/utility'

const expensesService: ExpensesService = new ExpensesService();
const methods: Util.Methods = new Util.Methods();

const InsertBilling: React.FC<any> = ({ onClose, updateRefresh }) => {
  // const http = new HttpService();

  const branch = useSelector((state: any) => state.branch);
  const [ClientID, setClientId] = useState("");
  const [leadId, setLeadId] = useState("");
  const [leadName, setLeadName] = useState("");
  const [clientGST, setClientGST] = useState("");
  const [amount, setAmount] = useState("");
  const [igst, setigst] = useState("");
  const [sgst, setsgst] = useState("");
  const [cgst, setcgst] = useState("");
  const [invDate, setInvDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );
  const [expenseIds, setExpenseId] = useState<(string | number)[]>([]);
  const [expenses, setExpenses] = useState<ExpenseClientDetail[]>([]);
  const [invNum, setInvNum] = useState("");
  const [narration, setNarration] = useState("");
  const [reimbursible, setReimbursible] = useState("Normal");
  const [invoiceId, setInvoiceId] = useState(0);
  const [companyGst, setCompanyGst] = useState("");
  const [sameState, setSameState] = useState(0);
  const [gstDeduction, setGstDeduction] = useState(0);
  const [allClients, setClients] = useState([]);
  const [scroll] = useState<DialogProps["scroll"]>("paper");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    expensesService.getClientExpenses(ClientID).then((res) => {
      if(res.status > 0){
        setExpenses(res.data.rows);
      }
    })
  }, [ClientID]);

  function handleAutoComplete (values: ExpenseClientDetail[]) {
    setExpenseId(values.map(val => val.id));
  }

  const handleClientID = (e: any) => {
    setClientId(e.target.value);

    let lead: any = allClients.find(
      (client: any) => client.id == e.target.value
    );
    setLeadId(lead.lead_id);
    setLeadName(lead.lead_name);
    setClientGST(lead.gst);
    setCompanyGst(lead.company_gst);

    if (companyGst.substring(0, 2) === clientGST.substring(0, 2)) {
      setSameState(1);
    } else {
      setSameState(2);
    }
  };

  const handleReimbursement = (e: any) => {
    setReimbursible(e.target.value);
  };

  function getFiscalYear(date: Date) {
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based in JavaScript

    let fiscalYearStart;
    let fiscalYearEnd;

    if (month >= 4 && month <= 12) {
      // For April to December
      fiscalYearStart = year.toString().slice(-2);
      fiscalYearEnd = (year + 1).toString().slice(-2);
    } else {
      // For January to March
      fiscalYearStart = (year - 1).toString().slice(-2);
      fiscalYearEnd = year.toString().slice(-2);
    }

    return fiscalYearStart + fiscalYearEnd;
  }

  const generateInvoiceNumber = (invoice_id: string, branch: number) => {
    const currentDate = Date.now();
    const date = new Date(currentDate);

    const day = date.getDate().toString().padStart(2, "0");
    const fiscalYear = getFiscalYear(date);
    let id = reimbursible === "Reimbursement" ? "R" + invoice_id : invoice_id;
    let branchCode;

    switch (branch) {
      case 1:
        branchCode = "V360M";
        break;
      case 2:
        branchCode = "V360B";
        break;
      case 3:
        branchCode = "V360D";
        break;
      default:
        branchCode = "V360M";
        break;
    }

    let generatedInvoice;
    if (reimbursible === "Reimbursement") {
      generatedInvoice = `${branchCode}${day}${fiscalYear}-${id}`;
    } else {
      generatedInvoice = `${branchCode}${day}-${fiscalYear}-${id}`;
    }

    setInvNum(generatedInvoice);
  };

  const handleGstDeduction = (e: any) => {
    setGstDeduction(Number(e.target.value));
  };

  // const handleKeyDown = (e: any, nextId: any) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault(); // Prevent the default form submission on Enter
  //     const nextElement = document.getElementById(nextId);
  //     if (nextElement) {
  //       nextElement.focus();
  //     }
  //   }
  // };

  const handleInsert = async () => {
    setIsLoading(true);
    axios
      .post(`/create-update/billing`, {
        company_id: branch.branch,
        client_id: ClientID,
        amount: amount,
        lead_id: leadId,
        invoice_date: invDate,
        invoice_no: invNum,
        igst: Number(igst),
        cgst: Number(cgst),
        sgst: Number(sgst),
        narration: narration,
        invoice_type: reimbursible,
        expensesIds: expenseIds
      })
      .then((resp) => {
        if (resp.data.status > 0) {
          toast.success(`Successfully Inserted!`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          updateRefresh(true);
          onClose();
        } else {
          toast.warning(`Please check all the details carefully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      })
      .catch((err) => {
        console.error("Insert billing error :: ", err);
        toast.error(`Service Unavailable!`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    // const resp = await axios.post(`/increment-invoice-id`, { branch: branch.branch })
  };

  useEffect(() => {
    const getAllClients = async () => {
      const response: any = await axios.post(`/get-all-clients-id`, {
        branch: branch.branch,
      });
      setClients(response.data.data.rows);
    };

    const getCurrentInvoiceId = async () => {
      const response: any = await axios.post(`/get-current-invoice-id`, {
        branch: branch.branch,
      });

      if (response.data.fetched) {
        console.log(response.data);
        setInvoiceId(response.data.id[0].id);
      }
    };

    getAllClients();
    getCurrentInvoiceId();
  }, [branch.branch]);

  useEffect(() => {
    generateInvoiceNumber(invoiceId.toString(), branch.branch);
  }, [invoiceId, reimbursible, branch.branch]);

  useEffect(() => {
    if (sameState === 1 && amount) {
      const gst = Math.round(Number(amount) * (gstDeduction / 100));
      setigst(gst.toString());
    } else if (sameState === 2 && amount) {
      const gst = Math.round((Number(amount) * (gstDeduction / 100)) / 2);
      setsgst(gst.toString());
      setcgst(gst.toString());
    }
  }, [gstDeduction, amount]);

  // const handleinsertopen = () => {
  // };

  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div>
      <Dialog
        open
        onClose={onClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        // maxWidth='xl'
        fullScreen
      >
        <DialogTitle id="scroll-dialog-title">
          <Stack
            direction={isMdUp ? "row" : "column"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <div>
              <h2>Create Invoice</h2>
            </div>
            <div>
              <h2>{localStorage.getItem("cur_branch")}</h2>
            </div>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack
            direction={isMdUp ? "row" : "column"}
            spacing={2}
            sx={{
              width: isMdUp ? 1200 : `100%`,
              borderRadius: 2,
              bgcolor: "background.paper",
              p: 3,
            }}
          >
            <Box
              sx={{
                width: isMdUp ? 400 : `100%`,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                bgcolor: "background.paper",
                p: 3,
                marginX: isMdUp ? 2 : 0,
              }}
            >
              <FormControl fullWidth>
                <RadioGroup
                  defaultValue="Normal"
                  value={reimbursible}
                  onChange={handleReimbursement}
                  name="radio-buttons-group"
                >
                  <Stack direction={"row"}>
                  {/* <FormControlLabel
                      value={"Operational"}
                      control={<Radio />}
                      label="Operational"
                    /> */}
                    <FormControlLabel
                      value={"Reimbursement"}
                      control={<Radio />}
                      label="Reimbursement"
                    />
                    <FormControlLabel
                      value={"Normal"}
                      control={<Radio />}
                      label="Retainer"
                    />
                  </Stack>
                </RadioGroup>
              </FormControl>
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Invoice Date"
                type="date"
                value={invDate}
                onChange={(e) => {
                  setInvDate(e.target.value);
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Invoice Number"
                type="string"
                value={invNum}
                onChange={(e) => {
                  setInvNum(e.target.value);
                }}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="lead-label">Client</InputLabel>
                <Select
                  labelId="lead-label"
                  label="Client"
                  value={ClientID}
                  onChange={handleClientID}
                  fullWidth
                >
                  {allClients.map((client: any) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.brand_and_branch}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Autocomplete
              sx={{ mt: 2 }}
              id="expense_id"
              className="w-100"
              limitTags={1}
              multiple={true}
              disableCloseOnSelect={true}
              options={expenses}
              value={methods.filterArray(expenseIds, expenses, 'id')}
              onChange={(event, value) =>
                handleAutoComplete(value)
              }
              getOptionLabel={(option) => option.invoice_no}
              renderOption={(props, option, { selected }) => (
                <li {...props} key={"invoice-list" + option.invoice_no}>
                  <Checkbox
                    icon={<CheckBoxOutlineBlank  />}
                    checkedIcon={<CheckBox  />}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.invoice_no}&nbsp;&nbsp;<strong className="date-box">{dayjs(option.invoice_date).format('DD, MMM, YYYY')}</strong>
                </li>
              )}
              renderInput={(params) => (
                <TextField {...params} label="Expenses" />
              )}
            />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Principal Amount"
                type="number"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
              />
              <FormControl fullWidth sx={{ mt: 2 }}>
                <InputLabel id="lead-label">GST</InputLabel>
                <Select
                  labelId="lead-label"
                  label="GST"
                  value={gstDeduction}
                  onChange={handleGstDeduction}
                  fullWidth
                >
                  <MenuItem value={0} key={0}>
                    0%
                  </MenuItem>
                  <MenuItem value={5} key={5}>
                    5%
                  </MenuItem>
                  <MenuItem value={18} key={18}>
                    18%
                  </MenuItem>
                  <MenuItem value={28} key={28}>
                    28%
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ mt: 2, display: sameState === 1 ? "block" : "none" }}
                fullWidth
                label="IGST"
                type="number"
                value={igst}
                onChange={(e) => setigst(e.target.value)}
              />
              <TextField
                sx={{ mt: 2, display: sameState === 2 ? "block" : "none" }}
                fullWidth
                label="SGST"
                type="number"
                value={sgst}
                onChange={(e) => setsgst(e.target.value)}
              />
              <TextField
                sx={{ mt: 2, display: sameState === 2 ? "block" : "none" }}
                fullWidth
                label="CGST"
                type="number"
                value={sgst}
                onChange={(e) => setcgst(e.target.value)}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Gross Amount"
                type="number"
                value={
                  Number(amount) + Number(igst) + Number(cgst) + Number(sgst)
                }
                inputProps={{
                  readonly: true,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                multiline
                rows={4}
                label="Narration"
                type="string"
                value={narration}
                onChange={(e) => setNarration(e.target.value)}
              />
            </Box>
            <Box
              sx={{
                width: isMdUp ? 400 : `100%`,
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                bgcolor: "background.paper",
                p: 3,
                marginX: isMdUp ? 2 : 0,
              }}
            >
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Team Lead"
                type="string"
                value={leadName}
                inputProps={{
                  readonly: true,
                  shrink: true,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Client GST"
                type="string"
                value={clientGST}
                inputProps={{
                  readonly: true,
                  shrink: true,
                }}
              />
              <TextField
                sx={{ mt: 2 }}
                fullWidth
                label="Company GST"
                type="string"
                value={companyGst}
                inputProps={{
                  readonly: true,
                  shrink: true,
                }}
              />
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            style={{ borderRadius: 5 }}
            onClick={handleInsert}
          >
            Create
          </LoadingButton>
          <Button
            variant="outlined"
            style={{ marginLeft: 10, borderRadius: 5 }}
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InsertBilling;
