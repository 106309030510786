import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import * as Util from "../utility";

export class ClientService {
  private https: HttpService;
  private methods: Util.Methods;

  constructor() {
    this.https = new HttpService();
    this.methods = new Util.Methods();
  }

  async ClientReview(start_date: string, end_date: string, branchId: number = 0) {
    const baseURL = BASE_URL + `/get/client-reviews?start_date=${start_date}&end_date=${end_date}&branchId=${branchId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async UpdateClientReview(data:Object) {
    const baseURL = BASE_URL + `/update/client-reviews`;
    try {
      let response = await this.https.postRequest(baseURL,data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }


  async ClientHealth(start_date: string, end_date: string, branchId: number = 0) {
    const baseURL = BASE_URL + `/get/client-health?start_date=${start_date}&end_date=${end_date}&branchId=${branchId}`;
    try {
      let response = await this.https.getRequest(baseURL, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }

  async UpdateClientHealth(data:Object) {
    const baseURL = BASE_URL + `/update/client-health`;
    try {
      let response = await this.https.postRequest(baseURL,data, null);
      return response;
    } catch (err) {
      throw new Error(`create lead service error  ==> ${err}`);
    }
  }


}
