import React, { useState } from "react";

const leads = [
  { lead: "lead1", startDate: "2023-11-01", endDate: "2024-01-21", won: 10000 },
  { lead: "lead2", startDate: "2023-09-21", endDate: "2024-03-18", won: 20000 },
  { lead: "lead3", startDate: "2023-10-01", lost: 15000, status: "Cold" },
  { lead: "lead4", startDate: "2024-01-11", lost: 18000, status: "Cold" },
];

function Test() {

    const currentDate = new Date();

    // One month ago
    const oneMonthAgoDate = new Date(currentDate);
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() - 1);
    const oneMonthAgoYear = oneMonthAgoDate.getFullYear();
    const oneMonthAgoMonth = String(oneMonthAgoDate.getMonth() + 1).padStart(2, '0');
    const oneMonthAgoDay = String(oneMonthAgoDate.getDate()).padStart(2, '0');
    const formattedOneMonthAgoDate = `${oneMonthAgoYear}-${oneMonthAgoMonth}-${oneMonthAgoDay}`;
    
    // Six months ago
    const sixMonthsAgoDate = new Date(currentDate);
    sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 6);
    const sixMonthsAgoYear = sixMonthsAgoDate.getFullYear();
    const sixMonthsAgoMonth = String(sixMonthsAgoDate.getMonth() + 1).padStart(2, '0');
    const sixMonthsAgoDay = String(sixMonthsAgoDate.getDate()).padStart(2, '0');
    const formattedSixMonthsAgoDate = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;
    
    console.log("One Month Ago Date:", formattedOneMonthAgoDate);
    console.log("Six Months Ago Date:", formattedSixMonthsAgoDate);
    

  const [startd, setstart] = useState(formattedSixMonthsAgoDate);
  const [endd, setend] = useState(formattedOneMonthAgoDate);
  const [row, setrow] = useState([]);

  const GetduplicatedRow = (start, end, leads) => {
    const startDate = new Date(start);
    const endDate = new Date(end);

    const result = [];
    const processedLeads = new Set();
    let cumulativeRevenue = 0;

    // Iterate over each month
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const month = currentDate.toLocaleString("default", { month: "long" });

      // Check each lead if its contract falls within the current month
      leads.forEach((lead) => {
        const leadStartDate = new Date(lead.startDate);
        let leadEndDate = lead.endDate ? new Date(lead.endDate) : null; // lost lead doesn't have end date

        // Shift end date to the next month
        if (leadEndDate) {
          leadEndDate = new Date(
            leadEndDate.getFullYear(),
            leadEndDate.getMonth() + 1,
            1
          );
        }

        // If the lead has an end date within the current month
        if (
          leadEndDate &&
          currentDate >= leadStartDate &&
          currentDate <= leadEndDate
        ) {
          cumulativeRevenue += lead.won || 0;
          result.push({
            month,
            lead: lead.lead,
            Periodstart: lead.startDate,
            Periodend: lead.endDate,
            Won: lead.won || "",
            Lost: "",
            "Current Revenue": cumulativeRevenue,
          });
        } else if (
          !leadEndDate &&
          currentDate.getMonth() === leadStartDate.getMonth() &&
          !processedLeads.has(lead.lead)
        ) {
          // If the lead doesn't have an end date and starts on the current month
          cumulativeRevenue -= lead.lost || 0;
          result.push({
            month,
            lead: lead.lead,
            Periodstart: lead.startDate,
            Periodend: "",
            Won: "",
            Lost: lead.lost || "Lost",
            "Current Revenue": cumulativeRevenue,
          });
          processedLeads.add(lead.lead);
        }
      });

      // Move to the next month
      currentDate.setMonth(currentDate.getMonth() + 1);

      // Check if we've reached the end date month
      if (
        currentDate.getFullYear() === endDate.getFullYear() &&
        currentDate.getMonth() === endDate.getMonth()
      ) {
        const month = currentDate.toLocaleString("default", { month: "long" });
        leads.forEach((lead) => {
          let leadEndDate = lead.endDate ? new Date(lead.endDate) : null;
          if (leadEndDate) {
            leadEndDate = new Date(
              leadEndDate.getFullYear(),
              leadEndDate.getMonth() + 1,
              1
            );
          }
          if (
            leadEndDate &&
            endDate >= new Date(lead.startDate) &&
            endDate <= leadEndDate
          ) {
            if (!processedLeads.has(lead.lead)) {
              cumulativeRevenue += lead.won || 0;
              result.push({
                month,
                lead: lead.lead,
                Periodstart: lead.startDate,
                Periodend: lead.endDate,
                Won: lead.won || "",
                Lost: "",
                "Current Revenue": cumulativeRevenue,
              });
              processedLeads.add(lead.lead);
            }
          }
        });
      }
    }

    console.table(result);
    return result;
  };

  const ApplyFilter = () => {
    setrow(GetduplicatedRow(startd, endd, leads));
  };

  return (
    <div>
      <h2>Month and Lead Table</h2>
      <input
        type="date"
        value={startd}
        onChange={(e) => setstart(e.target.value)}
      />
      <input
        type="date"
        value={endd}
        onChange={(e) => setend(e.target.value)}
      />
      <button onClick={ApplyFilter}>Apply</button>
      <table className="table">
        <thead>
          <tr>
            <th>Month</th>
            <th>Lead</th>
            <th>Contract Period</th>
            <th>Lost</th>
            <th>Won</th>
            <th>Current Revenue</th>
          </tr>
        </thead>
        <tbody>
          {row.map((item, index) => (
            <tr key={index}>
              <td>{item.month}</td>
              <td>{item.lead}</td>
              {item.Won === "" ? (
                <td> </td>
              ) : (
                <td>{`${item.Periodstart} To ${item.Periodend}`}</td>
              )}
              <td>{item.Lost}</td>
              <td>{item.Won}</td>
              <td>{item["Current Revenue"]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Test;
