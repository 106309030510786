import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { userReducer } from "../Reducer/User";
import branchReducer from "../Reducer/branchReducer";
import {LoaderReducer} from '../Reducer/branchReducer';
const initialState = {
}
const store = configureStore({
    reducer: combineReducers({
        user: userReducer,
        branch: branchReducer,
        loading: LoaderReducer
    }),
    preloadedState: initialState,
});

export default store;
