import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../utils/axios'
import { useDispatch, useSelector } from 'react-redux';
import { loginUserMeetingApproval } from '../Actions/User';
import './ApproveMeeting.css'
import ClearIcon from '@mui/icons-material/Clear';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from "material-ui/Dialog";
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { toast } from 'react-toastify';
import { Container, Grid } from '@mui/material';
import TextField from '@mui/material/TextField';

const token = localStorage.getItem('access_token');
const email = localStorage.getItem('email');
const headers = {
    authorization: token ? `${token}` : '',
    'Content-Type': 'application/json',
};
const ApproveMeeting = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state: any) => state.user)
    const [meetingData, setMeetingData] = useState<any[] | []>([]);
    const [disabled, setDisabled] = useState(false);
    const [slotBooked, setSlotBooked] = useState(false);
    const [statusRemarks, setStatusRemarks] = useState("");
    const [isValidRemarks, setIsValidRemarks] = useState("");
    const [approvedMeetingData, setApprovedMeetingData] = useState<any>();
    const searchParams = new URLSearchParams(location.search);
    // console.log("searchParams", searchParams)
    // Get values from query parameters
    const param1 = searchParams.get('bookingId');
    const param2 = searchParams.get('token');
    // console.log("searchParams", param1, param2)
    useEffect(() => {
        getData();


    }, []);

    const handleClose = () => {
        setSlotBooked(false)
    };

    const handleStatusRemarks = (event:any) => {
        setIsValidRemarks("")
        setStatusRemarks(event.target.value)
    }
     




    const getData = async () => {
        try {
            const response = await axios.get(`/approve-meeting?booking_id=${param1}&token=${param2}`);
            // console.log("res", response);

            const bookingData = response?.data?.getBookingData;
            if (bookingData) {
                setMeetingData(bookingData);
                // console.log("meetingData", bookingData);

                if (!isAuthenticated) {
                    dispatch(loginUserMeetingApproval(bookingData[0]?.approver_email));
                }

                if (bookingData[0]?.booking_status === 'Approved' || bookingData[0]?.booking_status === 'Rejected') {
                    //   console.log("hello button");
                    setDisabled(true);
                }
            }
        } catch (error) {
            console.log('getData_Error:', error);
            // Handle error appropriately (e.g., show an error message to the user)
        }
    };

    const handleApproveRequest = async (event: any) => {
        try {
            if(statusRemarks.trim() === ''){
                setIsValidRemarks("Not a valid Remark");
                return;
            }
            const buttonValue = event.target.value;
            // console.log(buttonValue);

            const user_email = meetingData[0]?.user_email;

            const response = await axios.post(`/meeting-status`, { buttonValue, meetingData, statusRemarks }, { headers });
            // console.log("response", response);

            if (response?.data?.message === 'Meeting status updated successfully and mail sent to user') {
                toast.success("Meeting Approved Successfully");
                navigate('/home');
            }

            if (response?.data?.message === "Slot already booked") {
                toast.error(response?.data?.message)
                // setSlotBooked(true);
                // setApprovedMeetingData(response?.data?.changeBookingStatus);
            }

            getData();
        } catch (error) {
            console.log('handleApproveRequest_Error:', error);
            // Handle error appropriately (e.g., show an error message to the user)
        }

    };

    return (
        <>

            <Container className='approve-meeting-container' maxWidth="sm">
                <h1>Meeting Details</h1>
                <div className='approve-meeting-detail'>
                    <ul>
                        <li><strong>Title </strong>: &nbsp; {meetingData[0]?.title}</li>
                        <li><strong>Description </strong>: &nbsp; {meetingData[0]?.meeting_description}</li>
                        <li><strong>Conference Room Name </strong>: &nbsp; {meetingData[0]?.conf_room_name}</li>
                        <li><strong>Location </strong>: &nbsp; {meetingData[0]?.location}</li>
                        <li><strong>Date </strong>: &nbsp;  {moment(meetingData[0]?.start).format("MMMM Do YYYY")}</li>
                        <li><strong>Time </strong>: &nbsp; {moment(meetingData[0]?.start).format('h:mm A')} - {moment(meetingData[0]?.end).format('h:mm A')}</li>
                        <li><strong>Booked By</strong>: &nbsp; {meetingData[0]?.user_email}</li>
                        <li><strong>Meeting Type </strong>: &nbsp; {meetingData[0]?.meeting_type}</li>
                        <li><strong>Remarks </strong>: &nbsp; {meetingData[0]?.remarks}</li>
                        <li><strong>Status </strong>: &nbsp; {meetingData[0]?.booking_status}</li>
                    </ul>
                    <TextField
                            sx={{ width: '55%' }}
                            id="outlined-basic"
                            label="Status Remarks"
                            variant="outlined"
                            value={statusRemarks}
                            onChange={handleStatusRemarks}
                            required
                        />
                        <p style={{ color: "red" }}>{isValidRemarks}</p>
                    <div className='approve_meeting_button-container'>
                        <Button variant="contained" size="large" value="Rejetced" color='error' onClick={handleApproveRequest}>Reject
                        </Button>
                        <Button variant="contained" size="large" value="Approved" onClick={handleApproveRequest}>Approve
                        </Button>


                    </div>
                </div>
                <MuiThemeProvider>
                    <Dialog
                        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <li>Slot Already Booked</li><ClearIcon onClick={handleClose}
                                className="clear-icon" />
                        </div>
                        }

                        open={slotBooked}
                        onRequestClose={handleClose}

                    >
                        <ul>
                            <li><strong>Title </strong>: &nbsp; {approvedMeetingData?.title}</li>
                            <li><strong>Description </strong>: &nbsp; {approvedMeetingData?.meeting_description}</li>
                            <li><strong>Conference Room Name </strong>: &nbsp; {meetingData[0]?.conf_room_name}</li>
                            <li><strong>Location </strong>: &nbsp; {meetingData[0]?.location}</li>
                            <li><strong>Date </strong>: &nbsp; {moment(approvedMeetingData?.start).format("MMMM Do YYYY")}</li>
                            <li><strong>Time </strong>: &nbsp; {moment(approvedMeetingData?.start).format('h:mm A')} - {moment(approvedMeetingData?.end).format('h:mm A')}</li>
                            <li><strong>Booked By</strong>: &nbsp; {approvedMeetingData?.user_email}</li>
                            <li><strong>Approved By</strong>: &nbsp; {approvedMeetingData?.approver_email}</li>
                            <li><strong>Meeting Type </strong>: &nbsp; {approvedMeetingData?.meeting_type}</li>
                            <li><strong>Remarks </strong>: &nbsp; {approvedMeetingData?.remarks}</li>
                            <li><strong>Status </strong>: &nbsp; {approvedMeetingData?.booking_status}</li>
                        </ul>
                        
                    </Dialog>
                </MuiThemeProvider>
            </Container>

        </>
    )
}

export default ApproveMeeting