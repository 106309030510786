import { createReducer} from '@reduxjs/toolkit'
const initialState = {
  isAuthenticated : false
}
export const userReducer = createReducer(initialState,{
    loginRequest:(state:any,action:any) => {
        state.loading = true;
    },
    loginSuccess:(state:any,action:any) => {
        state.loading = false;
        state.user = action.payload
        state.isAuthenticated = true;
    },
    loginFailure:(state:any,action:any) => {
        state.loading = false;
        state.error = action.payload
        state.isAuthenticated = false;
    },

    LoadUserRequest:(state:any,action:any) => {
        state.loading = true;
    },
    LoadUserSuccess:(state:any,action:any) => {
        state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
    },
    LoadUserFailure:(state:any,action:any) => {
        state.loading = false;
        state.error = action.payload;
        state.isAuthenticated = false;
    },
    LogOutRequest:(state:any,action:any) => {
      state.loading = true;
  },
    LogOutSuccess:(state:any,action:any) => {
      state.loading = false;
      state.user = null;
      state.isAuthenticated = false;
  },
  LogOutFailure:(state:any,action:any) => {
    state.loading = false;
        state.user = action.payload;
        state.isAuthenticated = true;
},
})