import React, { useState, useEffect } from 'react'
import CurrentRevenue from './CurrentRevenue';
import { Autocomplete, Button, Checkbox, TextField } from '@mui/material';
import { MBDatePicker } from '../../global-component';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';
import axios from "../../utils/axios";
import { LocalService } from '../../core/service';
import TotalRevenue from './TotalRevenue';
import { Stack } from '@mui/system';
import { jwtDecode } from 'jwt-decode';

const format = "YYYY-MM-DD";

const user_role: any = localStorage.getItem('access_token');

let roleobj: any = null;
if (user_role) {
  roleobj = jwtDecode(user_role);
}

const Accounts = () => {

  const localService: LocalService = new LocalService();

  const currentDate = new Date();
  const oneMonthAgoDate = new Date(currentDate);
  oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() + 3);
  const oneMonthAgoYear = oneMonthAgoDate.getFullYear();
  const oneMonthAgoMonth = String(oneMonthAgoDate.getMonth() + 1).padStart(2, '0');
  const oneMonthAgoDay = String(oneMonthAgoDate.getDate()).padStart(2, '0');
  const formattedOneMonthAgoDate = `${oneMonthAgoYear}-${oneMonthAgoMonth}-${oneMonthAgoDay}`;

  // Six months ago
  const sixMonthsAgoDate = new Date(currentDate);
  sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 3);
  const sixMonthsAgoYear = sixMonthsAgoDate.getFullYear();
  const sixMonthsAgoMonth = String(sixMonthsAgoDate.getMonth() + 1).padStart(2, '0');
  const sixMonthsAgoDay = String(sixMonthsAgoDate.getDate()).padStart(2, '0');
  const formattedSixMonthsAgoDate = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;

  const handleRevenue = () => {
    setDoApply(true);
  }

  const [startd, setstart] = useState(formattedSixMonthsAgoDate);
  const [endd, setend] = useState(dayjs().date.toString());
  const [teams, setTeams] = useState<any[]>([]);
  const [selectedTeams, setSelectedTeams] = useState<any[]>([]);
  const [doApply, setDoApply] = useState(false);

  useEffect(() => {
    const getAllTeams = async () => {
      axios.post(`/get-all-teams`, {}).then(
        (response: any) => {
          setTeams(response.data.data.rows);
        }
      ).catch((err) => {
        console.error("teams list error :: ", err)
        localService.toastify("Error fetching teams", "error", 2000);
      }).finally(() => {

      })
    }

    (roleobj?.role === "Director" || roleobj?.role === "Admin" ) && getAllTeams();
  }, []);

  return (
    <>
      <div className="expense-report-filter">
        <MBDatePicker
          defaultValue={formattedSixMonthsAgoDate}
          onChange={(e) => setstart(e)}
          className="w-100"
          size="small"
          label="From"
        />
        <MBDatePicker
          onChange={(e) => setend(e)}
          className="w-100"
          size="small"
          label="To"
          maxDate={dayjs().add(1, 'day').date.toString()}
        />
        {
          roleobj?.role === "Team Lead" ?
          <></> :
          <Autocomplete
            id="TEAM_id"
            className="w-100"
            limitTags={1}
            multiple={true}
            disableCloseOnSelect={true}
            options={teams}
            size="small"
            onChange={(event, value) =>
              setSelectedTeams(value)
            }
            getOptionLabel={(option) => option.user_name}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={"teams-filter" + option.user_id}>
                <Checkbox
                  icon={<CheckBoxOutlineBlank fontSize="small" />}
                  checkedIcon={<CheckBox fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.user_name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Teams" />
            )}
          />
        }
        <LoadingButton
          loading={false}
          loadingPosition="start"
          variant="contained"
          onClick={handleRevenue}
        >
          {" "}
          Apply
        </LoadingButton>
      </div>
      <div className='account-table'>
        <Stack>
          {
            teams &&
            <CurrentRevenue startd={startd} endd={endd} doApply={doApply} teams={roleobj?.role === "Team Lead" ? [{user_id: roleobj?.id, user_name: roleobj?.name}] : selectedTeams} setDoApply={setDoApply} />
          }
        </Stack>
      </div>
    </>
  )
}

export default Accounts
