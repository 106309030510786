import { SET_BRANCH, BRANCH_VISIBLE, LOADING } from "../Actions/branchActions";

const initialState = {
  branch: localStorage.getItem("branch_id") || 1,
  visible: true,
};

const intialLoader = {
  loading: false,
};

const branchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_BRANCH:
      localStorage.setItem("branch_id", action.payload);
      return {
        ...state,
        branch: action.payload,
      };
    case BRANCH_VISIBLE:
      return {
        ...state,
        visible: action.payload,
      };
    default:
      return state;
  }
};

export default branchReducer;

export const LoaderReducer = (state = intialLoader, action: any) => {
  switch (action.type) {
    case LOADING:
      return action.payload;
    default:
      return state.loading || false
  }
};


