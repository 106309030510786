export const Status = [
  { id: 1, label: "WIP", value: "wip" },
  { id: 2, label: "Cold", value: "cold" },
  { id: 3, label: "Hold", value: "hold" },
  { id: 4, label: "Pipeline", value: "pipeline" },
  { id: 5, label: "Lost", value: "lost" },
  { id: 6, label: "Converted", value: "converted" },
];

export const Mandate = [
  { id: 1, label: "Retainer", value: "retainer" }, // Monthly
  { id: 1, label: "Project", value: "project" }, // One Time
];

export const MandateFilter = [
  { id: 0, label: "All leads", value: "Retainer || Project" }, 
  { id: 1, label: "Retainer", value: "Retainer" },
  { id: 2, label: "Project", value: "Project" },
]

export const Source = [
  { id: 1, label: "Self generated", value: "self" },
  { id: 2, label: "Incoming", value: "incoming" },
  { id: 3, label: "Referral", value: "referral" },
  {id: 4, label: "Repitch", value: "repitch"}
];
