import React from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import AllExpense from './all';
import Billable from './billable';
import NonBillable from './non-billable';
import Variable from './variable';
import Fixed from './fixed';
import Operational from './operational';

export const Expenses: React.FC = () => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    return (
        <Box>
            <TabContext value={value}>
                <Box px={'30px'} >
                    <TabList
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="All" value="1" />
                        <Tab label="Billable" value="2" />
                        <Tab label="Non-Billable" value="3" />
                        <Tab label="Operational" value="6" />
                        <Tab label="Variable" value="5" />
                        <Tab label="Fixed" value="4" />
                    </TabList>
                </Box>
                <TabPanel value="1"><AllExpense /></TabPanel>
                <TabPanel value="2"><Billable /></TabPanel>
                <TabPanel value='3'><NonBillable /></TabPanel>
                <TabPanel value='4'><Fixed /></TabPanel>
                <TabPanel value='5'><Variable /></TabPanel>
                <TabPanel value='6'><Operational /></TabPanel>
            </TabContext>
        </Box>
    )
}