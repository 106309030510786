import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TableCell from "@mui/material/TableCell";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';


import { Stack, TextField, styled, useMediaQuery, useTheme } from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { useSelector } from "react-redux";

interface EditAccountProps {
    data: any
    updateRefresh: any;
}

interface VendorData {
    vendor_id: number;
    legal_name?: string;
    branch: string;
    brand_name: string;
    address: string;
    city: string;
    state: string;
    country: string;
    pin: string;
    pan: string;
    gst: string;
}

const initailForm: VendorData = {
    vendor_id: 0,
    legal_name: '',
    branch: '',
    brand_name: '',
    address: '',
    city: '',
    state: '',
    country: '',
    pin: '',
    pan: '',
    gst: ''
}

const VendorMaster: React.FC<EditAccountProps> = ({
    data, updateRefresh
}) => {
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [form, setForm] = useState<typeof initailForm>(initailForm);
    const branch = useSelector((state: any) => state.branch);

    useEffect(() => {
        if (data.vendor_id > 0 && open) {
            setForm(data);
        }
    }, [data, open])

    const handleEdit = async () => {

        try {
            const response = await axios.post(`/create-update/vendor`, {
                "vendor_id": form.vendor_id,
                "company_id": Number(branch.branch),
                "branch": form.branch,
                "address": form.address,
                "country": form.country,
                "state": form.state,
                "city": form.city,
                "pin": form.pin,
                "pan": form.pan,
                "gst": form.gst,
                "legal_name": form.legal_name,
                "brand_name": form.brand_name
            })

            if (response.data.status > 0) {
                toast.success(`Successfully Updated!`, {
                    position: 'top-right',
                    autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setOpen(false);
            }
        } catch (err) {
            console.log("API error ::: " + err);

            toast.error(`Please check all the entries carefully`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(true);
        }
        updateRefresh(true);

    }

    const handlePopup = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <TableCell>
                <IconButton color="primary" style={{ height: '8px' }}>
                    <InfoIcon onClick={handlePopup} />
                </IconButton>
            </TableCell>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                        <h2>{isEditing ? `Edit '${form.legal_name}'` : `'${form.legal_name}' Details`}</h2>
                        {!isEditing ? <IconButton color="primary" style={{ height: '8px' }}>
                            <EditIcon onClick={() => { setIsEditing(true) }} />
                        </IconButton> : <></>}
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <Stack
                        direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box
                            sx={{
                                width: isMdUp ? 600 : '100%',
                                minWidth: isMdUp ? 600 : '100%',
                                borderTopLeftRadius: 10,
                                borderBottomLeftRadius: 10,
                                bgcolor: "background.paper",
                                p: 3,
                                marginX: isMdUp ? 2 : 0
                            }}
                        >
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Legal Name"
                                type="text"
                                aria-readonly
                                value={form.legal_name}
                                onChange={(e) => setForm((prev) => ({ ...prev, legal_name: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Brand Name"
                                type="text"
                                value={form.brand_name}
                                onChange={(e) => setForm((prev) => ({ ...prev, brand_name: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Address"
                                type="text"
                                value={form.address}
                                onChange={(e) => setForm((prev) => ({ ...prev, address: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Country"
                                type="text"
                                value={form.country}
                                onChange={(e) => setForm((prev) => ({ ...prev, country: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="State"
                                type="text"
                                value={form.state}
                                onChange={(e) => setForm((prev) => ({ ...prev, state: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="City"
                                type="text"
                                value={form.city}
                                onChange={(e) => setForm((prev) => ({ ...prev, city: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Branch"
                                type="text"
                                value={form.branch}
                                onChange={(e) => setForm((prev) => ({ ...prev, branch: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Pincode"
                                type="text"
                                value={form.pin}
                                onChange={(e) => setForm((prev) => ({ ...prev, pin: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                                inputProps={{
                                    maxLength: 6
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="PAN Number"
                                type="text"
                                value={form.pan}
                                onChange={(e) => setForm((prev) => ({ ...prev, pan: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false,
                                }}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="GST"
                                type="text"
                                value={form.gst}
                                onChange={(e) => setForm((prev) => ({ ...prev, gst: e.target.value }))}
                                InputProps={{
                                    readOnly: !isEditing ? true : false
                                }}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                {isEditing ? <DialogActions>
                    <Button
                        variant="contained"
                        style={{ borderRadius: 5 }}
                        onClick={handleEdit}
                    >
                        Edit
                    </Button>
                    <Button
                        variant="outlined"
                        style={{ marginLeft: 10, borderRadius: 5 }}
                        onClick={() => { setIsEditing(false); setForm(data) }}
                    >
                        Cancel
                    </Button>
                </DialogActions> :
                    <DialogActions>
                        <Button
                            variant="outlined"
                            style={{ marginLeft: 10, borderRadius: 5 }}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                }
            </Dialog>
        </React.Fragment>
    );
};

export default VendorMaster;
