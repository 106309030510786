export const _ClientHealth = [
  {
    label: "Very satisfied",
    value: "Very satisfied",
  },
  {
    label: "Satisfied",
    value: "Satisfied",
  },
  {
    label: "Neutral",
    value: "Neutral",
  },
  {
    label: "Dissatisfied",
    value: "Dissatisfied",
  },
];

export const _ClientReview = [
  {
    label: "Done",
    value: "Done",
  },
  {
    label: "Pending",
    value: "Pending",
  }
];
