import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import axios from "../../utils/axios";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};


const InsertResource: React.FC<any> = ({ updateRefresh }) => {
    const [open, setOpen] = useState(false);
    // const [leadId, setLeadId] = useState<number>();
    const [employeeId, setEmployeeId] = useState<number>();
    const [Name, setName] = useState("");
    const [assingedDate, setAssingedDate] = useState<Date | null>(null);
    const [exitDate, setExitDate] = useState<Date | null>(null);
    const [userId, setUserId] = useState<number>();

    // const [allLeads, setAllLeads] = useState([]);
    const [allEmployees, setAllEmployees] = useState([]);
    const [allUser, setAllUsers] = useState([]);


    // const handleLeadIdChange = (e: any) => {
    //     const newSelectedLeadId = e.target.value; // This is the id of the selected bd_team
    //     console.log(newSelectedLeadId);
    //     const parsedOpstId = parseInt(newSelectedLeadId, 10);
    //     setLeadId(parsedOpstId);
    // }

    const handleEmployeeIdChange = (e: any) => {
        const newSelectedEmployeeId = e.target.value; // This is the id of the selected bd_team
        console.log(newSelectedEmployeeId);
        const parsedOpstId = parseInt(newSelectedEmployeeId, 10);
        setEmployeeId(parsedOpstId);
    }

    const handleUserIdChange = (e: any) => {
        const newSelectedUserId = e.target.value; // This is the id of the selected bd_team
        console.log(newSelectedUserId);
        const parsedOpstId = parseInt(newSelectedUserId, 10);
        setUserId(parsedOpstId);
    }

    const handleAssignDateChange = (date: Date | null) => {
        setAssingedDate(date);
    };

    const handleExitDateChange = (date: Date | null) => {
        setExitDate(date);
    };

    //   const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // take name,id from token

    const handleInsert = async () => {

        const response: any = await axios.post(`/create-resource`, {
            "user_id": userId, "employee_id": employeeId, "name": Name, "assinged_date": assingedDate?.toISOString().substring(0, 10), "exit_date": exitDate?.toISOString().substring(0, 10)
        })

        console.log(response);

        toast.success(`Successfully Inserted!`, {
            position: 'top-right',
            autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setOpen(false);
        updateRefresh(true);
    }

    useEffect(() => {

        // const getAllActiveContracts = async () => {
        //     const response: any = await axios.post(`/contracts/get-active-contracts`, {})
        //     console.log(response.data);
        //     setAllLeads(response.data.activeContracts);
        // }

        const getAllUsers = async () => {
            const response: any = await axios.post(`/get-all-managers-employees`, {})
            console.log(response.data);
            setAllUsers(response.data.managers);
        }

        const getAllEmployees = async () => {
            const response: any = await axios.post(`/get-all-team-employees`, {})
            console.log(response.data);
            setAllEmployees(response.data.teams);
        }

        // getAllActiveContracts();
        getAllUsers();
        getAllEmployees();

    }, [])

    const handleinsertopen = () => {
        setOpen(true);
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div>
            <Button variant="contained" onClick={handleinsertopen}>
                Insert a new Entry <FaEdit style={{ marginLeft: "10px" }} />
            </Button>
            <Modal
                open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box sx={{ width: isMdUp ? 400 : '100%', borderRadius: 2, bgcolor: 'background.paper', p: 3 }}>
                    <h1 style={{ marginBottom: 30 }}>Align Resource</h1>
                    <form>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="lead-label">Manager</InputLabel>
                            <Select labelId="lead-label" label="Manager" value={userId} onChange={handleUserIdChange}>
                                <MenuItem value=""></MenuItem>
                                {
                                    allUser.map((user: any) => (
                                        <MenuItem key={user.user_id} value={user.user_id} >{user.user_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        {/* <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="lead-label">Client / Customer</InputLabel>
                            <Select labelId="lead-label" label="Client / Customer" value={leadId} onChange={handleLeadIdChange}>
                                <MenuItem value=""></MenuItem>
                                {
                                    allLeads.map((lead: any) => (
                                        <MenuItem key={lead.lead_id} value={lead.lead_id} >{lead.legal_entity}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl> */}
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="lead-label">Employee</InputLabel>
                            <Select labelId="lead-label" label="Employee" value={employeeId} onChange={handleEmployeeIdChange}>
                                <MenuItem value=""></MenuItem>
                                {
                                    allEmployees.map((emp: any) => (
                                        <MenuItem key={emp.user_id} value={emp.user_id} >{emp.user_name}</MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>

                        {/* <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Name"
                            type="text"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                        /> */}
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Assinged Date" value={assingedDate}
                                    onChange={handleAssignDateChange} />
                            </LocalizationProvider>
                        </FormControl>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Exit Date" value={exitDate}
                                    onChange={handleExitDateChange} />
                            </LocalizationProvider>
                        </FormControl>
                        <TextField
                            sx={{ mt: 2 }}
                            fullWidth
                            label="Name"
                            type="text"
                            value={Name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <Box sx={{ mt: 2 }}>
                            <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Align</Button>
                            <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div>
    );
};

export default InsertResource;
