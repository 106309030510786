import React, { useEffect, useState } from 'react'
import { Button, Stack } from '@mui/material';
import CurrentRevenue from '../../DataTables/CurrentRevenue';
import CurrentBilling from '../../Analytics/Finance/CurrentBilling';
import axios from "../../../utils/axios";
import { useSelector, useDispatch } from 'react-redux';
import { loading } from '../../../Actions/branchActions';
import { toast } from "react-toastify";
import CurrentBillingStats from '../../Analytics/Finance/CurrentBillingStats';
import { LineBarColumn } from '../../../global-component/graphs/line-bar-column';

const BillingReport = () => {
    const dispatch = useDispatch();
    const currentDate = new Date();
    const oneMonthAgoDate = new Date(currentDate);
    oneMonthAgoDate.setMonth(oneMonthAgoDate.getMonth() + 3);
    const oneMonthAgoYear = oneMonthAgoDate.getFullYear();
    const oneMonthAgoMonth = String(oneMonthAgoDate.getMonth() + 1).padStart(2, '0');
    const oneMonthAgoDay = String(oneMonthAgoDate.getDate()).padStart(2, '0');
    const formattedOneMonthAgoDate = `${oneMonthAgoYear}-${oneMonthAgoMonth}-${oneMonthAgoDay}`;

    // Six months ago
    const sixMonthsAgoDate = new Date(currentDate);
    sixMonthsAgoDate.setMonth(sixMonthsAgoDate.getMonth() - 3);
    const sixMonthsAgoYear = sixMonthsAgoDate.getFullYear();
    const sixMonthsAgoMonth = String(sixMonthsAgoDate.getMonth() + 1).padStart(2, '0');
    const sixMonthsAgoDay = String(sixMonthsAgoDate.getDate()).padStart(2, '0');
    const formattedSixMonthsAgoDate = `${sixMonthsAgoYear}-${sixMonthsAgoMonth}-${sixMonthsAgoDay}`;

    const handleRevenue = () => {
        setDoApply(true);
    }

    const [startd, setstart] = useState(formattedSixMonthsAgoDate);
    const [endd, setend] = useState(formattedOneMonthAgoDate);
    const [doApply, setDoApply] = useState(false);

    const [data, setData] = useState([])
    const [months, setMonths] = useState([]);
    const [pAmount, setPAmount] = useState([]);
    const [taxAmount, setTaxAmount] = useState([]);
    const branch = useSelector((state: any) => state.branch);

    useEffect(() => {
        dispatch(loading(true))
        const getCurrentBilling = async () => {
            try {
                const response = await axios.post('/get-current-billing', { branch: branch.branch, startDate: startd, endDate: endd })

                if (response.data.fetched) {
                    setData(response.data.billings.rows);
                    const billings = response.data.billings.rows;
                    const monthsArray = billings.map((billing: any) => billing.MONTH);
                    const totalTaxesArray = billings.map((billing: any) => billing.total_tax);
                    const totalAmountsArray = billings.map((billing: any) => billing.total_amount);

                    setMonths(monthsArray);
                    setTaxAmount(totalTaxesArray);
                    setPAmount(totalAmountsArray);
                }
            } catch (err) {

                console.error(err)
                toast.error(`Service Unavailable!`, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
            dispatch(loading(false))
        }

        getCurrentBilling();
        setDoApply(false);
    }, [doApply])

    return (
        <>
            <div className="account-filter">
                <span>
                    <span><b>From</b></span>
                    <input type="date" value={startd} onChange={(e) => setstart(e.target.value)} style={{ maxWidth: '120px', padding: '8px', fontSize: '12px', margin: "5px" }} />
                </span>
                <span>
                    <span><b>To</b></span>
                    <input type="date" value={endd} onChange={(e) => setend(e.target.value)} style={{ maxWidth: '120px', padding: '8px', fontSize: '12px', margin: "5px" }} />
                </span>
                <Button variant='contained' sx={{ mx: 3 }} onClick={handleRevenue}>Apply</Button>
            </div>
            <div className='account-table'>
                <Stack direction={'column'} width={'100%'}>
                    <CurrentBillingStats data={data} name={"BillingReport"} />
                    {/* <CurrentBilling months={months} pAmounts={pAmount} taxAmounts={taxAmount} /> */}
                    <LineBarColumn dataSource={[
                        {
                            name: 'GST',
                            data: taxAmount
                        },
                        {
                            name: 'Principle Amount',
                            data: pAmount
                        }
                    ]} id='director-finance-billing-report' title='Current billing' chartType='bar' categories={months} exporting={true} yAxisTitle='Amount ( in rupees )' stackingColumns={true} />
                </Stack>
            </div>
        </>
    )
}

export default BillingReport
