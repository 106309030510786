import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

import Modal from "@mui/material/Modal";

import { TextField, FormControl, InputLabel, Select, MenuItem, Stack, Checkbox, FormControlLabel, useTheme, useMediaQuery } from '@mui/material';
import { toast } from "react-toastify";
import { FaPlus } from "react-icons/fa";

import axios from "../../utils/axios";

import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector } from "react-redux";


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};


const InsertClient: React.FC<any> = ({ updateRefresh }) => {
    const branch = useSelector((state: any) => state.branch);
    const [open, setOpen] = useState(false);

    const [country, setCountry] = useState("");
    const [state, setState] = useState("");
    const [city, setCity] = useState("");
    const [address, setAddress] = useState("");

    const [pin, setPin] = useState("");
    const [PAN, setPAN] = useState("");
    const [gst, setGst] = useState("");

    const [legalName, setLegalName] = useState("");
    const [brandName, setBrandName] = useState("");
    const [branchName, setBranchName] = useState("");

    const resetState = () => {
        setCountry("");
        setState("");
        setCity("");
        setAddress("");
        setPin("");
        setPAN("");
        setGst("");
        setLegalName("");
        setBrandName("");
        setBranchName("");
    }

    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper');

    const handleClickOpen = (scrollType: DialogProps['scroll']) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const descriptionElementRef = React.useRef<HTMLElement>(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    //   const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // take name,id from token

    const handleInsert = async () => {
        try {
            const response: any = await axios.post(`/create-update/client`, {
                "company_id": Number(branch.branch),
                "brand_name": brandName,
                "legal_name": legalName,
                "branch": branchName,
                "address": address,
                "country": country,
                "state": state,
                "city": city,
                "pin": Number(pin),
                "pan": PAN,
                "gst": gst
            })

            console.log(response);

            toast.success(`Successfully Inserted!`, {
                position: 'top-right',
                autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            setOpen(false);
            updateRefresh(true);
            resetState();
        } catch (err) {
            console.log(err)
            toast.error(`Please check all the entries carefully!`, {
                position: 'top-right',
                autoClose: 5000, // Close the toast after 2000 milliseconds (2 seconds)
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const theme = useTheme();
    const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <React.Fragment>
            <Button variant="contained" style={{ backgroundColor: "white", color: "#2196F3" }} onClick={handleClickOpen('paper')}>
                Add New Client<FaPlus style={{ marginLeft: "10px" }} />
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                // maxWidth='xl'
                fullScreen
            >
                <DialogTitle id="scroll-dialog-title">
                    <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} width={"100%"}>
                        <div>
                            <h2>Add New Client</h2>
                        </div>
                        <div>
                            <h2>{localStorage.getItem('cur_branch')}</h2>
                        </div>
                    </Stack>
                </DialogTitle>
                <DialogContent >
                    <Stack
                        direction={isMdUp ? 'row' : 'column'}
                    >
                        <Box sx={{ width: isMdUp ? 600 : '100%', minWidth: isMdUp ? 600 : '100%', borderTopLeftRadius: 2, borderBottomLeftRadius: 2, bgcolor: 'background.paper', p: 3, marginX: isMdUp ? 2 : 0 }}>
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Brand Name"
                                type="text"
                                value={brandName}
                                onChange={(e) => setBrandName(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Legal Name"
                                type="text"
                                value={legalName}
                                onChange={(e) => setLegalName(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Branch Name"
                                type="text"
                                value={branchName}
                                onChange={(e) => setBranchName(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Address"
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Country"
                                type="text"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="State"
                                type="text"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="City"
                                type="text"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="Pincode"
                                type="number"
                                value={pin}
                                onChange={(e) => setPin(e.target.value)}
                                inputProps={{
                                    maxLength: 10
                                }}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="PAN Number"
                                type="text"
                                inputProps={{
                                    maxLength: 10
                                }}
                                value={PAN}
                                onChange={(e) => setPAN(e.target.value)}
                            />
                            <TextField
                                sx={{ mt: 2 }}
                                fullWidth
                                label="GST"
                                type="text"
                                value={gst}
                                onChange={(e) => setGst(e.target.value)}
                            />
                        </Box>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" style={{ borderRadius: 5 }} onClick={handleInsert}>Add</Button>
                    <Button variant="outlined" style={{ marginLeft: 10, borderRadius: 5 }} onClick={handleClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default InsertClient;
