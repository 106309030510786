import React, { SyntheticEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Checkbox,
  Autocomplete,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { FaPlus } from "react-icons/fa";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import { MBDatePicker } from "../../global-component";
import { CommonService, LeadService, LocalService } from "../../core/service";
import { Mandate, Source as EnumSource } from "../../enums/leads";
import * as Util from "../../core/utility";
import dayjs from "dayjs";
import { CustomDivider } from "../../global-component";

const commonService: CommonService = new CommonService();
const leadService: LeadService = new LeadService();
const localService: LocalService = new LocalService();
const methods: Util.Methods = new Util.Methods();

const user_id: any = localStorage.getItem("access_token");

let roleobj: { role: string; id: number; name: string; iat: number } | null =
  null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

type btTeamListType = {
  user_id: number;
  employee_id: number;
  user_name: string;
};

export const IntialFormObj = {
  branch_id: 0,
  created_by: 0,
  updated_by: 0,
  vp_leads_id: 0,
  lead_manager_id: roleobj?.id || 0,
  manager_leads_id: 0,
  client_lead_name: "",
  lead_date: new Date().toString(),
  source: "",
  referral: "",
  mandate: "",
  projected_fee: "",
  description: "",
  bd_team_members: [] as btTeamListType[],
  ops_team_members: [] as btTeamListType[],
};

interface Props {
  updateRefresh: (event: boolean) => void;
  branch: { branch: number; visible: boolean };
}

const InsertLead: React.FC<Props> = ({ updateRefresh, branch }: Props) => {
  // const branch = useSelector((state: any) => state.branch);
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState(IntialFormObj);
  const [bdTeamList, setbdTeamList] = useState<btTeamListType[]>([]);
  const [vpList, setVpList] = useState<typeof bdTeamList>([]);
  const [opsmList, setOpsmList] = useState<any[]>([]);
  const [opsTeamList, setOpsTeamList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    commonService
      .getOwnVP(roleobj?.id || 0)
      .then((res) => {
        if (res.status > 0 && res.data?.fetchedRows > 0) {
          setVpList(res.data.rows);
          setForm((prev) => ({
            ...prev,
            vp_leads_id: res.data.rows[0].user_id,
          }));
        }
      })
      .catch((error) => {
        throw new Error(
          `get bdTeam list Error in create new lead ==> ${error}`
        );
      });

    commonService
      .getTeamLeader({ userId: roleobj?.id || 0 })
      .then((res) => {
        if (res.status > 0 && res.data?.fetchedRows > 0) {
          setOpsmList(res.data.rows);
        }
      })
      .catch((error) => {
        throw new Error(
          `get bdTeam list Error in create new lead ==> ${error}`
        );
      });

    commonService
      .getBDTeam(roleobj?.id || 0)
      .then((res) => {
        if (res.status > 0 && res.data?.fetchedRows > 0) {
          setbdTeamList(res.data.rows);
        }
      })
      .catch((error) => {
        throw new Error(
          `get bdTeam list Error in create new lead ==> ${error}`
        );
      });
  }, []);

  useEffect(() => {
    commonService
      .getLeaderTeam(form.manager_leads_id)
      .then((res: any) => {
        if (res.data.fetchedRows > 0) {
          setOpsTeamList(res.data.rows);
        }
      })
      .catch((error) => {
        throw new Error(
          `get teamMembers list Error in create new lead ==> ${error}`
        );
      });
  }, [form.manager_leads_id]);

  const [scroll, setScroll] = useState<DialogProps["scroll"]>("paper");
  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => setOpen(false);

  function onSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    try {
      setLoading(true);
      const CreateLeadData: typeof IntialFormObj = {
        ...form,
        ...Object.fromEntries(new FormData(event.target as any)),
        lead_manager_id: roleobj?.id || 0,
        branch_id: branch.branch,
        created_by: roleobj?.id || 0,
      };

      leadService
        .createLead<typeof IntialFormObj>({
          ...CreateLeadData,
          manager_leads_id: CreateLeadData.manager_leads_id
            ? CreateLeadData.manager_leads_id
            : 0,
        })
        .then((res) => {
          if (res.status === 1) {
            localService.toastify("Lead Create Success!!", "success", 2000);
            /* Rest Form States */
            setForm(IntialFormObj);
            /* close dialog */
            handleClose();
            /* Refresh Outer Table */
            updateRefresh(true);
          }
        })
        .finally(() => setLoading(false));
    } catch (error) {
      throw new Error(`formSubmit Error in Create Lead  ==> ${error}`);
    }
  }

  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      <Button
        variant="contained"
        style={{ backgroundColor: "white", color: "#2196F3" }}
        onClick={handleClickOpen("paper")}
      >
        New Lead
        <FaPlus style={{ marginLeft: "10px" }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
      >
        <DialogTitle className="dialog-title-sh" id="scroll-dialog-title">
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <div>
                <h2>Create Lead</h2>
              </div>
              <div>
                <h2>{localStorage.getItem("cur_branch")}</h2>
              </div>
            </Stack>
          </Stack>
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent style={{ padding: "10px 24px" }}>
            <Stack direction={isMdUp ? "row" : "column"}>
              <Box
                component="section"
                // noValidate
                // autoComplete="off"
                sx={{
                  width: isMdUp ? 400 : "100%",
                  minWidth: isMdUp ? 400 : "100%",
                  borderTopLeftRadius: 2,
                  borderBottomLeftRadius: 2,
                  bgcolor: "background.paper",
                  marginX: isMdUp ? 2 : 0,
                }}
              >
                <CustomDivider label="BD Team" />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">VP Leads</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="VP Leads"
                    disabled={true}
                    value={form.vp_leads_id}
                    required
                  >
                    {vpList.map((data, index) => (
                      <MenuItem
                        key={`vp_list_${index}_id`}
                        value={data.user_id}
                      >
                        {data.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <TextField
                  disabled={true}
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Lead Manager"
                  value={roleobj?.name || ""}
                  type="text"
                />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="bd-team-list-id"
                    limitTags={1}
                    multiple={true}
                    disableCloseOnSelect={true}
                    options={bdTeamList}
                    onChange={(event, value) =>
                      setForm((prev) => ({ ...prev, bd_team_members: value }))
                    }
                    getOptionLabel={(option) => option.user_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.user_id}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.user_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="BD Team" />
                    )}
                  />
                </FormControl>
                <CustomDivider label="Team Leader" />
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="ops-label">Team Leader</InputLabel>
                  <Select
                    labelId="ops-label"
                    label="Team Leader"
                    name="manager_leads_id"
                    onChange={(event) => {
                      setForm((prev) => ({
                        ...prev,
                        manager_leads_id: (event.target.value as number) || 0,
                        ops_team_members: event.target.value ? prev.ops_team_members : []
                      }));
                    }}
                  >
                    <MenuItem>
                      <em>None</em>
                    </MenuItem>
                    {opsmList.map((data, index) => (
                      <MenuItem
                        key={`insert-lead_${index}_id`}
                        value={data.user_id}
                      >
                        {data.user_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <Autocomplete
                    id="ops-team-list_id"
                    limitTags={1}
                    multiple={true}
                    disableCloseOnSelect={true}
                    options={opsTeamList}
                    onChange={(event, value) =>
                      setForm((prev) => ({ ...prev, ops_team_members: value }))
                    }
                    // value={methods.filterArray(form.bd_team_members.map(val => val.user_id), bdTeamList, 'user_id') || []}
                    getOptionLabel={(option) => option.user_name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.user_id}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.user_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="Team Member" />
                    )}
                  />
                </FormControl>
                <CustomDivider label="Lead Details" />
                <TextField
                  sx={{ mt: 2 }}
                  fullWidth
                  label="Client Legal Name"
                  name="client_lead_name"
                  type="text"
                  required={true}
                />

                <MBDatePicker
                  label="Lead Date"
                  // onChange={(event) => console.log(event)}
                  onChange={(event) =>
                    setForm((prev) => ({
                      ...prev,
                      lead_date:
                        event !== "Invalid Date"
                          ? event
                          : dayjs(new Date()).format("YYYY-MM-DD"),
                    }))
                  }
                  // minDate={form.lead_date}
                  className="w-100"
                  sx={2}
                />

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Source</InputLabel>
                  <Select
                    labelId="lead-label"
                    name="source"
                    label="Source"
                    onChange={(e) =>
                      setForm((prev) => ({
                        ...prev,
                        source: e.target.value as string,
                      }))
                    }
                    required
                  >
                    {EnumSource.map((data, index) => (
                      <MenuItem
                        key={`source-insert_${index}_id`}
                        value={data.value}
                      >
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {form.source.toLowerCase() === "referral" && (
                  <TextField
                    sx={{ mt: 2 }}
                    fullWidth
                    label="Referral"
                    name="referral"
                    required
                  />
                )}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel id="lead-label">Mandate</InputLabel>
                  <Select
                    labelId="lead-label"
                    label="Mandate"
                    name="mandate"
                    onChange={(event) =>
                      setForm((prev) => ({
                        ...prev,
                        mandate: (event.target.value as string) || "",
                      }))
                    }
                    required
                  >
                    {Mandate.map((data, index) => (
                      <MenuItem
                        key={`insert-lead_${index}_id`}
                        value={data.value}
                      >
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {form.mandate && (
                  <TextField
                  required
                    sx={{ mt: 2 }}
                    fullWidth
                    label={`Projected Fee (${
                      form.mandate === "retainer" ? "Monthly" : "One Time"
                    })`}
                    type="number"
                    name="projected_fee"
                  />
                )}

                <FormControl fullWidth sx={{ mt: 2 }}>
                  <TextField
                    label="Description"
                    required
                    name="description"
                    multiline
                    rows={4}
                  />
                </FormControl>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="contained"
              type="submit"
            >
              Create
            </LoadingButton>
            {/* <Button variant="contained" type="submit">
              Create
            </Button> */}
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

const mapToState = (state: any) => {
  return {
    branch: state.branch,
  };
};

const connectToRedux = connect(mapToState, null)(InsertLead);

export { connectToRedux as InsertLead };
// export default InsertLead;
