import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  TextField,
  styled,
  Autocomplete,
  Checkbox,
  TableCell,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { toast } from "react-toastify";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";

import axios from "../../utils/axios";
import IconButton from "@mui/material/IconButton";
import { jwtDecode } from "jwt-decode";
import { useSelector } from "react-redux";
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";

import { CommonService, LeadService, LocalService } from "../../core/service";
import { Mandate, Source as EnumSource, Status } from "../../enums/leads";
import { MBDatePicker } from "../../global-component";
import * as Util from "../../core/utility";
import dayjs from "dayjs";
import {CustomDivider} from '../../global-component';

const methods: Util.Methods = new Util.Methods();
const commonService: CommonService = new CommonService();
const leadService: LeadService = new LeadService();
const localService: LocalService = new LocalService();

// type FormInputs = {
//   id: number;
//   contract_id: number;
//   client_id: number;
//   client_office_id: number;
//   branch_id: number;
//   updated_by: number;
//   created_by: number;
//   created_time: string;
//   updated_time: string;
//   bd_manager_id: number;
//   bd_manager_name: string;
//   bd_team_id: number;
//   bd_team_name: number;
//   ops_manager_id: number;
//   ops_manager_name: string;
//   ops_team_id: number;
//   ops_team_name: string;
//   lead_name: string;
//   description: string;
//   source: string;
//   referral: string;
//   projected_fee: number;
//   status: string;
//   created_at: string;
//   start_date: string;
//   end_date: string;
//   sow: string;
//   poc: string;
//   poc_email: string;
//   poc_contact: string;
//   account_poc: string;
//   account_email: string;
//   account_contact: string;
//   price: number;
//   brand_name: string;
//   legal_name: string;
//   company_id: number;
//   branch: string;
//   address: string;
//   city: string;
//   state: string;
//   country: string;
//   pin: string;
//   pan: string;
//   gst: string;
// };

const user_id: any = localStorage.getItem("access_token");

let roleobj: { role: string; id: number; name: string; iat: number } | null =
  null;
if (user_id) {
  roleobj = jwtDecode(user_id);
}

// type opsTeamListType = {
//   user_id: number;
//   employee_id: number;
//   user_name: string;
// };

// type NewFormType = {
//   lead: {
//     id?: number;
//     branch_id: number;
//     created_by: number;
//     updated_by: number;
//     vp_leads_id: number;
//     lead_manager_id: number;
//     client_lead_name: string;
//     lead_date: string;
//     source: string;
//     referral: string;
//     mandate: string;
//     projected_fee: string;
//     description: string;
//     status: string;
//     team_members: any[];
//   };
//   lead_converted: {
//     id?: number;
//     updated_by: number;
//     lead_id: number;
//     client_id: number;
//     start_date: string;
//     end_date: string;
//     sow: string;
//     poc: string;
//     poc_email: string;
//     poc_contact: string;
//     account_poc: string;
//     account_email: string;
//     account_contact: string;
//     price: string;
//   };
//   client: {
//     id?: number;
//     lead_id: number;
//     legal_name: string;
//     brand_name: string;
//   };
//   clientOffice: {
//     id?: number;
//     ref_id: number;
//     company_id: number;
//     branch: string;
//     address: string;
//     city: string;
//     state: string;
//     country: string;
//     pin: string;
//     pan: string;
//     gst: string;
//   };
// };

// const intialFormNew: NewFormType = {
//   lead: {
//     branch_id: 0,
//     created_by: 0,
//     updated_by: 0,
//     vp_leads_id: 0,
//     lead_manager_id: 0,
//     client_lead_name: "",
//     lead_date: "",
//     source: "",
//     referral: "",
//     mandate: "",
//     projected_fee: "",
//     description: "",
//     status: "",
//     team_members: [],
//   },
//   lead_converted: {
//     updated_by: 0,
//     lead_id: 0,
//     client_id: 0,
//     start_date: "",
//     end_date: "",
//     sow: "",
//     poc: "",
//     poc_email: "",
//     poc_contact: "",
//     account_poc: "",
//     account_email: "",
//     account_contact: "",
//     price: "",
//   },
//   client: {
//     lead_id: 0,
//     legal_name: "",
//     brand_name: "",
//   },
//   clientOffice: {
//     ref_id: 0,
//     company_id: 0,
//     branch: "",
//     address: "",
//     city: "",
//     state: "",
//     country: "",
//     pin: "",
//     pan: "",
//     gst: "",
//   },
// };

const intialForm = {
  lead: {
    lead_id: 0,
    branch_id: 0,
    created_by: 0,
    updated_by: 0,
    vp_leads_id: 0,
    lead_manager_id: 0,
    manager_leads_id: 0,
    client_id: 0,
    client_lead_name: "",
    lead_date: "",
    source: "",
    referral: "",
    mandate: "",
    projected_fee: 0,
    description: "",
    status: "WIP",
    start_date: "1970-01-01",
    end_date: "1970-01-01",
    sow: "",
    poc: "",
    poc_email: "",
    poc_contact: "",
    account_poc: "",
    account_email: "",
    account_contact: "",
    price: 0,
    bd_team_members: [] as {
      user_id: number;
      employee_id: number;
      user_name: string;
    }[],
    ops_team_members: [] as {
      user_id: number;
      employee_id: number;
      user_name: string;
    }[],
  },
  masterClients: {
    client_id: 0,
    company_id: 0,
    legal_name: "",
    brand_name: "",
    branch: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    pan: "",
    gst: "",
  },
};

type RemarkType = {
  lead_id: number;
  user_id: number;
  user_name: string;
  role: string;
  remarks: string;
  created_at: string;
};

type AutoCompleteType = {
  user_id: number;
  employee_id: number;
  user_name: string;
};

interface EditAccountProps {
  data: any;
  updateRefresh: (event: boolean) => void;
}

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const LeadMaster: React.FC<EditAccountProps> = ({
  data,
  updateRefresh,
  ...arg
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remarkLoading, setRemarkLoading] = useState(false);
  const Company = useSelector((state: any) => state.branch);
  const [form, setForm] = useState<typeof intialForm>(intialForm);
  const [remark, setRemark] = useState<string>("");
  // const [opsTeamList, setOpsTeamList] = useState<opsTeamListType[]>([]);
  // const [vpList, setVpList] = useState<typeof opsTeamList>([]);
  const [remarkList, setRemarkList] = useState<RemarkType[]>([]);
  const [isConverted, setIsConverted] = useState(false);
  const [renderElement, setRenderElement] = useState(false);
  const [fileNames, setFileNames] = useState<any>([]);
  const [SOW, setSOW] = useState<any>([]);

  // Lists
  const [teamLeadList, setTeamLeadList] = useState<AutoCompleteType[]>([]);
  const [teamMemberList, setTeamMemberList] = useState<AutoCompleteType[]>([]);
  const [bdTeamList, setBdTeamList] = useState<AutoCompleteType[]>([]);

  // const [opsmList, setOpsmList] = useState<any[]>([]);

  // other states
  const [isEditing, setIsEditing] = useState(false);
  const [allowToEdit, setAllowToEdit] = useState(false);
  

  useEffect(() => {
    if (data.lead_id > 0 && open) {
      commonService
        .getTeamLeader({ userId: roleobj?.id || 0 })
        .then((res: any) => {
          if (res.data.rows.length > 0) {
            setTeamLeadList(tranfromForAutoComplete(res.data.rows));
          }
        })
        .catch((error) => {
          throw new Error(
            `get bdTeam list Error in create new lead ==> ${error}`
          );
        });

      // Exception handle to vp cases pending
      commonService.getOwnVP(roleobj?.id || 0).then((res) => {
        if (res.data.fetchedRows > 0) {
          const vpDetail = res.data.rows[0] as {
            user_name: string;
            user_id: number;
          };
          // setVpList(res.data.rows);
          setForm((prev) => {
            prev.lead.vp_leads_id = vpDetail.user_id;
            return prev;
          });
        }
      });

      commonService
        .getBDTeam(roleobj?.id || 0)
        .then((res) => {
          if (res.status > 0 && res.data?.fetchedRows > 0) {
            setBdTeamList(res.data.rows);
          }
        })
        .catch((error) => {
          throw new Error(
            `get bdTeam list Error in create new lead ==> ${error}`
          );
        });

      leadService
        .getLeadDetail(data.lead_id)
        .then((res) => {
          let response = res.data;
          setForm((prev) => {
            prev.lead = { ...prev.lead, ...response?.lead };
            prev.masterClients = {
              ...prev.masterClients,
              ...response.masterClients,
            };
            return prev;
          });

          setIsConverted(response.lead.status === "Converted" ? true : false);

          // Set User is Allow to Edit

          setAllowToEdit(() =>
            response.lead.created_by.toString() === roleobj?.id.toString() &&
            response.lead.status.toLowerCase() !== "converted"
              ? true
              : false
          );

          // methods.filterArray(response.lead.team_members.map((val) => val.user_id),bdTeamList,"user_id") || []
        })
        .catch((err) => console.log(err))
        .finally(() => setRenderElement(true));

      getRemarks();
    }

    return;
  }, [data, open]);

  useEffect(() => {
    if (form.lead.manager_leads_id > 0) {
      commonService
        .getLeaderTeam(form.lead.manager_leads_id)
        .then((res: any) => {
          if (res.data.rows.length > 0) {
            setTeamMemberList(res.data.rows);
          }
        });
    } else {
      setTeamMemberList([]);
      setForm((prev) => {
        prev.lead.ops_team_members = [];
        return { ...prev };
      });
    }
  }, [form.lead.manager_leads_id]);


  function tranfromForAutoComplete(dataSource: AutoCompleteType[]){
    const tempData:AutoCompleteType[] = [];
    for(let data of dataSource){
      tempData.push({
        user_id: data.user_id,
        employee_id: data.employee_id,
        user_name: data.user_name
      })
    };

    return tempData

  }

  // const getOpsTeams = () => {
  //   commonService
  //     .getLeaderTeam(form.lead.manager_leads_id)
  //     .then((res:any) => {
  //       if (res.data.rows.length > 0) {
  //         setOpsTeamList(res.OPSTeams);
  //       }
  //     })
  //     .catch((error) => {
  //       throw new Error(
  //         `get teamMembers list Error in create new lead ==> ${error}`
  //       );
  //     });
  // }

  // useEffect(() => {
  //   if (data.lead_id > 0 && open) {
  //     getOpsTeams();
  //   }
  // }, [form.lead.manager_leads_id])

  const handleFileChange = (event: any) => {
    const files = event.target.files;
    if (files) {
      const names = Array.from(files).map((file: any) => file.name);
      const pdfs = Array.from(files).map((file: any) => file);
      setSOW(pdfs);
      setFileNames(names);
    } else {
      setFileNames([]);
      setSOW([]);
    }
  };

  const uploadSOW = async () => {
    try {
      const formData = new FormData();
      formData.append("id", data.id.toString());
      formData.append("lead_name", data.lead_name);
      SOW.forEach((pdf: any) => formData.append("sow", pdf));
      // for (let i = 0; i < fileNames.length; i++) {
      //     formData.append('sow', fileNames[i]);
      // }
      // const response = await axios.post("/upload-sow", formData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //   },
      // });
      toast.success(`Successfully Uploaded!`, {
        position: "top-right",
        autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.error("Error uploading PDFs:", error);

      toast.error(`Error uploading documents`, {
        position: "top-right",
        autoClose: 2000, // Close the toast after 2000 milliseconds (2 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleSowDownload = async () => {
    try {
      const response = await axios.post(
        `/get-sow`,
        {
          id: data.id,
          lead_name: data.lead_name,
        },
        {
          responseType: "blob", // Ensure response type is blob
        }
      );

      // Create a blob URL for the zip file
      const blob = new Blob([response.data], { type: "application/zip" });
      const url = window.URL.createObjectURL(blob);

      // Create a link element and trigger download
      const link = document.createElement("a");
      link.href = url;
      link.download = `${data.id}-${data.lead_name}-sow.zip`;
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading documents:", error);
      // Handle error
      toast.warning(`No SOW document availble to download`, {
        position: "top-right",
        autoClose: 3000, // Close the toast after 2000 milliseconds (2 seconds)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handlePopup = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    updateRefresh(true);
    setRenderElement(false);
  };

  function getRemarks() {
    try {
      leadService
        .getLeadRemark(data.lead_id)
        .then((res: any) => {
          if (res.status > 0) {
            setRemarkList(res.data.rows as RemarkType[]);
          }
        })
        .catch((err) => console.log(`get lead Remark error :: ${err}`));
    } catch (error) {}
  }

  function createNewRemark() {
    try {
      setRemarkLoading(true);
      console.log({
        user_id: (roleobj?.id as number) || 0,
        lead_id: data.lead_id,
        remarks: remark,
      })
      leadService
        .createRemark({
          user_id: (roleobj?.id as number) || 0,
          lead_id: data.lead_id,
          remarks: remark,
        })
        .then((res) => {
          setRemark("");
          getRemarks();
        })
        .catch((err) => console.log(`create remark error :: ${err}`))
        .finally(() => setRemarkLoading(false));
    } catch (error) {}
  }

  // Complete form
  async function onSubmit(event: SyntheticEvent) {
    event.preventDefault();

    setLoading(!loading);

    const target = event.target as HTMLFormElement;
    try {
      const fmData = new FormData(target);
      const lead: Record<string, any> = {
        ...form.lead,
        updated_by: roleobj?.id,
        sow: "SOW",
      };
      const masterClients: Record<string, any> = {};
      // const lead_converted: Record<string, any> = {};
      // const client: Record<string, any> = {};
      // const clientOffice: Record<string, any> = {};

      // Update lead data with form data or retain existing values
      Object.keys(lead).forEach((key) => {
        lead[key] = fmData.get(key) || lead[key];
      });

      if (isConverted) {
        masterClients["masterClients"] = {
          ...form.masterClients,
          company_id: Company.branch,
        };
        // lead_converted["lead_converted"] = {
        //   ...form.lead_converted,
        //   sow: "SOW",
        //   lead_id: data.id,
        // };
        // client["client"] = { ...form.client, lead_id: data.id };
        // clientOffice["clientOffice"] = {
        //   ...form.clientOffice,
        //   company_id: Company.branch,
        // };

        // Update lead_converted, client, and clientOffice data with form data or retain existing values
        Object.keys(masterClients["masterClients"]).forEach((key) => {
          masterClients["masterClients"][key] =
            fmData.get(key) || masterClients["masterClients"][key];
        });
        // Object.keys(lead_converted["lead_converted"]).forEach((key) => {
        //   lead_converted["lead_converted"][key] =
        //     fmData.get(key) || lead_converted["lead_converted"][key];
        // });
        // Object.keys(client["client"]).forEach((key) => {
        //   client["client"][key] = fmData.get(key) || client["client"][key];
        // });
        // Object.keys(clientOffice["clientOffice"]).forEach((key) => {
        //   clientOffice["clientOffice"][key] =
        //     fmData.get(key) || clientOffice["clientOffice"][key];
        // });
      }

      const formData = { lead, ...masterClients };

      leadService
        .updateLeadDetails(formData)
        .then((res: { status: number }) => {
          if (res.status > 0) {
            setIsEditing(!isEditing);
            // it will be work when file attach (temp close)
            if (fileNames.length !== 0) {
              uploadSOW();
            }
            localService.toastify("Lead update succesfully.", "success", 10000);
          }
        })
        .catch((err) => console.error(`update lead data error :: ${err}`))
        .finally(() => setLoading(false));
    } catch (error) {
      console.error(`Lead onSubmit function error ==> ${error}`);
    }
  }

  const theme = useTheme();
  // const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <React.Fragment>
      <TableCell>
        <IconButton color="primary" style={{ height: "8px" }}>
          <InfoIcon onClick={handlePopup} />
        </IconButton>
      </TableCell>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen
      >
        {renderElement ? (
          <form id="lead-update-form-id" onSubmit={onSubmit}>
            <DialogTitle
              className="dialog-title-sh"
              id="scroll-dialog-title disable-inputs"
            >
              <Stack
                direction={isMdUp ? "row" : "column"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <h2>
                  {isEditing
                    ? `Edit '${form.lead.client_lead_name}'`
                    : `'${form.lead.client_lead_name}' details`}
                </h2>
                <Stack
                  direction={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  {allowToEdit ? (
                    <IconButton color="primary" size={"large"}>
                      <EditIcon
                        onClick={() => {
                          setIsEditing(true);
                        }}
                      />
                    </IconButton>
                  ) : (
                    <></>
                  )}
                  <Stack
                    direction={"column"}
                    alignItems={"end"}
                    justifyContent={"center"}
                  >
                    <h2 style={{ marginLeft: 10 }}>
                      {localStorage.getItem("cur_branch")}
                    </h2>
                    <p className="p-title">
                      <b>Created by</b>: {data?.lead_manager_name} (
                      {dayjs(data.created_time).format("LL")})
                    </p>
                    {/* <p>
                      <b>Last Modified by</b>: {updatedByPerson}{" "}
                      {data.updated_time}
                    </p> */}
                  </Stack>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent style={{ padding: "15px 24px" }}>
              <Stack direction={isMdUp ? "row" : "column"}>
                <Box
                  sx={{
                    width: isMdUp ? 400 : "100%",
                    minWidth: isMdUp ? 400 : "100%",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: isConverted ? 0 : 10,
                    borderTopRightRadius: isConverted ? 0 : 10,
                    bgcolor: "background.paper",
                    marginX: isMdUp ? 2 : 0,
                  }}
                >
                  <section>
                    <CustomDivider label="BD Team" />
                    <FormControl fullWidth >
                      <TextField
                        disabled={true}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Lead Manager"
                        value={data.vp_name || ""}
                        type="text"
                      />
                    </FormControl>
                    {/* bug in this input */}

                    {(roleobj?.id || 0) !== form.lead.lead_manager_id ? (
                      // <FormControl fullWidth sx={{ mt: 2 }}>
                      //   <InputLabel id="lead-label">Lead Manager</InputLabel>
                      //   <Select
                      //     disabled={true}
                      //     labelId="lead-label"
                      //     label="Lead Manager"
                      //     defaultValue={form.lead.lead_manager_id}
                      //   >
                      //     {opsTeamList.map((data, index) => (
                      //       <MenuItem
                      //         key={`source-insert_${index}_id`}
                      //         value={data.user_id}
                      //       >
                      //         {data.user_name}
                      //       </MenuItem>
                      //     ))}
                      //   </Select>
                      // </FormControl>
                      <></>
                    ) : (
                      <TextField
                        disabled={true}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Lead Manager"
                        value={roleobj?.name || ""}
                        type="text"
                      />
                    )}

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Autocomplete
                        id="bd-team-list-id"
                        limitTags={1}
                        multiple={true}
                        disableCloseOnSelect={true}
                        value={methods.filterArray(form.lead.bd_team_members.map(val => val.user_id), bdTeamList, 'user_id') || []}
                        options={bdTeamList}
                        onChange={(event, value) =>
                          setForm((prev) => ({
                            ...prev,
                            prev: prev.lead.bd_team_members = value
                            // bd_team_members: value,
                          }))
                        }
                        getOptionLabel={(option) => option.user_name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.user_id}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBox fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.user_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="BD Team" />
                        )}
                      />
                    </FormControl>
                        <CustomDivider label="Team Leader" />
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="ops-label">Team Leader</InputLabel>
                      <Select
                        readOnly={!isEditing ? true : false}
                        labelId="ops-label"
                        label="Team Leader"
                        name="manager_leads_id"
                        value={form.lead.manager_leads_id}
                        onChange={(event) => {
                          const value = event.target.value
                            ? event.target.value
                            : 0;
                          setForm((prev) => ({
                            ...prev,
                            lead: {
                              ...prev.lead,
                              manager_leads_id: (value as number) || 0,
                            },
                          }));
                        }}
                      >
                        <MenuItem value={0}>
                          <em>None</em>
                        </MenuItem>
                        {teamLeadList?.map((data, index) => (
                          <MenuItem
                            key={`insert-lead_${index}_id`}
                            value={data.user_id}
                          >
                            {data.user_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Autocomplete
                        readOnly={!isEditing ? true : false}
                        id="ops-team-list-id"
                        limitTags={1}
                        multiple={true}
                        disableCloseOnSelect={true}
                        value={methods.filterArray(form.lead.ops_team_members.map(val => val.user_id), teamMemberList, 'user_id') || []}
                        options={teamMemberList}
                        onChange={(event, value) =>
                          setForm((prev) => ({
                            ...prev,
                            lead: { ...prev.lead, ops_team_members: value },
                          }))
                        }
                        getOptionLabel={(option) => option.user_name}
                        renderOption={(props, option, { selected }) => (
                          <li {...props} key={option.user_id}>
                            <Checkbox
                              icon={<CheckBoxOutlineBlank fontSize="small" />}
                              checkedIcon={<CheckBox fontSize="small" />}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {option.user_name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params} label="Team Member" />
                        )}
                      />
                    </FormControl>
                    <CustomDivider label="Lead Details" />
                    <TextField
                      sx={{ mt: 2 }}
                      fullWidth
                      InputProps={{
                        readOnly: !isEditing ? true : false,
                      }}
                      label="Client Legal Name"
                      defaultValue={form.lead.client_lead_name}
                      name="client_lead_name"
                      type="text"
                      required={true}
                    />

                    <MBDatePicker
                      label="Lead Date"
                      defaultValue={form.lead.lead_date}
                      onChange={(event) =>
                        setForm((prev) => ({
                          ...prev,
                          lead: { ...prev.lead, lead_date: event as string },
                        }))
                      }
                      // onChange={(event) => console.log(event as string)}
                      className="w-100"
                      readOnly={!isEditing ? true : false}
                      sx={2}
                    />

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="lead-label">Source</InputLabel>
                      <Select
                        readOnly={!isEditing ? true : false}
                        labelId="lead-label"
                        name="source"
                        label="Source"
                        defaultValue={form.lead.source.toLowerCase()}
                        onChange={(event) =>
                          setForm((prev) => {
                            prev.lead.source = event.target.value as string;
                            return prev;
                          })
                        }
                        required
                      >
                        {EnumSource.map((data, index) => (
                          <MenuItem
                            key={`source-insert_${index}_id`}
                            value={data.value}
                          >
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {form.lead.source.toLowerCase() === "referral" && (
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Referral"
                        name="referral"
                        defaultValue={form.lead.referral}
                        required
                      />
                    )}
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="lead-label">Mandate</InputLabel>
                      <Select
                        readOnly={!isEditing ? true : false}
                        labelId="lead-label"
                        label="Mandate"
                        name="mandate"
                        defaultValue={form.lead.mandate.toLowerCase()}
                        onChange={(event) =>
                          setForm((prev) => {
                            prev.lead.mandate = event.target.value as string;
                            return prev;
                          })
                        }
                        required
                      >
                        {Mandate.map((data, index) => (
                          <MenuItem
                            key={`insert-lead_${index}_id`}
                            value={data.value}
                          >
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    {form.lead.mandate && (
                      <TextField
                      required
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label={`Projected Fee (${
                          form.lead.mandate === "retainer"
                            ? "Monthly"
                            : "One Time"
                        })`}
                        defaultValue={form.lead.projected_fee}
                        type="number"
                        name="projected_fee"
                      />
                    )}

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel id="lead-label">Status</InputLabel>
                      <Select
                        readOnly={!isEditing ? true : false}
                        labelId="lead-label"
                        label="Status"
                        name="status"
                        defaultValue={form.lead.status.toLowerCase()}
                        onChange={(event) => {
                          if (event.target.value === "converted") {
                            setIsConverted(true);
                          } else {
                            setIsConverted(false);
                          }
                        }}
                        required
                      >
                        {Status.map((data, index) => (
                          <MenuItem
                            key={`insert-lead_${index}_id`}
                            value={data.value}
                          >
                            {data.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <TextField
                        InputProps={{ readOnly: !isEditing ? true : false }}
                        label="Description"
                        required
                        name="description"
                        multiline
                        rows={4}
                        defaultValue={form.lead.description}
                      />
                    </FormControl>
                  </section>
                </Box>
                {isConverted && (
                  <Fragment>
                    <Box
                      sx={{
                        width: isMdUp ? 400 : "100%",
                        minWidth: isMdUp ? 400 : "100%",
                        borderTopLeftRadius: isConverted ? 0 : 10,
                        borderBottomLeftRadius: isConverted ? 0 : 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        bgcolor: "background.paper",
                        marginX: isMdUp ? 2 : 0,
                      }}
                    >
                      <CustomDivider label="Client Details" />
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        label="Legal Name"
                        type="text"
                        aria-readonly
                        name="legal_name"
                        defaultValue={form.masterClients.legal_name}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Brand Name"
                        type="text"
                        name="brand_name"
                        defaultValue={form.masterClients.brand_name}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="PAN number"
                        type="text"
                        name="pan"
                        defaultValue={form.masterClients.pan}
                        inputProps={{
                          maxLength: 10,
                        }}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="GST"
                        type="text"
                        name="gst"
                        defaultValue={form.masterClients.gst}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Branch"
                        type="text"
                        name="branch"
                        defaultValue={form.masterClients.branch}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Address"
                        type="text"
                        name="address"
                        defaultValue={form.masterClients.address}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Country"
                        type="text"
                        name="country"
                        defaultValue={form.masterClients.country}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="State"
                        type="text"
                        name="state"
                        defaultValue={form.masterClients.state}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="City"
                        type="text"
                        name="city"
                        defaultValue={form.masterClients.city}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Pincode"
                        type="text"
                        name="pin"
                        defaultValue={form.masterClients.pin}
                        required
                      />
                    </Box>
                    <Box
                      sx={{
                        width: isMdUp ? 400 : "100%",
                        minWidth: isMdUp ? 400 : "100%",
                        borderTopLeftRadius: isConverted ? 0 : 10,
                        borderBottomLeftRadius: isConverted ? 0 : 10,
                        borderTopRightRadius: 10,
                        borderBottomRightRadius: 10,
                        bgcolor: "background.paper",
                        marginX: isMdUp ? 2 : 0,
                      }}
                    >
                      <CustomDivider label="Client Contact Details" />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="Start Date"
                        type="date"
                        name="start_date"
                        defaultValue={new Date(form.lead.start_date) > new Date('1970-01-01') ? form.lead.start_date : dayjs(new Date()).format('YYYY-MM-DD')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{ mt: 2 }}
                        fullWidth
                        label="End Date"
                        type="date"
                        name="end_date"
                        defaultValue={new Date(form.lead.end_date) > new Date('1970-01-01') ? form.lead.end_date : dayjs(new Date()).format('YYYY-MM-DD')}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="Point of Contact"
                        type="text"
                        name="poc"
                        defaultValue={form.lead.poc}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="POC Contact"
                        type="number"
                        name="poc_contact"
                        defaultValue={form.lead.poc_contact}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="POC Email"
                        name="poc_email"
                        defaultValue={form.lead.poc_email}
                        type="email"
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="Account Point of Contact"
                        type="text"
                        name="account_poc"
                        defaultValue={form.lead.account_poc}
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="Account Email"
                        name="account_email"
                        defaultValue={form.lead.account_email}
                        type="text"
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="Account Contact"
                        name="account_contact"
                        defaultValue={form.lead.account_contact}
                        type="text"
                        required
                      />
                      <TextField
                        InputProps={{
                          readOnly: !isEditing ? true : false,
                        }}
                        sx={{
                          mt: 2,
                        }}
                        fullWidth
                        label="Price"
                        name="price"
                        defaultValue={form.lead.price}
                        type="number"
                        required
                      />
                    </Box>
                  </Fragment>
                )}
                <Box
                  sx={{
                    width: isMdUp ? 400 : "100%",
                    minWidth: isMdUp ? 400 : "100%",
                    borderTopLeftRadius: isConverted ? 0 : 10,
                    borderBottomLeftRadius: isConverted ? 0 : 10,
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                    bgcolor: "background.paper",
                    marginX: isMdUp ? 2 : 0,
                  }}
                >
                  <FormControl fullWidth sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{ py: "15px" }}
                      startIcon={<CloudUploadIcon />}
                      onClick={handleSowDownload}
                    >
                      Download SOW
                    </Button>
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{ mt: 2, display: isEditing ? "block" : "none" }}
                  >
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      sx={{ py: "15px" }}
                      tabIndex={-1}
                      fullWidth
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload SOW
                      <VisuallyHiddenInput
                        type="file"
                        accept=".pdf"
                        multiple
                        onChange={handleFileChange}
                      />
                    </Button>

                    {fileNames.length > 0 && (
                      <div>
                        <p>Selected files:</p>
                        <ul style={{ marginLeft: "20px" }}>
                          {fileNames.map((fileName: any, index: any) => (
                            <li key={index}>{fileName}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </FormControl>
                  {!isEditing && (
                    <div className="remark-section">
                      <span className="remark-title">Remarks</span>
                      <ul>
                        {remarkList.map((data, index) => (
                          <li key={`lead-remark_id_${index}`}>
                            <span className="user-remark">{data.remarks}</span>
                            <span className="user-details">
                              <span className="user-namet">
                                {data.user_name}
                              </span>
                              &nbsp; &nbsp;
                              <span className="created_at">
                                {dayjs(data.created_at).format("LL")}
                              </span>
                            </span>
                          </li>
                        ))}
                      </ul>
                      <div className="inputfield-btn">
                        <TextField
                          className="w-100 inputfield"
                          // label=""
                          name="remark"
                          value={remark}
                          onChange={(event) => setRemark(event.target.value)}
                          multiline
                          placeholder="Remark type here..."
                          // rows={4}
                        />
                        <LoadingButton
                          className="btn"
                          loading={remarkLoading}
                          loadingPosition="start"
                          startIcon={<SendIcon />}
                          onClick={createNewRemark}
                        ></LoadingButton>
                      </div>
                    </div>
                  )}
                </Box>
              </Stack>
            </DialogContent>
            {isEditing ? (
              <DialogActions>
                {/* <Button
                  variant="contained"
                  style={{ borderRadius: 5 }}
                  type="submit"
                >
                  Update
                </Button> */}
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined"
                  type="submit"
                >
                  Update
                </LoadingButton>
                <Button
                  variant="outlined"
                  style={{ marginLeft: 10, borderRadius: 5 }}
                  onClick={() => setIsEditing(!isEditing)}
                >
                  Cancel
                </Button>
              </DialogActions>
            ) : (
              <DialogActions>
                <Button
                  variant="outlined"
                  style={{ marginLeft: 10, borderRadius: 5 }}
                  onClick={handleClose}
                >
                  Close
                </Button>
              </DialogActions>
            )}
          </form>
        ) : (
          "Loading..."
        )}
      </Dialog>
    </React.Fragment>
  );
};

export default LeadMaster;
