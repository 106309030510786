import React, { useState } from 'react';
import { useNavigate } from "react-router-dom"
import './Card.css'
import roomImage from '../Assets/images/danielle-cerullo-bIZJRVBLfOM-unsplash.jpg'
interface CardProps {
  id: Number;
  room_id: string;
  title: string;
  description: string;
  location: string;
  imageUrl: string;
  seating_capacity: string;
  wall_projector: string;
  conferencing_phone: string;
  lan_conn_count: string;
  power_socket_count: string;
  wi_fi: string;
  television: string;
  hdmi_connectivity: string;
  presentation_pointer: string;
  url:string
}
const Card: React.FC<CardProps> = (props) => {
  // console.log(props)
  const [isHovered, setIsHovered] = useState(false);
  const history = useNavigate();
  // console.log(history)
  const handleBooking = async (e: any) => {
    const title = props.title;
    const room_id = props.room_id;
    history(`/booking/${room_id}/${title.replace(/\s/g, "").toLowerCase()}`, { state: { title, room_id } })
  }

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div className="card">
      <div className="card-item" onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave} onClick={handleBooking}>
        <img
          src={props.url}
          alt="room-pic"
        />
        <div className="card-content">
          <h1>{props.title}</h1>
          <p>{props.description}</p>
          <p><strong>{props.location}</strong></p>
          {/* <details>
                  <summary>Details</summary> */}
          {isHovered &&
            <div className='details-container'>
              <p><strong>Room Id</strong> : {props.room_id}</p>
              <p><strong>Seating Capacity</strong> : {props.seating_capacity}</p>
              <p><strong>Wall Projector</strong> : {props.wall_projector}</p>
              <p><strong>Conferencing Phone</strong> : {props.conferencing_phone}</p>
              <p><strong>LAN Socket Count</strong> : {props.lan_conn_count}</p>
              <p><strong>Power Socket Count</strong> : {props.power_socket_count}</p>
              <p><strong>WiFi</strong> :{props.wi_fi} </p>
              <p><strong>Television</strong> : {props.television} </p>
              <p><strong>HDMI Connectivity</strong> : {props.hdmi_connectivity}</p>
              <p><strong>Presentation Pointer</strong> : {props.presentation_pointer}</p>

            </div>
          }
          <br />

        </div>
      </div>
    </div>

  )
}

export default Card