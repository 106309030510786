import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import moment from 'moment'
interface Props {
    data: any
}



const PendingCards: React.FC<Props> = (props) => {
    return (
        <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>

                        <TableCell>Title</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Time</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Booked By</TableCell>
                        <TableCell>Approver</TableCell>
                        <TableCell>Meeting Type</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Remarks</TableCell>
                        <TableCell>Booking Date</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data?.map((row: any) => (
                        
                        <>
                            <TableRow
                                key={row.room_title}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell >
                                    {row.title}
                                </TableCell>
                                <TableCell>
                                    {row.meeting_description}
                                </TableCell>
                                <TableCell>{moment(row?.start).format('h:mm A')} - {moment(row?.end).format('h:mm A')}</TableCell>
                                <TableCell>{moment(row?.start).format("MMMM Do YYYY")}</TableCell>
                                <TableCell>{row?.user_email}</TableCell>
                                <TableCell>{row?.approver_email}</TableCell>
                                <TableCell>{row?.meeting_type}</TableCell>
                                <TableCell>{row?.booking_status}</TableCell>
                                <TableCell>{row?.remarks}</TableCell>
                                <TableCell>{moment(row?.created_at).format("MMMM Do YYYY, h:mm A")}</TableCell>
                            </TableRow>
                        </>
                        
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default PendingCards