import React, { Fragment } from "react";
import { connect } from "react-redux";

interface Props {
  loading: boolean;
}

const Loader: React.FC<Props> = ({ loading }: Props) => {
  return (
    <Fragment>
      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </Fragment>
  );
};

const mapToState = (state: any) => {
  return {
    loading: state.loading,
  };
};

const connectToRedux = connect(mapToState, null)(Loader);

export { connectToRedux as Loader };
